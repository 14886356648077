import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {EtablissementModel} from '../model/etablissement.model';

@Injectable({
  providedIn: 'root',
})
export class EtablissementService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'etablissements', 'etablissement');
  }

  getAll(id?: string): Observable<EtablissementModel[]> {
    return this.httpClient.get(environment.serverUrl + '/etablissements' , {
      params: new HttpParams()
        .set('size' , this.size.toString())
        .set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          if (id !== undefined && Number(id) && Number(id) > 0) {
            // @ts-ignore
            return response._embedded.etablissements.filter(value => value['status'] && value['id'] === Number(id));
          } else {
            // @ts-ignore
            return response._embedded.etablissements.filter(value => value['status']);
          }
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<EtablissementModel> {
    return this.httpClient.get<EtablissementModel>(environment.serverUrl + `/contrats/${id}/etablissement`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<EtablissementModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<EtablissementModel> {
    return this.httpClient.get<EtablissementModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<EtablissementModel>(`getOne`)),
    );
  }


}
