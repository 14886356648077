import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {TypeNiveauModel} from '../model/typeNiveau.model';

@Injectable({
  providedIn: 'root',
})
export class TypeNiveauService  extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeNiveaus' , 'typeNiveau' );
  }

  getAll(): Observable<TypeNiveauModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeNiveaus' , {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.typeNiveaus;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }
}
