import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {DbDate} from '../shared/dbDate';
import {FormGroup} from '@angular/forms';
import {PersonneModel} from '../model/personnel.model';
import {EvaluationModel} from '../model/evaluation.model';
import * as moment from 'moment';
import {EvaluationProperties} from '../model/EvaluationProperties.model';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

const httpBlobOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class EvaluationService extends ApplicationService {

    model: any = {};

    constructor(httpClient: HttpClient, private dbDate: DbDate) {
        super(httpClient, 'evaluations', 'evaluation');
    }

    setEvaluationData(etat: string, group: FormGroup): void {
        this.model = {
            'type': group.get('type').value,
            'anneeScolaire': group.get('anneeScolaire').value,
            'passageCdi': group.get('passageCdi').value,
            'renouvellementCdd': group.get('renouvellementCdd').value,
            'avisEvaluateur': group.get('avisEvaluateur').value,
            'etat': etat,
            'dateInitialisation': moment().format('YYYY-MM-DD'),
        };
        if (etat === 'Initiée') {
            this.model.legacy = false;
        }
        if (Number(group.get('agent').value) > 0) {
            const personneId = Number(group.get('agent').value);
            this.model.personne = environment.serverUrl + `/personnes/${personneId}`;
        }
        if (Number(group.get('evaluateur').value) > 0) {
            const evaluateurId = Number(group.get('evaluateur').value);
            this.model.evaluateur = environment.serverUrl + `/evaluateurs/${evaluateurId}`;
        }
        if (Number(group.get('etablissement').value) > 0) {
            const etablissementId = Number(group.get('etablissement').value);
            this.model.etablissement = environment.serverUrl + `/etablissements/${etablissementId}`;
        }
    }

    listingVisa(): any {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listingVisa`,
            {'visa': true}, this.httpOptions).pipe(
            tap(_ => console.log(`listing`)),
            catchError(this.handleError<any>('listingVisa')),
        );
    }

    listingByEvaluateur(email: string, group: FormGroup): any {
        const searchData: any = {};
        if (group.get('anneeScolaire').value !== undefined) {
            searchData.anneeScolaire = group.get('anneeScolaire').value;
        }
        if (group.get('etat').value !== undefined) {
            searchData.etat = group.get('etat').value;
        }
        searchData.email = email;
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listing`,
            searchData, this.httpOptions).pipe(
            tap(_ => console.log(`listingByEvaluateur`)),
            catchError(this.handleError<any>('listingByEvaluateur')),
        );
    }

    listingByAgent(agentId: number): any {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listing`,
            {'agentId': agentId, 'etat': 'Cloturer', 'cloturer': true}, this.httpOptions).pipe(
            tap(_ => console.log(`listingByAgent`)),
            catchError(this.handleError<any>('listingByAgent')),
        );
    }

    getAllAgents(): Observable<EvaluationProperties[]> {
        return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/all-agents`,
            {}, this.httpOptions).pipe(
            tap(_ => console.log(`getAllAgents`)),
            catchError(this.handleError<any>('getAllAgents')),
        );
    }

    etat(evaluationId: number): any {
        return this.httpClient.post(
            `${environment.serverBaseUrl}evaluation/etat`,
            {'evaluationId': evaluationId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }
    get(anneeScolaire: string, etablissementId: number, agentId: number): any {
        return this.httpClient.post(
            `${environment.serverBaseUrl}evaluation/get`,
            {'anneeScolaire': anneeScolaire, 'etablissementId': etablissementId, 'agentId': agentId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }
    addEvaluationDetache(group: FormGroup): Observable<EvaluationModel> {
        this.model = {
            'anneeScolaire': group.get('anneeScolaire').value,
            'etat': 'Clôturer',
            'cloturer': true,
            'active': true,
            'manual': true,
            'dateInitialisation': moment().format('YYYY-MM-DD'),
            'dateCloture': moment().format('YYYY-MM-DD'),
        };
        if (this.isNotEmpty(group.get('dateCloture').value)) {
            this.model.dateCloture = this.dbDate.format(group.get('dateCloture').value);
        }
        if (Number(group.get('personne').value) > 0) {
            const personneId = Number(group.get('personne').value);
            this.model.personne = environment.serverUrl + `/personnes/${personneId}`;
        }
        if (Number(group.get('etablissement').value) > 0) {
            const etablissementId = Number(group.get('etablissement').value);
            this.model.etablissement = environment.serverUrl + `/etablissements/${etablissementId}`;
        }
        console.log('Model', this.model);
        return this.httpClient.post<EvaluationModel>(`${environment.serverUrl}/evaluations`,
            this.model, httpOptions).pipe(
            tap((evaluation: EvaluationModel) => {
            }),
            catchError(this.handleError<EvaluationModel>(`addEvaluationDetache`)),
        );
    }

    initEvaluation(group: FormGroup): Observable<EvaluationModel> {
        this.setEvaluationData('Initiée', group);
        return this.httpClient.post<EvaluationModel>(`${environment.serverUrl}/evaluations`,
            this.model, httpOptions).pipe(
            tap((model: EvaluationModel) => {
            }),
            catchError(this.handleError<EvaluationModel>(`addEvaluation`)),
        );
    }
    enregistrerEvaluation(id: number, group: FormGroup): Observable<EvaluationModel> {
        const request = {
            'dateEnregistrement': moment().format('YYYY-MM-DD'),
            'etat': 'Enregistrée',
            'avisEvaluateur': group.get('avisEvaluateur').value,
            'questions': JSON.stringify(group.get('questions').value),
        };
        if (group.get('passageCdi').value) {
            request['passageCdi'] = group.get('passageCdi').value;
            request['renouvellementCdd'] = false;
        }
        if (group.get('renouvellementCdd').value) {
            request['renouvellementCdd'] = group.get('renouvellementCdd').value;
            request['passageCdi'] = false;
        }
        if (this.isNotEmpty(group.get('dateRenouvellementCdd').value) && group.get('renouvellementCdd').value) {
            request['dateRenouvellementCdd'] = this.dbDate.format(group.get('dateRenouvellementCdd').value);
        }
        if (this.isNotEmpty(group.get('datePassageCdi').value) && group.get('passageCdi').value) {
            request['datePassageCdi'] = this.dbDate.format(group.get('datePassageCdi').value);
        }

        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            request, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    validerEvaluation(id: number, group: FormGroup): Observable<EvaluationModel> {
        const request = {
            'dateValidation': moment().format('YYYY-MM-DD'),
            'etat': 'En attente',
            'avisEvaluateur': group.get('avisEvaluateur').value,
            'questions': JSON.stringify(group.get('questions').value),
        };
        if (this.isNotEmpty(group.get('passageCdi').value) && group.get('passageCdi').value) {
            request['passageCdi'] = group.get('passageCdi').value;
        }
        if (this.isNotEmpty(group.get('renouvellementCdd').value) && group.get('renouvellementCdd').value) {
            request['renouvellementCdd'] = group.get('renouvellementCdd').value;
        }
        if (this.isNotEmpty(group.get('dateRenouvellementCdd').value) && group.get('renouvellementCdd').value) {
            request['dateRenouvellementCdd'] = this.dbDate.format(group.get('dateRenouvellementCdd').value);
        }
        if (this.isNotEmpty(group.get('datePassageCdi').value) && group.get('passageCdi').value) {
            request['datePassageCdi'] = this.dbDate.format(group.get('datePassageCdi').value);
        }
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            request, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    approuverEvaluation(id: number): Observable<EvaluationModel> {
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            {'etat': 'Approuvée'}, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    rejeterEvaluation(id: number): Observable<EvaluationModel> {
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            {'etat': 'Rejetée'}, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    cloturerEvaluation(id: number): Observable<EvaluationModel> {
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            {'etat': 'Clôturer', 'cloturer': true, 'dateCloture': moment().format('YYYY-MM-DD')}, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    cloturerEvaluations(documents: []): Observable<any> {
        return this.httpClient.post(`${environment.serverBaseUrl}evaluation/terminate`,
            {'data': documents}, httpOptions).pipe(
            tap((data: any) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    updateEtatEvaluation(id: number, etat: string): Observable<EvaluationModel> {
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            {'etat': etat}, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }


    updateEvaluation(id: number, etat: string, group: FormGroup): Observable<EvaluationModel> {
        this.setEvaluationData(etat, group);
        return this.httpClient.patch<EvaluationModel>(`${environment.serverUrl}/evaluations/${id}`,
            this.model, httpOptions).pipe(
            tap((personne: EvaluationModel) => {
                console.log(`updated evaluation id=${id}`);
            }),
            catchError(this.handleError<any>('updateEvaluation')),
        );
    }

    getPersonnes(id: number): Observable<PersonneModel> {
        const url = environment.serverUrl + `/evaluations/${id}/agents`;
        console.log(url);
        return this.httpClient.get<PersonneModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<PersonneModel>(`get PersonneModel id=${id}`)),
        );
    }

    getEvaluateur(email: string, etablissementId: number, agentId: number): any {
        return this.httpClient.post(
            `${environment.serverBaseUrl}evaluation/evaluateur`,
            {'email': email, 'etablissementId': etablissementId, 'agentId': agentId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getEtablissements(email: string): any {
        return this.httpClient.post(`${environment.serverBaseUrl}evaluation/etablissements`,
            {'email': email}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getAgents(email: string, etablissementId: number): any {
        return this.httpClient.post(`${environment.serverBaseUrl}evaluation/agents`,
            {'email': email, 'etablissementId': etablissementId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    downloadEvaluation(id: number): any {
        return this.httpClient.get(`${environment.serverBaseUrl}evaluation/download/${id}`, httpBlobOptions).pipe(
            tap(_ => console.log(`downloadEvaluation`)),
            catchError(this.handleError<any>('downloadEvaluation')),
        );
    }

    export(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}evaluation/export`,
            data, httpBlobOptions).pipe(
            tap(_ => console.log(`export`)),
            catchError(this.handleError<any>('export')),
        );
    }
}
