import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {CorpsService} from '../../../lyautey/services/corps.service';
import {RegimeService} from '../../../lyautey/services/regime.service';
import {EtablissementService} from '../../../lyautey/services/etablissement.service';
import {StatutService} from '../../../lyautey/services/statut.service';
import Swal from 'sweetalert2';
import {SearchQueryService} from '../../../lyautey/services/search-query.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchQueryModel} from '../../../lyautey/model/searchQuery.model';
import {NgxSpinnerService} from 'ngx-spinner';

import 'datatables.net';
import 'datatables.net-bs4';
import * as moment from 'moment';
import {EtablissementModel} from '../../../lyautey/model/etablissement.model';
import {interval, Observable, Subscription} from 'rxjs';
import {StatutModel} from '../../../lyautey/model/statut.model';
import {CorpsModel} from '../../../lyautey/model/corps.model';
import {RegimeModel} from '../../../lyautey/model/regime.model';
import {CustomDatepickerI18n, I18n} from '../../../lyautey/shared/CustomDatepickerI18n';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../lyautey/shared/CustomDateParserFormatter';
import {DatePipe} from '@angular/common';
import {NgbDateMomentParserFormatter} from '../../../lyautey/shared/NgbDateMomentParserFormatter';
import {CountryModel} from '../../../lyautey/model/country.model';
import {CountryService} from '../../../lyautey/services/country.service';
import {SituationFamilialeModel} from '../../../lyautey/model/situation-familiale.model';
import {SituationFamilialeService} from '../../../lyautey/services/situation-familiale.service';
import {TypeNiveauModel} from '../../../lyautey/model/typeNiveau.model';
import {TypeNiveauService} from '../../../lyautey/services/type-niveau.service';
import {GlobalObject} from '../../../lyautey/classes/global-object';
import {GenreModel} from '../../../lyautey/model/genre.model';
import {GenreService} from '../../../lyautey/services/genre.service';
import {DisciplineModel} from '../../../lyautey/model/discipline.model';
import {DisciplineService} from '../../../lyautey/services/discipline.service';
import {KeycloakService} from 'keycloak-angular';
import {AppAuthGuard} from '../../../lyautey/services/app-auth-guard.service';
import {FonctionService} from '../../../lyautey/services/fonction.service';
import {FonctionModel} from '../../../lyautey/model/fonction.model';
import {switchMap, takeWhile} from 'rxjs/operators';

interface Event {
  name: string;
  value: any;
}

@Component({
  selector: 'ngx-edit-recherche',
  templateUrl: './edit-recherche.component.html',
  styleUrls: ['./edit-recherche.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    DatePipe,
    {
      provide: NgbDateMomentParserFormatter,
      useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
    },
  ],
})
export class EditRechercheComponent implements OnInit  {
  events: Event[] = [];
  clients: any[];
  isSample: boolean = true;
  isPersist: boolean = true;
  searchForm: FormGroup;
  searchCriteriaForm: FormGroup;
  globalObject: GlobalObject = new GlobalObject();
  situationFamiliales: Observable<SituationFamilialeModel[]>;
  genres: Observable<GenreModel[]>;
  fonctions: Observable<FonctionModel[]>;
  etablissements: Observable<EtablissementModel[]>;
  statuts: Observable<StatutModel[]>;
  corps: Observable<CorpsModel[]>;
  disciplines: Observable<DisciplineModel[]>;
  regimes: Observable<RegimeModel[]>;
  countries: Observable<CountryModel[]>;
  typeNiveaux: Observable<TypeNiveauModel[]>;
  fields;
  orderedFields;
  fieldsLabels;
  columns = [];
  result = [];
  resultCount: number = 0;
  loading: boolean;
  searchQueryId: number = 0;
  searchQueryDescription: string = 'Listes et Recherches';
  blob: Blob;

  constructor(private fb: FormBuilder,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private service: SearchQueryService,
              private personnelService: PersonnelService,
              private corpsService: CorpsService,
              private regimeService: RegimeService,
              private etablissementService: EtablissementService,
              private statutService: StatutService,
              private genreService: GenreService,
              private disciplineService: DisciplineService,
              private fonctionService: FonctionService,
              private searchQueryService: SearchQueryService,
              private countryService: CountryService,
              private situationFamilialeService: SituationFamilialeService,
              private typeNiveauService: TypeNiveauService,
              public keycloakService: KeycloakService,
              public appAuthGuard: AppAuthGuard) {
  }
  ngOnInit() {
    this.countryService.search('ma');
    this.countries = this.countryService.getAll();
    this.statuts = this.statutService.getAll();
    this.disciplines = this.disciplineService.getAll();
    this.genres = this.genreService.getAll();
    this.corps = this.corpsService.getAll();
    this.regimes = this.regimeService.getAll();
    this.situationFamiliales = this.situationFamilialeService.getAll();
    this.typeNiveaux = this.typeNiveauService.getAll();
    this.fonctions = this.fonctionService.getAll();
    this.orderedFields = this.globalObject.orderedFields;
    this.fields = this.globalObject.fields;
    this.fieldsLabels = this.globalObject.fieldsLabels;
    this.searchCriteriaForm = this.formBuilder.group({
      description: [''],
      sample: [true],
      visibilite: [''],
      format: ['excel'],
      personneFields : this.formBuilder.group(this.globalObject.fields.personneFields),
      contactUrgenceFields : this.formBuilder.group(this.globalObject.fields.contactUrgenceFields),
      contratFields : this.formBuilder.group(this.globalObject.fields.contratFields),
      grilleFields : this.formBuilder.group(this.globalObject.fields.grilleFields),
      autreFields : this.formBuilder.group(this.globalObject.fields.autreFields),
      adresseMarocFields : this.formBuilder.group(this.globalObject.fields.adresseMarocFields),
      adresseHorsMarocFields : this.formBuilder.group(this.globalObject.fields.adresseHorsMarocFields),
      criteria: this.formBuilder.array([this.addStatutContratFormGroup()]),
    });
    this.route.paramMap.subscribe(params => {
      this.searchQueryId = Number(params.get('id'));
      if (this.searchQueryId) {
        this.criteria.removeAt(0);
        this.service.getOne(this.searchQueryId).subscribe(
          (model: SearchQueryModel) => {
            this.searchCriteriaForm.patchValue(model);
            const queryObject = JSON.parse(model.query);
            this.patchFields(queryObject, 'personneFields');
            this.patchFields(queryObject, 'contactUrgenceFields');
            this.patchFields(queryObject, 'contratFields');
            this.patchFields(queryObject, 'grilleFields');
            this.patchFields(queryObject, 'adresseMarocFields');
            this.patchFields(queryObject, 'adresseHorsMarocFields');
            this.patchFields(queryObject, 'autreFields');
            for (const data of queryObject['criteria']) {
              this.addCriterionFormGroup(data);
              (<FormArray>this.searchCriteriaForm.get('criteria')).push(this.addCriterionFormGroup(data));
            }
            this.searchQueryDescription = model.description;
            this.spinner.show();
            this.result = [];
            this.personnelService.advancedSearch(this.searchForm, true, false, queryObject)
              .subscribe((result: any) => {
                  this.spinner.hide();
                  this.resultCount = result.count;
                  this.result = result.data;
                  this.columns = result.columns;
                }, (err: any) => {
                  this.spinner.hide();
                  Swal.fire({
                    icon: 'error',
                    title: `Veuillez reessayer plus tard!`,
                  }) ;
                },
              );
            this.spinner.hide();
          });
      }
    });
    if (this.appAuthGuard.isDirector()) {
      this.keycloakService
        .isLoggedIn()
        .then( loggedIn => {
          if ( loggedIn ) {
            this.keycloakService.loadUserProfile()
              .then(profile => {
                // @ts-ignore
                this.etablissements = this.etablissementService.getAll(profile.attributes.etablissement);
                // @ts-ignore
                if (profile.attributes.etablissement !== undefined && Number(profile.attributes.etablissement) > 0) {
                  this.globalObject.criteriaList = this.globalObject.criteriaList
                    .filter(value => value.value !== 'etablissementId');
                }
              })
              .catch( reason => {
              });
          }
        }).catch( reason => console.log ( reason ));
    } else {
      this.etablissements = this.etablissementService.getAll();
    }
  }
  submitForm() {
    this.resultCount = 0;
    this.result = [];
    this.personnelService.advancedSearch(this.searchCriteriaForm, this.isSample, false)
      .subscribe((result: any) => {
        if (result !== undefined && result.count !== undefined) {
          this.resultCount = result.count;
          this.result = result.data;
          this.columns = result.columns;
        }
        }, (err: any) => {
          this.spinner.hide();
          Swal.fire({
          icon: 'error',
          title: `Veuillez reessayer plus tard!`,
        }) ;
        },
      );
    this.spinner.hide();
  }
  async saveSearch() {
    this.personnelService.setSearchData(this.searchCriteriaForm, false);
    this.keycloakService
      .isLoggedIn()
      .then( loggedIn => {
        if ( loggedIn ) {
          this.keycloakService.loadUserProfile()
            .then(profile => {
              this.route.paramMap.subscribe(params => {
                this.searchQueryId = Number(params.get('id'));
                if (this.searchQueryId) {
                  this.searchQueryService.update(this.searchQueryId,
                    {
                      user: profile.email,
                      description: this.searchCriteriaForm.get('description').value,
                      visibilite: this.searchCriteriaForm.get('visibilite').value,
                      format: 'excel',
                      dateModification: moment(),
                      query: JSON.stringify(this.personnelService.searchData),
                    })
                    .subscribe((entity: any) => {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title:
                            `Votre liste[${this.searchCriteriaForm.get('description').value }] a été mise à jour.`,
                        });
                      }, (err: any) => {},
                    );
                } else {
                  this.searchQueryService.add(
                    {
                      user: profile.email,
                      description: this.searchCriteriaForm.get('description').value,
                      visibilite: this.searchCriteriaForm.get('visibilite').value,
                      format: 'excel',
                      dateModification: moment(),
                      query: JSON.stringify(this.personnelService.searchData),
                    })
                    .subscribe((entity: any) => {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title:
                            `Votre liste[${this.searchCriteriaForm.get('description').value }] a été bien enregistrée.`,
                        });
                      }, (err: any) => {},
                    );
                }
              });
            })
            .catch( reason => {
            });
        }
      }).catch( reason => console.log ( reason ));
  }
  download() {
    this.spinner.show();
    // Step 1: Start report generation and get reportId
    this.personnelService.startReportGeneration(this.searchCriteriaForm, false).subscribe(response => {
      const reportId = response.body.reportId;
      const checkInterval = 5000; // Check every 5000 milliseconds (5 seconds)

      // Step 2: Poll for report status
      const polling: Subscription = interval(checkInterval).pipe(
          switchMap(() => this.personnelService.reportStatus(reportId)),
          takeWhile(statusResponse => !statusResponse.body.isReady, true),
      ).subscribe(statusResponse => {
        if (statusResponse.body.isReady) {
          polling.unsubscribe(); // Stop polling

          // Step 3: Download the report
          this.personnelService.downloadReport(reportId).subscribe(data => {
            this.spinner.hide(); // Hide spinner when the download is ready

            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            const now = moment().locale('fr').format('YYYY-MM-DD_HH-mm-ss');
            link.download = `report-${reportId}-${now}.xlsx`;
            link.click();
            window.URL.revokeObjectURL(downloadURL); // Clean up
          }, error => {
            this.spinner.hide(); // Ensure spinner is hidden on error
            console.error('Download failed', error);
          });
        }
      }, error => {
        this.spinner.hide(); // Ensure spinner is hidden on error
        console.error('Error checking report status', error);
      });
    }, error => {
      this.spinner.hide(); // Ensure spinner is hidden on error
      console.error('Error starting report generation', error);
    });
  }



  generateDocuments() {
    this.spinner.show();
    this.personnelService.generateDocuments(this.searchCriteriaForm, false).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      const now = moment().locale('fr').format();
      link.download = 'certificats-' + now + '.zip';
      this.spinner.hide();
      link.click();
    });
  }
  redirect(id: any) {
    this.router.navigate(['/pages/personnel/personne/', id, 'fiche']);
  }
  addCriteriaFormGroup(): FormGroup {
    return this.formBuilder.group({
      criterion: [''],
      value: [''],
      listValues: [''],
      minValue: [''],
      maxValue: [''],
    });
  }
  addCriterionFormGroup(data: any): FormGroup {
    return this.formBuilder.group({
      criterion: [data.criterion],
      value: [data.value],
      listValues: [data.values],
      minValue: [data.minValue],
      maxValue: [data.maxValue],
    });
  }
  addStatutContratFormGroup(): FormGroup {
    return this.formBuilder.group({
      criterion: ['contratStatus'],
      value: ['actif'],
      listValues: [''],
      minValue: [''],
      maxValue: [''],
    });
  }
  addCriteriaButtonClick(): void {
    (<FormArray>this.searchCriteriaForm.get('criteria')).push(this.addCriteriaFormGroup());
  }
  patchFields(queryObject, groupName: string) {
    for (const key of Object.keys(queryObject[groupName])) {
      this.searchCriteriaForm.get(groupName)
        .get(key)
        .patchValue(queryObject[groupName][key]);
    }
  }
  criterionChangedHandler($event: Event, index: number) {
/*    const criterion: string =
      (<FormArray>this.searchCriteriaForm.controls['criteria']).at(index).get('criterion').value;
    this.criteriaList = this.criteriaList.map(value => {
      if (value.value === criterion) {
        value.disabled = true;
      }
      return value;
    });*/
  }
  get criteria() {
    return this.searchCriteriaForm.get('criteria') as FormArray;
  }
  beforeDelete(index, title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        this.criteria.removeAt(index);
        Swal.fire(
          'Suppression de paramètre',
          'Paramètre vient d\'être supprimé',
          'success',
        );
        return;
      }
    });
  }

  onChange($event) {
    // this.events.push({name: '(change)', value: $event });
  }

  enfantsAcharge() {
    this.spinner.show();
    this.personnelService.downloadEnfantResponsablesReport().subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      const now = moment().locale('fr').format();
      link.download = 'EnfantResponsables-' + now + '.xlsx';
      link.click();
      this.spinner.hide();
    });
  }
}
