import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {ContratModel} from '../model/contrat.model';
import {FormArray, FormGroup} from '@angular/forms';
import {DbDate} from '../shared/dbDate';
import {TypeModel} from '../model/type.model';
import {QuotiteHebdomadaireModel} from '../model/quotiteHebdomadaire.model';
import {QuotiteHebdomadaireService} from './quotite-hebdomadaire.service';
import {AvancementModel} from '../model/Avancement.model';
import {AvancementService} from './avancement.service';

@Injectable({
  providedIn: 'root',
})
export class ContratService extends ApplicationService {
  constructor(httpClient: HttpClient,
              private quotiteHebdomadaireService: QuotiteHebdomadaireService,
              private avancementService: AvancementService,
              private dbDate: DbDate) {
    super(httpClient, 'contrats', 'contrat');
  }

  getByPersonId(id: number ): Observable<ContratModel> {
    const url = environment.serverUrl + `/personnes/${id}/contrats`;
    return this.httpClient.get<ContratModel>(url).pipe(
      tap(_ => console.log(`fetched contrats id=${id}`)),
      catchError(this.handleError<ContratModel>(`get  id=${id}`)),
    );
  }

  addToPerson(personneid: number, group: FormGroup, avancements: {}): void {
    const formArray = group.get('contrats') as FormArray;
    const contrats = [];
    const commonFields = [
      'classeEchelle', 'indiceBrut', 'duree', 'indiceMajore', 'grade', 'chevron', 'echelon', 'nombreHeures',
      'isvl', 'cexpat', 'reliquat', 'abbat', 'numeroPoste', 'matriculeAgence', 'numeroSom', 'edt',
      'iss', 'groupech', 'radier', 'numen', 'encadrement', 'disponibilite', 'academie', 'ministere',
      'noteAdm', 'notePre', 'anneeNote', 'observations', 'rrd', 'status',
      'appreciation', 'periodeDetachement', 'dateDebutDetachement', 'dateFinDetachement', 'dateMaroc', 'dateContrat',
      'typeAvancement', 'dureeDetachement', 'datePromotion', 'dateAvancement', 'dateProchainePromotion',
      'dateAppreciation', 'dateCdd', 'dateCdi', 'dateFinCdd', 'dateEntreeErc', 'dateSortieErc',
      'dateDebutSuspension', 'dateFinSuspension', 'dateArchivage', 'remplacant', 'promotionMoisGagnes',
      'cddHeure', 'cddTauxHoraire', 'cddMotif', 'cddMajoration',
    ];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const contrat = {
          id: 0,
          action: '',
          typeContrat: '',
          personne: '',
          typeCorps: '',
          type: '',
          fonction: '',
          discipline: '',
          typeMage: '',
          typeRegime: '',
          typeFonction: '',
          typeStatut: '',
          typeRit: '',
          etablissement: '',
          poste: '',
          grille: '',
          status: '',
          typeAvancement: '',
          dureeDetachement: '',
          dateDebutDetachement : '',
          dateFinDetachement : '',
          dateMaroc : '',
          dateContrat : '',
          datePromotion : '',
          dateAvancement : '',
          dateProchainePromotion : '',
          dateAppreciation : '',
          dateCdd : '',
          dateCdi : '',
          dateFinCdd : '',
          cddHeure : '',
          cddTauxHoraire : '',
          cddMotif : '',
          dateEntreeErc : '',
          dateSortieErc : '',
          dateDebutSuspension : '',
          dateFinSuspension : '',
          dateArchivage : '',
          remplacant : '',
          vacationCategorieHoraire : '',
          cddMajoration : '',
        };
        Object.keys(control.controls).forEach(key => {
          if (this.isNotEmpty(control.controls['status'].value)) {
            contrat.status = control.controls['status'].value;
          }
          switch (key) {
            default:
              // @ts-ignore
              if (control.controls[key].value !== undefined && commonFields.includes(key) && key !== 'status') {
                contrat[key] = control.controls[key].value;
              }
              break;
            case 'id':
              contrat.personne = environment.serverUrl + `/personnes/${personneid}`;
              if ( Number(control.controls['id'].value) > 0) {
                contrat.id = Number(control.controls['id'].value);
                contrat.action = 'PATCH';
              } else {
                contrat.action = 'POST';
              }
              break;
            case 'vacationCategorieHoraire':
              if (control.controls['vacationCategorieHoraire'].value !== undefined
                && Number(control.controls['vacationCategorieHoraire'].value) > 0 ) {
                contrat.vacationCategorieHoraire =
                  environment.serverUrl + `/vacationCategorieHoraires/${control.controls['vacationCategorieHoraire'].value}`;
              }
              break;
            case 'corps':
              if (control.controls['corps'].value !== undefined
                && Number(control.controls['corps'].value) > 0 ) {
                contrat.typeCorps = environment.serverUrl + `/typeCorps/${control.controls['corps'].value}`;
              }
              break;
            case 'fonction':
              if (control.controls['fonction'].value !== undefined
                && Number(control.controls['fonction'].value) > 0 ) {
                contrat.typeFonction = environment.serverUrl + `/typeFonctions/${control.controls['fonction'].value}`;
              }
              break;
            case 'discipline':
              if (control.controls['discipline'].value !== undefined) {
                contrat.discipline = control.controls['discipline'].value;
              }
              break;
            case 'regime':
              if (control.controls['regime'].value !== undefined
                && Number(control.controls['regime'].value) > 0 ) {
                contrat.typeRegime = environment.serverUrl + `/typeRegimes/${control.controls['regime'].value}`;
              }
              break;
            case 'mage':
              if (control.controls['mage'].value !== undefined
                && Number(control.controls['mage'].value) > 0 ) {
                contrat.typeMage = environment.serverUrl + `/typeMages/${control.controls['mage'].value}`;
              }
              break;
            case 'type':
              if (control.controls['type'].value !== undefined
                && Number(control.controls['type'].value) > 0 ) {
                contrat.type = environment.serverUrl + `/types/${control.controls['type'].value}`;
              }
              break;
            case 'etablissement':
              if (control.controls['etablissement'].value !== undefined
                && Number(control.controls['etablissement'].value) > 0 ) {
                contrat.etablissement =
                  environment.serverUrl + `/etablissements/${control.controls['etablissement'].value}`;
              }
              break;
            case 'poste':
              if (control.controls['poste'].value !== undefined
                && Number(control.controls['poste'].value) > 0 ) {
                contrat.poste = environment.serverUrl + `/postes/${control.controls['poste'].value}`;
              }
              break;
            case 'rit':
              if (control.controls['rit'].value !== undefined
                && Number(control.controls['rit'].value) > 0 ) {
                contrat.typeRit = environment.serverUrl + `/typeRits/${control.controls['rit'].value}`;
              }
              break;
            case 'contrat':
              if (control.controls['contrat'].value !== undefined
                && Number(control.controls['contrat'].value) > 0 ) {
                contrat.typeContrat = environment.serverUrl + `/typeContrats/${control.controls['contrat'].value}`;
              }
              break;
            case 'statut':
              if (control.controls['statut'].value !== undefined
                && Number(control.controls['statut'].value) > 0 ) {
                contrat.typeStatut = environment.serverUrl + `/typeStatuts/${control.controls['statut'].value}`;
              }
              break;
            case 'grille':
              if (control.controls['grille'].value !== undefined
                && Number(control.controls['grille'].value) > 0 ) {
                contrat.grille = environment.serverUrl + `/grilles/${control.controls['grille'].value}`;
              }
              break;
            case 'remplacant':
              if (control.controls['remplacant'].value !== undefined
                && control.controls['remplacant'].value !== null
                && control.controls['remplacant'].value.id !== undefined
                && Number(control.controls['remplacant'].value.id) > 0 ) {
                contrat.remplacant =
                  environment.serverUrl + `/personnes/${control.controls['remplacant'].value.id}`;
              }
              break;
            case 'dateDebutDetachement':
              if (this.isNotEmpty(control.controls['dateDebutDetachement'].value)) {
                contrat.dateDebutDetachement = this.dbDate.format(control.controls['dateDebutDetachement'].value);
              }
              break;
            case 'dateFinDetachement':
              if (this.isNotEmpty(control.controls['dateFinDetachement'].value)) {
                contrat.dateFinDetachement = this.dbDate.format(control.controls['dateFinDetachement'].value);
              }
              break;
            case 'dateAppreciation':
              if (this.isNotEmpty(control.controls['dateAppreciation'].value)) {
                contrat.dateAppreciation = this.dbDate.format(control.controls['dateAppreciation'].value);
              }
              break;
            case 'datePromotion':
              if (this.isNotEmpty(control.controls['datePromotion'].value)) {
                contrat.datePromotion = this.dbDate.format(control.controls['datePromotion'].value);
              }
              break;
            case 'dateAvancement':
              if (this.isNotEmpty(control.controls['dateAvancement'].value)) {
                contrat.dateAvancement = this.dbDate.format(control.controls['dateAvancement'].value);
              }
              break;
            case 'dateCdd':
              if (this.isNotEmpty(control.controls['dateCdd'].value)) {
                contrat.dateCdd = this.dbDate.format(control.controls['dateCdd'].value);
              }
              break;
            case 'dateCdi':
              if (this.isNotEmpty(control.controls['dateCdi'].value)) {
                contrat.dateCdi = this.dbDate.format(control.controls['dateCdi'].value);
              }
              break;
            case 'dateFinCdd':
              if (this.isNotEmpty(control.controls['dateFinCdd'].value)) {
                contrat.dateFinCdd = this.dbDate.format(control.controls['dateFinCdd'].value);
              }
              break;
            case 'dateEntreeErc':
              if (this.isNotEmpty(control.controls['dateEntreeErc'].value)) {
                contrat.dateEntreeErc = this.dbDate.format(control.controls['dateEntreeErc'].value);
              }
              break;
            case 'dateSortieErc':
              if (this.isNotEmpty(control.controls['dateSortieErc'].value)  && contrat.status === 'radie') {
                contrat.dateSortieErc = this.dbDate.format(control.controls['dateSortieErc'].value);
              }
              break;
            case 'dateDebutSuspension':
              if (this.isNotEmpty(control.controls['dateDebutSuspension'].value)  && contrat.status === 'suspendu') {
                contrat.dateDebutSuspension = this.dbDate.format(control.controls['dateDebutSuspension'].value);
              }
              break;
            case 'dateFinSuspension':
              if (this.isNotEmpty(control.controls['dateFinSuspension'].value)  && contrat.status === 'suspendu') {
                contrat.dateFinSuspension = this.dbDate.format(control.controls['dateFinSuspension'].value);
              }
              break;
            case 'dateArchivage':
              if (this.isNotEmpty(control.controls['dateArchivage'].value) && contrat.status === 'archive') {
                contrat.dateArchivage = this.dbDate.format(control.controls['dateArchivage'].value);
              }
              break;
          }
        });
        if (contrat.action === 'POST') {
          delete contrat.id;
          this.add(contrat).subscribe((model: any) => {
              // Assuming model contains the ID of the newly created contract
              console.log('Quotite ajouté avec succès', control);
              this.handleQuotiteHebdomadaires(control, model.id);
            }, (err: any) => {
            },
          );
        } else if (contrat.action === 'PATCH') {
          this.update(contrat.id, contrat).subscribe((model: any) => {
              console.log('Quotite modifie avec succès', control);
              this.handleQuotiteHebdomadaires(control, contrat.id);
              if (Object.keys(avancements).length > 0 && avancements.hasOwnProperty(contrat.id)) {
                this.avancementService.addToContract(avancements[contrat.id]).subscribe((avancementModel: any) => {
                      console.log('Avancement ajouté avec succès', model);
                    },
                    (err: any) => { },
                );
              }
            }, (err: any) => {
            },
          );
        }
        contrats.push(contrat);
      }
    }
  }
  handleQuotiteHebdomadaires(contratControl: FormGroup, contratId: number) {
    const quotiteHebdomadairesArray = contratControl.get('quotiteHebdomadaires') as FormArray;
    if (quotiteHebdomadairesArray) {
      console.log(`Processing ${quotiteHebdomadairesArray.length} quotiteHebdomadaires for contratId: ${contratId}`);
      for (const quotiteControl of quotiteHebdomadairesArray.controls) {
        if (quotiteControl instanceof FormGroup) {
          const quotiteData = {
            ...quotiteControl.value,
            contratId: contratId,
          };
          console.log('QuotiteHebdomadaire Data:', quotiteData);

          // Send the data to backend
          this.sendQuotiteHebdomadaireData(quotiteData);
        }
      }
    } else {
      console.log('No quotiteHebdomadaires to process for this contrat');
    }
  }
  sendQuotiteHebdomadaireData(quotiteData: any) {
    // Logic to send data to backend
    if (quotiteData.contratId !== undefined && Number(quotiteData.contratId) > 0 ) {
      quotiteData.contrat = environment.serverUrl + `/contrats/${quotiteData.contratId}`;
    }
    if (this.isNotEmpty(quotiteData.dateDebut)) {
      quotiteData.dateDebut = this.dbDate.format(quotiteData.dateDebut);
    }
    if (this.isNotEmpty(quotiteData.dateFin)) {
      quotiteData.dateFin = this.dbDate.format(quotiteData.dateFin);
    }
    delete quotiteData.contratId;
    if (quotiteData.id > 0) {
      this.quotiteHebdomadaireService.update(quotiteData.id , quotiteData)
          .subscribe((model: any) => {  },
              (err: any) => { },
          );
    } else {
        delete quotiteData.id;
        this.quotiteHebdomadaireService.add(quotiteData)
          .subscribe((model: any) => {  },
              (err: any) => { },
          );
    }

    console.log('quotiteData', quotiteData);
  }
  patchPoste(contratId: number, posteId: number): void {
    const contrat = {
      poste: environment.serverUrl + `/postes/${posteId}`,
    };
    this.update(contratId, contrat).subscribe((model: any) => {
      }, (err: any) => {
      },
    );
  }
  getTypes(id: number): Observable<TypeModel> {
    const url = environment.serverUrl + `/contrats/${id}/types`;
    return this.httpClient.get<TypeModel>(url).pipe(
      tap(_ => console.log(`fetched personne id=${id}`)),
      catchError(this.handleError<TypeModel>(`get EnfantModel id=${id}`)),
    );
  }

  getQuotiteHebdomadaire(id: number): Observable<QuotiteHebdomadaireModel> {
    const url = environment.serverUrl + `/contrats/${id}/quotiteHebdomadaires`;
    return this.httpClient.get<QuotiteHebdomadaireModel>(url).pipe(
        tap(_ => console.log(`fetched QuotiteHebdomadaireModel id=${id}`)),
        catchError(this.handleError<QuotiteHebdomadaireModel>(`get QuotiteHebdomadaireModel id=${id}`)),
    );
  }
  getAvancements(id: number): Observable<AvancementModel> {
    const url = environment.serverUrl + `/contrats/${id}/avancements`;
    return this.httpClient.get<AvancementModel>(url).pipe(
        tap(_ => console.log(`fetched AvancementModel id=${id}`)),
        catchError(this.handleError<AvancementModel>(`get AvancementModel id=${id}`)),
    );
  }
}
