import { Injectable } from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {GenreModel} from '../model/genre.model';
import {ApplicationService} from './application.service';


@Injectable({
  providedIn: 'root',
})
export class GenreService  extends ApplicationService {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'genres', '');
  }
  getAll(): Observable<GenreModel[]> {
    return this.httpClient.get(environment.serverUrl + '/genres',
      {params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        return response._embedded.genres;
      }),
      catchError(this.handleError('getGenres', [])),
    );
  }
}
