import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {NiveauModel} from '../model/niveau.model';
import {ApplicationService} from './application.service';

@Injectable({
  providedIn: 'root',
})
export class NiveauService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'niveaus', 'niveau');
  }

  getAll(): Observable<NiveauModel[]> {
    return this.httpClient.get(environment.serverUrl + '/niveaus' , {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.niveaus;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }
}
