import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {KeycloakService} from 'keycloak-angular';
import {catchError, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {of, throwError} from 'rxjs';
import Swal from 'sweetalert2';
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(public keycloakService: KeycloakService, private router: Router) {}



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { enableBearerInterceptor, excludedUrls } = this.keycloakService;
    if (!enableBearerInterceptor) {
      return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
    }
    return this.keycloakService.addTokenToHeader(request.headers).pipe(mergeMap((headersWithBearer => {
      const kcReq = request.clone({ headers: headersWithBearer });
      return next.handle(kcReq).pipe(catchError(x => this.handleAuthError(x)));
    })));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      Swal.fire(
        'Vous n\'êtes pas autorisés à effectuer cette action.',
        '',
        'error',
      );
      // if you've caught / handled the error, you don't want to rethrow
      // it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

}
