import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {FormArray, FormGroup} from '@angular/forms';
import {GrilleModel} from '../model/grille.model';
import {GrilleNiveauModel} from '../model/grilleNiveau.model';
import {GrilleFonctionModel} from '../model/grilleFonction.model';
import {GrilleTypeModel} from '../model/grilleType.model';



@Injectable({
  providedIn: 'root',
})
export class GrilleService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'grilles', 'grille');
  }

  getById(id: number): Observable<GrilleModel> {
    const url = environment.serverBaseUrl + `grille/getGrille/${id}`;
    return this.httpClient.get<GrilleModel>(url).pipe(
      tap(_ => console.log(`fetched contrat grille id=${id}`)),
      catchError(this.handleError<GrilleModel>(`get GrilleModel id=${id}`)),
    );
  }


  getByContractId(id: number): Observable<GrilleModel> {
    const url = environment.serverUrl + `/contrats/${id}/grille`;
    return this.httpClient.get<GrilleModel>(url).pipe(
      tap(_ => console.log(`fetched contrat grille id=${id}`)),
      catchError(this.handleError<GrilleModel>(`get GrilleModel id=${id}`)),
    );
  }

  addToContrat(contratId: number, group: FormGroup): void {
    const formArray = group.get('grilles') as FormArray;
    const grilles = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const grille = {
          id: 0,
          personne: '',
          dateNaissance: '',
          acharge: '',
          scolarise: '',
          nom: '',
          prenom: '',
          prenomDeux: '',
          etablissement: '',
          responsableDeux: '',
          creationDate: '',
          grilleEtablissement: '',
          action: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'id':
              grille.personne = environment.serverUrl + `/personnes/${contratId}`;
              if ( Number(control.controls['id'].value) > 0) {
                grille.id = Number(control.controls['id'].value);
                grille.action = 'PATCH';
              } else {
                grille.action = 'POST';
              }
              break;
            case 'responsableDeux':
              if (control.controls['responsableDeux'].value !== undefined
                && control.controls['responsableDeux'].value !== null
                && control.controls['responsableDeux'].value.id !== undefined
                && Number(control.controls['responsableDeux'].value.id) > 0 ) {
                grille.responsableDeux =
                  environment.serverUrl + `/personnes/${control.controls['responsableDeux'].value.id}`;
              }
              break;
            case 'grilleEtablissement':
              if (control.controls['grilleEtablissement'].value !== undefined
                && Number(control.controls['grilleEtablissement'].value) > 0 ) {
                grille.grilleEtablissement =
                  environment.serverUrl + `/grilleEtablissements/${control.controls['grilleEtablissement'].value}`;
              }
              break;
            case 'dateNaissance':
              grille.dateNaissance = control.controls['dateNaissance'].value;
              break;
            case 'acharge':
              grille.acharge = control.controls['acharge'].value;
              break;
            case 'scolarise':
              grille.scolarise = control.controls['scolarise'].value;
              break;
            case 'nom':
              grille.nom = control.controls['nom'].value;
              break;
            case 'prenom':
              grille.prenom = control.controls['prenom'].value;
              break;
            case 'prenomDeux':
              grille.prenomDeux = control.controls['prenomDeux'].value;
              break;
          }
        });
        if (grille.action === 'POST') {
          delete grille['id'];
          this.add(grille).subscribe((model: any) => {
            }, (err: any) => {
              console.log('Error', err);
            },
          );
        } else if (grille.action === 'PATCH') {
          this.update(grille['id'], grille).subscribe((model: any) => {
            }, (err: any) => {
              console.log('Error', err);
            },
          );
        }
        grilles.push(grille);
      }
    }
  }

  getRits(): Observable<string[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getRits` , {
        params : new HttpParams()
          .set('size' , this.size.toString()),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getRits', [])),
    );
  }

  getCategories(rit: string): Observable<GrilleNiveauModel[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getCategories` , {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getNiveaus', [])),
    );
  }


  getNiveaus(rit: string, categoryId: number): Observable<GrilleNiveauModel[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getNiveaus/${categoryId}` , {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getNiveaus', [])),
    );
  }

  getFonctions(rit: string, categoryId: number, niveauId: number): Observable<GrilleFonctionModel[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getFonctions/${categoryId}/${niveauId}` , {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getFonctions', [])),
    );
  }

  getTypes(rit: string, categoryId: number,
           niveauId: number,
           fonctionId: number): Observable<GrilleTypeModel[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getTypes/${categoryId}/${niveauId}/${fonctionId}` , {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getTypes', [])),
    );
  }


  getDescriptions(rit: string,
                  categoryId: number,
                  niveauId: number,
                  fonctionId: number,
                  grilleTypeId: number): Observable<string[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl + `grille/getDescriptions/${categoryId}/${niveauId}/${fonctionId}/${grilleTypeId}` ,
      {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getDescriptions', [])),
    );
  }


  getEchelons(rit: string,
              categoryId: number,
              niveauId: number,
              fonctionId: number,
              grilleTypeId: number,
              description: string): Observable<string[]> {
    // @ts-ignore
    return this.httpClient.get(
environment.serverBaseUrl +
      `grille/getEchelons/${categoryId}/${niveauId}/${fonctionId}/${grilleTypeId}/${description}`,
      {
        params : new HttpParams()
          .set('rit' , rit),
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getEchelons', [])),
    );
  }

  getDureeAndIndiceBrut(rit: string,
                        datePromotion: string,
                        reliquat: number,
                        categoryId: number,
                        niveauId: number,
                        fonctionId: number,
                        grilleTypeId: number,
                        description: string,
                        echelon: string): Observable<string[]> {
    // @ts-ignore
    return this.httpClient.get(
      environment.serverBaseUrl +
      `grille/getDureeAndIndiceBrut/${categoryId}/${niveauId}/${fonctionId}/${grilleTypeId}/${description}/${echelon}`,
      {
        params : new HttpParams()
          .set('rit' , rit)
          .set('datePromotion' , datePromotion)
          .set('reliquat' , reliquat)
        ,
      } ).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError('getDureeAndIndiceBrut', [])),
    );
  }






}
