import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {DocumentModel} from '../../../../../lyautey/model/document.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentService} from '../../../../../lyautey/services/document.service';
import {Observable} from 'rxjs';
import {TypeDocumentModel} from '../../../../../lyautey/model/typeDocument.model';
import {TypeDocumentsService} from '../../../../../lyautey/services/type-documents.service';
import Swal from 'sweetalert2';
import {CanComponentDeactivate} from '../../../../../lyautey/guards/can-deactivate.guard';

@Component({
    selector: 'ngx-edit-document',
    templateUrl: './edit-document.component.html',
    styleUrls: ['./edit-document.component.scss'],
})
export class EditDocumentComponent implements OnInit, CanComponentDeactivate {

    @Input() document: DocumentModel;
    groupedTypeDocuments: any;

    editForm: FormGroup;
    typesDocuments: Observable<TypeDocumentModel[]>;

    constructor(protected ref: NbDialogRef<EditDocumentComponent>,
                private documentsService: DocumentService,
                private typeDocumentsService: TypeDocumentsService,
                private fb: FormBuilder) {
        this.typeDocumentsService.getAll().subscribe(data => {
            console.log('typeDocumentsService', data);
            this.groupedTypeDocuments = this.groupByCategory(data);
        });
    }

    dismiss() {
        this.ref.close();
    }

    ngOnInit(): void {
        this.editForm = this.fb.group({
            id: ['', Validators.required],
            description: ['', Validators.required],
            typeDocument: ['', Validators.required],
        });
        this.editForm.patchValue({
            id: this.document.id,
            description: this.document.description,
            typeDocument: this.document.typeDocument,
        });
        this.documentsService.getByUrl(this.document._links.typeDocument.href).subscribe(value => {
            if (value !== undefined) {
                this.editForm.patchValue({
                    typeDocument: value.id,
                });
            }
        });
    }

    upload() {
        this.documentsService.updateDocument(this.editForm);
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Votre modification a été bien enregistrée.`,
        }).then(() => {
            this.ref.close();
        });
    }

    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }

    private groupByCategory(documents: TypeDocumentModel[]) {
        const groups = {};
        documents.forEach(doc => {
            if (!groups[doc.categorie]) {
                groups[doc.categorie] = [];
            }
            groups[doc.categorie].push(doc);
        });
        return groups;
    }

}
