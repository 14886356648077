<nb-card status="primary" *nbIsGranted="['listing', 'search']">
  <nb-card-header>Mes listes</nb-card-header>
  <nb-card-body>
      <ngx-spinner  size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Veuillez patienter s'il vous plait... </p></ngx-spinner>

      <div class="form-row">
      <div class="form-group col-md-12">
        <table class="table table-striped table-bordered display responsive" width="100%" >
          <thead>
            <tr>
              <th class="all">Libelle</th>
              <th class="all">Type</th>
              <th class="all">Date de création</th>
              <th class="all">Date de la dernière modification</th>
              <th class="all">User</th>
              <th class="all">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of mesListes">
              <td>{{row.description}}</td>
              <td>
                <span *ngIf="row.visibilite === '1'">Liste personnelle</span>
                <span *ngIf="row.visibilite === '2'">Liste commune</span>
                <span *ngIf="row.visibilite === '3'">Liste commune verrouillée</span>
              </td>
              <td>
                <span style="display: none">{{row.creationDate  | amDateFormat:'YYYY-MM-DD hh:mm:ss' }}</span>
                {{row.creationDate  | amDateFormat:'DD/MM/YYYY hh:mm:ss' }}
              </td>
              <td>
                <span style="display: none">{{row.dateModification  | amDateFormat:'YYYY-MM-DD hh:mm:ss' }}</span>
                {{row.dateModification  | amDateFormat:'DD/MM/YYYY hh:mm:ss' }}
              </td>
              <td>{{row.user}}</td>
              <td>
                <div class="form-inline">
                  <div class="btn-group" btnRadioGroup>
                    <label *nbIsGranted="['preview', 'search']" class="btn btn-secondary" style="cursor: pointer" role="button" btnRadio="Right" (click)="preview(row.id)" title="Aperçu"><i class="fas fa-eye"></i></label>
                    <label *nbIsGranted="['download', 'personnel']"class="btn btn-primary" style="cursor: pointer" role="button" btnRadio="Right" (click)="download(row.id)" title="Télécharger cette liste"><i class="fas fa-file-excel"></i></label>
                    <ng-container *ngIf="currentUser === row.user">
                      <label *ngIf="row.visibilite === '1'" class="btn btn-warning" style="cursor: pointer" role="button" btnRadio="Right" (click)="redirect(row.id)" title="Modifier"><i class="fas fa-edit"></i></label>
                      <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left" (click)="beforeDelete(row.id, 'Suppression de liste', 'Cette action n\'est pas revocable')" title="Supprimer"><i class="fas fa-trash"></i></label>

                    </ng-container>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>

