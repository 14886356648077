import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {FormArray, FormGroup} from '@angular/forms';
import {EnfantModel} from '../model/enfant.model';
import {DbDate} from '../shared/dbDate';

@Injectable({
  providedIn: 'root',
})
export class EnfantService extends ApplicationService {

  constructor(httpClient: HttpClient, private dbDate: DbDate) {
    super(httpClient, 'enfants', '');
  }

  getByPersonId(id: number): Observable<EnfantModel> {
    const url = environment.serverUrl + `/personnes/${id}/enfants`;
    return this.httpClient.get<EnfantModel>(url).pipe(
      tap(_ => console.log(`fetched personne enfant id=${id}`)),
      catchError(this.handleError<EnfantModel>(`get EnfantModel id=${id}`)),
    );
  }

  addToPerson(personneid: number, group: FormGroup): void {
    const formArray = group.get('enfants') as FormArray;
    const enfants = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const enfant = {
          id: 0,
          personne: '',
          dateNaissance: '',
          acharge: '',
          scolarise: '',
          nom: '',
          prenom: '',
          prenomDeux: '',
          etablissement: '',
          responsableDeux: '',
          creationDate: '',
          enfantEtablissement: '',
          action: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'id':
              enfant.personne = environment.serverUrl + `/personnes/${personneid}`;
              if ( Number(control.controls['id'].value) > 0) {
                enfant.id = Number(control.controls['id'].value);
                enfant.action = 'PATCH';
              } else {
                enfant.action = 'POST';
              }
              break;
            case 'responsableDeux':
              if (control.controls['responsableDeux'].value !== undefined
                && control.controls['responsableDeux'].value !== null
                && control.controls['responsableDeux'].value.id !== undefined
                && Number(control.controls['responsableDeux'].value.id) > 0 ) {
                enfant.responsableDeux =
                  environment.serverUrl + `/personnes/${control.controls['responsableDeux'].value.id}`;
              }
              break;
            case 'enfantEtablissement':
              if (control.controls['enfantEtablissement'].value !== undefined
                && Number(control.controls['enfantEtablissement'].value) > 0 ) {
                enfant.enfantEtablissement =
                  environment.serverUrl + `/enfantEtablissements/${control.controls['enfantEtablissement'].value}`;
              }
              break;
            case 'dateNaissance':
              enfant.dateNaissance = this.dbDate.format(control.controls['dateNaissance'].value);
              break;
            case 'acharge':
              enfant.acharge = control.controls['acharge'].value;
              break;
            case 'scolarise':
              enfant.scolarise = control.controls['scolarise'].value;
              break;
            case 'nom':
              enfant.nom = control.controls['nom'].value;
              break;
            case 'prenom':
              enfant.prenom = control.controls['prenom'].value;
              break;
            case 'prenomDeux':
              enfant.prenomDeux = control.controls['prenomDeux'].value;
              break;
          }
        });
        if (enfant.action === 'POST') {
          delete enfant['id'];
          this.add(enfant).subscribe((model: any) => {
            }, (err: any) => {
              console.log('Error', err);
            },
          );
        } else if (enfant.action === 'PATCH') {
          this.update(enfant['id'], enfant).subscribe((model: any) => {
            }, (err: any) => {
              console.log('Error', err);
            },
          );
        }
        enfants.push(enfant);
      }
    }
  }

}
