import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import {KeyValueSupport} from '../../../lyautey/shared/commons';


@Component({
    selector: 'ngx-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {

    dataTable: any;
    dataTableSettings: any = {
        fixedColumns: true, columnDefs: [
            {type: 'date-eu', targets: 1}, {type: 'date-eu', targets: 2}],
    };
    private blob: Blob;

    columns = [];
    result = [];
    settings = {};


    months: KeyValueSupport[] = [
        {value: 'PREVIOUS_MONTH', viewValue: 'Mois dernier', disabled: false},
        {value: 'CURRENT_MONTH', viewValue: 'Mois en cours', disabled: false},
        {value: 'NEXT_MONTH', viewValue: 'Prochain mois', disabled: false},
        {value: 'NEXT_TWO_MONTH', viewValue: 'Deux prochains mois', disabled: false},
    ];
    userForm: FormGroup;

    constructor(private fb: FormBuilder,
                private router: Router,
                private chRef: ChangeDetectorRef,
                private personnelService: PersonnelService) {
    }


    submitForm() {
        $('table').DataTable().clear().destroy();
        this.personnelService.alertePromotion(
            {'month': this.userForm.get('month').value === undefined ? 'NEXT_MONTH' : this.userForm.get('month').value})
            .subscribe((result: any) => {
                    this.result = result.data;
                    this.chRef.detectChanges();
                    // Now you can use jQuery DataTables :
                    // @ts-ignore
                    const table: any = $('table');
                    // @ts-ignore
                    this.dataTable = table.DataTable(this.dataTableSettings);
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: `Veuillez reessayer plus tard!`,
                    });
                },
            );
    }

    ngOnInit() {
        this.userForm = this.fb.group({
            month: ['NEXT_MONTH'],
        });
        this.personnelService.alertePromotion(
            {'month': this.userForm.get('month').value === undefined ? 'NEXT_MONTH' : this.userForm.get('month').value})
            .subscribe((result: any) => {
                this.result = result.data;
                this.columns = result.columns;
                console.log('result', result);
                }, (err: any) => {
                    console.error(err);
                    Swal.fire({
                        icon: 'error',
                        title: `Veuillez reessayer plus tard!`,
                    });
                },
            );
    }

    redirect(id: any, sub: string) {
        this.router.navigate(['/pages/personnel/personne/', id, sub]);
    }

    download() {
        this.personnelService.downloadAlertePromotion({'month': this.userForm.get('month').value === undefined ? 'NEXT_MONTH' : this.userForm.get('month').value})
            .subscribe(data => {
                console.log('data', data);
            this.blob = new Blob([data], {type: 'application/octet-stream'});
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'AlerteProchainePromotion.xlsx';
            link.click();
        });
    }
}