<nb-card status="primary" style="margin-top: 10px" *nbIsGranted="['create', 'document']">
  <nb-card-header>
  Nouveau accident de travail
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]='editForm' autocomplete="off" (ngSubmit)="onSubmit()">

      <div class="form-row">

        <div class="form-group col-md-3">
          <label for="dateDebut">Date début</label>
          <input type="hidden" class="form-control" formControlName="id" />
        </div>

        <div class="form-group col-md-3">
          <div class="input-group">
            <input type="text" class="form-control"  formControlName="dateDebut" id="dateDebut"
                   placeholder="dd/mm/yyyy" ngbDatepicker #d1="ngbDatepicker"
                   container="body"
                   placement="top"
                   [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="dateFin">Date fin</label>
        </div>

        <div class="form-group col-md-3">
          <div class="input-group">
            <input type="text" class="form-control"  formControlName="dateFin" id="dateFin"
                   placeholder="dd/mm/yyyy" ngbDatepicker #d2="ngbDatepicker"
                   container="body"
                   placement="top"
                   [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
            </div>
          </div>
        </div>

      </div>
      <div class="form-row">

        <div class="form-group col-md-3">
          <label for="conclusion">Conclusion</label>
        </div>

        <div class="form-group col-md-6">
          <textarea  class="form-control" id="conclusion"  formControlName="conclusion" cols="60" rows="5">
            </textarea>
        </div>

      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="file">Rapport</label>
        </div>
        <div class="form-group col-md-6">
          <input class="form-control" type="file" id="file"  formControlName="file" (change)="onFileSelect($event)" />
        </div>
        <div class="form-group col-md-1" *ngIf="editForm.get('id').value > 0">
          <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="quickViewByUrl(editForm.get('documentUrl').value)" title="Aperçu"> <i class="fas fa-eye"></i></label>
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
        </div>
        <div class="form-group col-md-1">
          <button class="btn btn-success" type="submit">
            &nbsp;{{buttonText}}
          </button>
        </div>

        <div class="form-group col-md-1" *ngIf="editForm.get('id').value > 0">
          <button class="btn btn-secondary" type="button" (click)="resetEditForm()">
            Annuler
          </button>
        </div>

        <div class="form-group col-md-1" *ngIf="editForm.get('id').value > 0">
          <button class="btn btn-danger" type="button" (click)="beforeDelete(editForm.get('id').value, editForm.get('documentUrl').value, 'Suppression d\'accident de travail', 'Cette action n\'est pas revocable')">
            Supprimer
          </button>
        </div>
      </div>



    </form>
  </nb-card-body>
</nb-card>

<nb-card status="primary"  *nbIsGranted="['listing', 'document']">
  <nb-card-header *ngIf="accidentTravails.length > 0">
    Liste des accidents de travail
  </nb-card-header>
  <nb-card-body>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Date création</th>
        <th scope="col">Conclusion</th>
        <th scope="col">Date début</th>
        <th scope="col">Date fin</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of accidentTravails; let i=index">
        <td>{{ item.id }}</td>
        <td>
        <span style="display: none">{{item.creationDate | amDateFormat:'YYYY-MM-DD' }}</span>
        {{item.creationDate | amDateFormat:'DD/MM/YYYY' }}
        </td>
        <td>{{ item.conclusion }}</td>
        <td>
            <span style="display: none">{{item.dateDebut | amDateFormat:'YYYY-MM-DD' }}</span>
            {{item.dateDebut | amDateFormat:'DD/MM/YYYY' }}
        </td>
        <td>
            <span style="display: none">{{item.dateFin | amDateFormat:'YYYY-MM-DD' }}</span>
            {{item.dateFin | amDateFormat:'DD/MM/YYYY' }}
        </td>
        <td>
          <div class="form-inline" style="float: right">
            <div class="btn-group" btnRadioGroup >
              <label class="btn btn-primary" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="downloadFile(item)" title="Télécharger ce document"> <i class="fas fa-file-download"></i></label>
              <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="quickView(item._links.document.href)" title="Aperçu"> <i class="fas fa-eye"></i></label>
              <label class="btn btn-warning" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['create', 'document']" (click)="editDocument(item)" title="Modifier"> <i class="fas fa-edit"></i></label>
              <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left" *nbIsGranted="['download', 'document']" (click)="beforeDelete(item.id, item._links.document.href, 'Suppression d\'accident de travail', 'Cette action n\'est pas revocable')" title="Supprimer"><i class="fas fa-trash"></i></label>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    </div>
  </nb-card-body>

</nb-card>

