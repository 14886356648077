<nb-card>
  <nb-card-header>Type: <b>{{ document.typeDocument }}</b>, Réf: <b>{{ document.id }}</b></nb-card-header>
  <nb-card-body>
    <ngx-doc-viewer [url]="fileSrc" [viewer]="viewer" style="width:100%;height:50vh;"></ngx-doc-viewer>

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Fermer</button>
  </nb-card-footer>
</nb-card>
