<nb-card status="primary" style="margin-top: 10px" *ngIf="hasTitulaireDetache">
    <nb-card-header>
        Nouvelle évaluation
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]='editForm' autocomplete="off" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="etablissement">Etablissement</label>
                </div>
                <div class="form-group col-md-5">
                    <select class="form-control" formControlName="etablissement" id="etablissement">
                        <option *ngFor="let item of (contrats )" [ngValue]="item.etablissementId ">
                            {{ item.etablissement | uppercase }} - {{ item.fonction }} -
                            [{{ item.status | uppercase }}]
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="dateCloture">Date de l'évaluation</label>
                </div>
                <div class="form-group col-md-4">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Date de la promotion en vigueur"
                               formControlName="dateCloture" id="dateCloture"
                               container="body" ngbDatepicker #d="ngbDatepicker"
                               placement="top"
                               [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                        />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                    type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label class="control-label" for="anneeScolaire">Année scolaire</label>
                </div>
                <div class="form-group col-md-4">
                    <select class="form-control" fullWidth formControlName="anneeScolaire" id="anneeScolaire">
                        <option *ngFor="let item of this.globalObject.anneeScolaire" [ngValue]="item.value ">
                            {{ item.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">

                <div class="form-group col-md-2">
                    <label for="file">Evaluation</label>
                </div>
                <div class="form-group col-md-4">
                    <input class="form-control" type="file" id="file" formControlName="file"
                           (change)="onFileSelect($event)"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3">
                    <button class="btn btn-success" type="submit">Sauvegarder</button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>


<ng-container *nbIsGranted="['view', 'evaluation']">
    <nb-card status="primary" style="margin-top: 10px"
             *ngIf="result['evaluationDetails'] !== undefined && result['evaluationDetails'].length >0">
        <nb-card-header>
            Liste des évaluations
        </nb-card-header>
        <nb-card-body>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <table class="table table-striped table-bordered display responsive" width="100%">
                        <thead>
                        <tr>
                            <th class="all">Évaluateur</th>
                            <th class="all">Etablissement</th>
                            <th class="all">Année Scolaire</th>
                            <th class="all">Type évaluation</th>
                            <th class="all">Date de validation</th>
                            <th class="all">Date clôture</th>
                            <th *nbIsGranted="['download', 'evaluation']" class="all">Evaluation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of result['evaluationDetails']">
                            <td>{{ row.nomEvaluateur }} {{ row.prenomEvaluateur }}</td>
                            <td>{{ row.etablissement }}</td>
                            <td>{{ row.anneeScolaire }}</td>
                            <td>{{ row.type }}</td>
                            <td>
                                <span style="display: none">{{ row.dateValidation | amDateFormat:'YYYY-MM-DD' }}</span>
                                {{ row.dateValidation | amDateFormat:'DD/MM/YYYY' }}
                            </td>
                            <td>
                                <span style="display: none">{{ row.dateCloture | amDateFormat:'YYYY-MM-DD' }}</span>
                                {{ row.dateCloture | amDateFormat:'DD/MM/YYYY' }}
                            </td>
                            <td *nbIsGranted="['download', 'evaluation']">
                                <div class="form-inline">
                                    <div class="btn-group" btnRadioGroup>
                                        <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right"
                                               (click)="quickView(row.id, row.personneId)" title="Aperçu"> <i
                                                class="fas fa-eye"></i></label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
    <nb-card status="secondary" style="margin-top: 10px"
             *ngIf="result['evaluationManualDetails'] !== undefined && result['evaluationManualDetails'].length >0">
        <nb-card-header>
            Liste des évaluations
        </nb-card-header>
        <nb-card-body>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <table class="table table-striped table-bordered display responsive" width="100%">
                        <thead>
                        <tr>
                            <th class="all">Établissement</th>
                            <th class="all">Année Scolaire</th>
                            <th class="all">Date clôture</th>
                            <th *nbIsGranted="['download', 'evaluation']" class="all">Evaluation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of result['evaluationManualDetails']">
                            <td>{{ row.etablissement }}</td>
                            <td>{{ row.anneeScolaire }}</td>
                            <td>
                                <span style="display: none">{{ row.dateCloture | amDateFormat:'YYYY-MM-DD' }}</span>
                                {{ row.dateCloture | amDateFormat:'DD/MM/YYYY' }}
                            </td>
                            <td *nbIsGranted="['download', 'evaluation']">
                                <div class="form-inline">
                                    <div class="btn-group" btnRadioGroup>
                                        <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right"
                                               (click)="quickView(row.id, row.personneId)" title="Aperçu"> <i
                                                class="fas fa-eye"></i></label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</ng-container>



