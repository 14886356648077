<div class="timeline-container" *ngFor="let avancement of avancements">
    <div class="timeline-entry">
        <div class="timeline-label">{{ avancement.datePromotion | amDateFormat:'DD/MM/YYYY' }}</div>
        <div class="timeline-content">
            <p>&nbsp;</p>
            <p>
                <strong>Grille:</strong> {{ avancement.grilleType }} {{ avancement.grilleDescription }},
                <strong>Echelon:</strong> {{ avancement.echelon }},
                <strong>Indice Brut:</strong> {{ avancement.indiceBrut }}</p>
            <p>
                <strong>Type avancement:</strong> {{ avancement.typeAvancement }},
                <strong>Nombre de mois gagnes:</strong> {{ avancement.promotionMoisGagnes }},
            </p>
        </div>
    </div>
</div>