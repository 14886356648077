import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {EtablissementService} from '../../../lyautey/services/etablissement.service';

import {EtablissementModel} from '../../../lyautey/model/etablissement.model';
import {SearchQueryService} from '../../../lyautey/services/search-query.service';
import * as moment from 'moment';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'ngx-carte-personnelle',
  templateUrl: './carte-personnelle.component.html',
  styleUrls: ['./carte-personnelle.component.scss'],
})
export class CartePersonnelleComponent implements OnInit {

  editForm: FormGroup;
  submitted: boolean = false;
  etablissements: Observable<EtablissementModel[]>;
  blob: Blob;
  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private personnelService: PersonnelService,
              private searchQueryService: SearchQueryService,
              private etablissementService: EtablissementService) { }

  ngOnInit() {
    this.etablissements = this.etablissementService.getAll();
    this.editForm = this.fb.group({
      etablissement: ['', Validators.required],
    });

    this.editForm.valueChanges.subscribe(
      (selectedValue) => {
      });
    this.editForm.valueChanges.subscribe({
      next: (value) => {
      },
    });
  }




  onFormSubmit() {
    this.spinner.show();
    this.personnelService.downloadCarte(this.editForm.get('etablissement').value).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      const now = moment().locale('fr').format();
      link.download = 'cartes-professionnelles-' + now + '.zip';
      this.spinner.hide();
      link.click();
    });
  }









}
