import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {EnfantEtablissementModel} from '../model/enfantEtablissement.model';
import {ApplicationService} from './application.service';

@Injectable({
  providedIn: 'root',
})
export class EnfantEtablissementService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'enfantEtablissements', '');
  }

  getAll(): Observable<EnfantEtablissementModel[]> {
    return this.httpClient.get(environment.serverUrl + '/enfantEtablissements' , {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        return response._embedded.enfantEtablissements;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  findById(id: number ): Observable<EnfantEtablissementModel> {
    return this.httpClient.get<EnfantEtablissementModel>(environment.serverUrl + `/enfants/${id}/enfantEtablissement`)
      .pipe(
      catchError(this.handleError<EnfantEtablissementModel>(`findById`)),
    );
  }

  getByUrl(url: string ): Observable<EnfantEtablissementModel> {
    return this.httpClient.get<EnfantEtablissementModel>(url).pipe(
      catchError(this.handleError<EnfantEtablissementModel>(`getOne`)),
    );
  }


}
