import {NgModule} from '@angular/core';

import { AppCommonRoutingModule } from './app-common-routing.module';
import { ListPageComponent } from './list-page/list-page.component';
import {NbButtonModule, NbCardModule, NbIconModule} from '@nebular/theme';
import {DataTablesModule} from 'angular-datatables';
import { FormsModule as ngFormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [ListPageComponent],
  imports: [
    AppCommonRoutingModule,
    NbCardModule,
    NbIconModule,
    DataTablesModule,
    ngFormsModule,
    CommonModule,
    NbButtonModule,
  ],
  exports: [
    ListPageComponent,
  ],
})
export class AppCommonModule { }
