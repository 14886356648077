import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UploadFileService} from '../../../../lyautey/services/upload-file.service';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TypeDocumentsService} from '../../../../lyautey/services/type-documents.service';
import {TypeDocumentModel} from '../../../../lyautey/model/typeDocument.model';
import {DocumentService} from '../../../../lyautey/services/document.service';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {NbDialogService} from '@nebular/theme';
import {ViewImageComponent} from '../documents/view-image/view-image.component';
import {ViewPdfComponent} from '../documents/view-pdf/view-pdf.component';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {PersonneModel} from '../../../../lyautey/model/personnel.model';
import {PersonnelService} from '../../../../lyautey/services/personnel.service';
import {CustomDatepickerI18n, I18n} from '../../../../lyautey/shared/CustomDatepickerI18n';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {DatePipe} from '@angular/common';
import {NgbDateMomentParserFormatter} from '../../../../lyautey/shared/NgbDateMomentParserFormatter';
import {AccidentTravailModel} from '../../../../lyautey/model/AccidentTravail.model';
import {CteService} from '../../../../lyautey/services/cte.service';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';


@Component({
    selector: 'ngx-cte',
    templateUrl: './cte.component.html',
    styleUrls: ['./cte.component.scss'],
    providers: [
        I18n,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        DatePipe,
        {
            provide: NgbDateMomentParserFormatter,
            useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
        },
    ],
})
export class CteComponent implements OnInit, CanComponentDeactivate {
    buttonText: string = 'Ajouter';
    selectedFiles: FileList;
    currentFile: File;
    progress = 0;
    message = '';
    fileInfo = {};
    editForm: FormGroup;
    globalObject: GlobalObject = new GlobalObject();
    years = [];
    ctes: any = [];
    typesDocuments: Observable<TypeDocumentModel[]>;
    fullName = '';
    private downloadUrl: string;
    private blob: Blob;

    constructor(private http: HttpClient,
                private uploadService: UploadFileService,
                private documentsService: DocumentService,
                private service: CteService,
                private dialogService: NbDialogService,
                private personnelService: PersonnelService,
                private sanitizer: DomSanitizer,
                private typeDocumentsService: TypeDocumentsService,
                private route: ActivatedRoute,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.buttonText = 'Ajouter';
        this.generateArrayOfYears();
        this.downloadUrl = environment.serverBaseUrl + `document/download/`;
        this.editForm = this.fb.group({
            id: [''],
            dateDebut: ['', Validators.required],
            dateFin: ['', Validators.required],
            visa: ['', Validators.required],
            file: [''],
            documentUrl: [''],
        });
        this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id) {
                this.personnelService.getPersonnel(id)
                    .subscribe(
                        (personneModel: PersonneModel) => {
                            this.fullName = personneModel.nomUsuel.toUpperCase() + ' ' +
                                personneModel.prenom.charAt(0).toUpperCase() + personneModel.prenom.toLowerCase().slice(1);
                        },
                        (err: any) => {
                        },
                    );
                this.service.getByPersonId(id).subscribe((data: any) => {
                    this.ctes = data._embedded.ctes;
                });
            }
        });
        this.typesDocuments = this.typeDocumentsService.getAll();
    }

    upload() {
        this.progress = 0;
        this.currentFile = this.selectedFiles.item(0);
        this.uploadService.upload(this.currentFile).subscribe(
            event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round(100 * event.loaded / event.total);
                } else if (event instanceof HttpResponse) {
                    this.fileInfo = event.body;
                    this.route.parent.paramMap.subscribe(params => {
                        const id: number = Number(params.get('id'));
                        if (id) {
                            this.documentsService.addToPerson(id, this.fileInfo, this.editForm);
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: `Votre modification a été bien enregistrée.`,
                            }).then(() => this.ngOnInit());
                        }
                    });
                }
            },
            err => {
                this.progress = 0;
                this.message = 'Could not upload the file!';
                this.currentFile = undefined;
            });
        this.selectedFiles = undefined;
    }

    editDocument(accidentTravailModel: AccidentTravailModel) {
        this.buttonText = 'Modifier';
        this.editForm.patchValue(accidentTravailModel);
        this.editForm.get('documentUrl').setValue(accidentTravailModel._links.document.href);
    }

    downloadFile(accidentTravailModel: AccidentTravailModel) {
        this.service.getByUrl(accidentTravailModel._links.document.href)
            .subscribe((documentModel: any) => {
                this.documentsService.downloadFile(documentModel.id).subscribe(data => {
                    this.blob = new Blob([data], {type: 'application/octet-stream'});
                    const downloadURL = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = documentModel.file;
                    link.click();
                });
            });
    }

    resetEditForm() {
        this.buttonText = 'Ajouter';
        this.editForm.reset({
            id: '',
            dateDebut: '',
            dateFin: '',
            visa: '',
            file: '',
            documentUrl: '',
        });
    }

    quickViewByUrl(documentUrl: string) {
        this.service.getByUrl(documentUrl)
            .subscribe((documentModel: any) => {
                this.documentsService.downloadFile(documentModel.id).subscribe(data => {
                    this.blob = new Blob([data], {type: 'application/octet-stream'});
                    const downloadURL = window.URL.createObjectURL(data);
                    const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
                    const extension: string = documentModel.file.split('.').pop();
                    if (imageExtensions.includes(extension.toLowerCase())) {
                        this.dialogService.open(ViewImageComponent, {
                            closeOnEsc: true,
                            autoFocus: true,
                            hasScroll: true,
                            hasBackdrop: true,
                            context: {
                                document: documentModel,
                                fileSrc: this.sanitizer.bypassSecurityTrustUrl(downloadURL),
                            },
                        });
                    } else {
                        const viewer = extension === 'pdf' ? 'pdf' : 'office';
                        this.dialogService.open(ViewPdfComponent, {
                            closeOnEsc: true,
                            autoFocus: true,
                            hasScroll: true,
                            hasBackdrop: true,
                            context: {
                                document: documentModel,
                                fileSrc: downloadURL,
                                viewer: viewer,
                            },
                        });
                    }
                });
            });
    }

    quickView(documentUrl: string) {
        this.service.getByUrl(documentUrl)
            .subscribe((documentModel: any) => {
                this.documentsService.downloadFile(documentModel.id).subscribe(data => {
                    this.blob = new Blob([data], {type: 'application/octet-stream'});
                    const downloadURL = window.URL.createObjectURL(data);
                    const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
                    const extension: string = documentModel.file.split('.').pop();
                    if (imageExtensions.includes(extension.toLowerCase())) {
                        this.dialogService.open(ViewImageComponent, {
                            closeOnEsc: true,
                            autoFocus: true,
                            hasScroll: true,
                            hasBackdrop: true,
                            context: {
                                document: documentModel,
                                fileSrc: this.sanitizer.bypassSecurityTrustUrl(downloadURL),
                            },
                        });
                    } else {
                        const viewer = extension === 'pdf' ? 'pdf' : 'office';
                        this.dialogService.open(ViewPdfComponent, {
                            closeOnEsc: true,
                            autoFocus: true,
                            hasScroll: true,
                            hasBackdrop: true,
                            context: {
                                document: documentModel,
                                fileSrc: downloadURL,
                                viewer: viewer,
                            },
                        });
                    }
                });
            });
    }

    deleteAccidentTravail(id: number, documentUrl: string) {
        if (Number(id) > 0) {
            if (documentUrl !== undefined && documentUrl !== null && documentUrl !== '') {
                this.service.getByUrl(documentUrl)
                    .subscribe((documentModel: any) => {
                            if (documentModel !== undefined && documentModel.id !== undefined && documentModel.id !== null) {
                                this.documentsService.deleteDocument(documentModel.id).subscribe(
                                    (data: any) => {
                                    }, (err: any) => {
                                        console.log('error in deleteDocument', err);
                                    },
                                );
                            }
                        },
                        (err: any) => {
                            console.log('error in getByUrl', err);
                        });
            }
            this.service.delete(id).subscribe((data: any) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Votre modification a été bien enregistrée.`,
                }).then(() => this.ngOnInit());
            }, (err: any) => {
            });
        }
    }

    beforeDelete(id: number, documentUrl: string, title, text) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                this.deleteAccidentTravail(id, documentUrl);
            }
        });
    }

    onSubmit() {
        this.route.parent.paramMap.subscribe(params => {
            const personneId: number = Number(params.get('id'));
            if (personneId) {
                this.progress = 0;
                this.currentFile = (this.selectedFiles !== undefined && this.selectedFiles !== null && this.selectedFiles.length > 0) ? this.selectedFiles.item(0) : undefined;
                this.service.addToPerson(personneId, this.editForm).subscribe((cteModel) => {
                    const accidentTravailId = cteModel['_links']['self']['href'].split('/').pop();
                    if (this.currentFile !== undefined || this.currentFile !== null) {
                        const documentDescription = this.fullName +
                            '_' + new Date().getFullYear() +
                            '_CTE';
                        this.documentsService.uploadAttachedDocument(
                            this.currentFile,
                            params.get('id'),
                            documentDescription,
                            '21',
                            accidentTravailId,
                            'cte',
                        ).subscribe(
                            event => {
                                if (event.type === HttpEventType.UploadProgress) {
                                    this.progress = Math.round(100 * event.loaded / event.total);
                                } else if (event instanceof HttpResponse) {
                                    this.service.updateDocument(accidentTravailId, event.body.documentId)
                                        .subscribe((model: any) => {
                                            }, (err: any) => {
                                            },
                                        );
                                    this.selectedFiles = undefined;
                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'success',
                                        title: `Votre modification a été bien enregistrée.`,
                                    }).then(() => this.ngOnInit());
                                }
                            },
                            err => {
                                this.selectedFiles = undefined;
                                this.progress = 0;
                                this.message = 'Could not upload the file!';
                                this.currentFile = undefined;
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: err.error.message,
                                });
                            });
                    }
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Votre modification a été bien enregistrée.`,
                    }).then(() => this.ngOnInit());
                }, (error) => {
                    console.error('Error occurred:', error);
                });
            }
        });
        this.selectedFiles = undefined;
    }

    onFileSelect(event) {
        this.selectedFiles = event.target.files;
    }

    generateArrayOfYears() {
        const max = new Date().getFullYear();
        const min = max - 50;
        for (let i = max; i >= min; i--) {
            this.years.push(i);
        }
    }
    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }
}
