<nb-card>
  <nb-card-header>Aperçu de votre liste [ {{ searchQuery.description }}]</nb-card-header>
  <nb-card-body>
    <div class="container">
      <div class="form-row">
        <div class="form-group col-md-12">
          <h4> {{ count }} éléments trouvés</h4>
        </div>
      </div>
      <div class="form-row" *ngIf="count > 0">
        <div class="form-group col-md-12">
          <table id="tableSample" class="table table-striped table-bordered display responsive" width="100%" >
            <thead>
            <tr>
            <tr>
              <th *ngFor="let column of columns; let index = index;">{{column}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let employee of data">
              <td *ngFor="let column of columns; let index = index;">{{employee[column]}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Fermer</button>
  </nb-card-footer>
</nb-card>
