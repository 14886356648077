import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {throwIfAlreadyLoaded} from '../@core/module-import-guard';
import { OnlyNumberDirective } from './directives/only-number.directive';
import {NbCardModule, NbIconModule} from '@nebular/theme';
import {DataTablesModule} from 'angular-datatables';
import { TreePipe } from './pipe/tree.pipe';
import {DragDropFileUploadDirective} from './directives/drag-drop-file-upload.directive';



@NgModule({
  declarations: [OnlyNumberDirective, TreePipe, DragDropFileUploadDirective],
  imports: [
    CommonModule,
    NbCardModule,
    DataTablesModule,
    NbIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LyauteyModule {
  constructor(@Optional() @SkipSelf() parentModule: LyauteyModule) {
    throwIfAlreadyLoaded(parentModule, 'LyauteyModule');
  }

  static forRoot(): ModuleWithProviders<LyauteyModule> {
    return {
      ngModule: LyauteyModule,
      /*      providers: [
              ...NB_CORE_PROVIDERS,
            ],*/
    };
  }
}

