import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {PosteModel} from '../model/poste.model';
import {FormGroup} from '@angular/forms';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root',
})
export class PosteService extends ApplicationService {
  searchData: any = {};

  constructor(httpClient: HttpClient) {
    super(httpClient, 'postes', 'poste');
  }

  getAll(): Observable<PosteModel[]> {
    return this.httpClient.get(environment.serverUrl + '/postes' , {
      params: new HttpParams().set('size' , this.size.toString())})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.postes;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<PosteModel> {
    return this.httpClient.get<PosteModel>(environment.serverUrl + `/contrats/${id}/poste`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<PosteModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<PosteModel> {
    return this.httpClient.get<PosteModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<PosteModel>(`getOne`)),
    );
  }

  markAsImported(posteId: number): void {
    const poste = {
      imported: true,
      updatedAt: moment().locale('en').format('YYYY-MM-DD'),
      importedDate: moment().toDate().getTime(),
    };
    this.update(posteId, poste).subscribe((model: any) => {
      }, (err: any) => {
        console.log('Error', err);
      },
    );
  }

  advancedSearch(group: FormGroup): any {
    this.setSearchData(group);
    return this.httpClient.post(`${environment.serverBaseUrl}poste/advancedSearch`,
      this.searchData, httpOptions).pipe(
      tap(_ => console.log(`advanced Search`)),
      catchError(this.handleError<any>('advancedSearch')),
    );
  }

  setSearchData(group: FormGroup): void {
    this.searchData.imported = group.get('imported').value;
  }
}
