import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {QuotiteHebdomadaireModel} from '../model/quotiteHebdomadaire.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
};
@Injectable({
  providedIn: 'root',
})
export class QuotiteHebdomadaireService extends ApplicationService {
  searchData: any = {};

  constructor(httpClient: HttpClient) {
    super(httpClient, 'quotiteHebdomadaires', 'quotiteHebdomadaire');
  }

  getAll(): Observable<QuotiteHebdomadaireModel[]> {
    return this.httpClient.get(environment.serverUrl + '/quotiteHebdomadaires' , {
      params: new HttpParams().set('size' , this.size.toString())})
        .pipe(
            map(response => {
              // @ts-ignore
              return response._embedded.postes;
            }),
            catchError(this.handleError('getAll', [])),
        );
  }

  findById(id: number ): Observable<QuotiteHebdomadaireModel> {
    return this.httpClient.get<QuotiteHebdomadaireModel>(environment.serverUrl + `/contrats/${id}/regime`)
        .pipe(
            // tap((value) => console.log(value)),
            catchError(this.handleError<QuotiteHebdomadaireModel>(`findById`)),
        );
  }

  getByUrl(url: string ): Observable<QuotiteHebdomadaireModel> {
    return this.httpClient.get<QuotiteHebdomadaireModel>(url).pipe(
        // tap((value) => console.log(value)),
        catchError(this.handleError<QuotiteHebdomadaireModel>(`getOne`)),
    );
  }

}
