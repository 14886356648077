<nb-card>
  <nb-card-header>Type: <b>{{ document.typeDocument }}</b>, Réf: <b>{{ document.id }}</b></nb-card-header>
  <nb-card-body>
    <form [formGroup]='editForm' autocomplete="off">
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="description">Libelle</label>
          <input type="hidden" class="form-control" formControlName="id" />
        </div>
        <div class="form-group col-md-9">
          <input type="description" class="form-control" id="description"  formControlName="description" [ngClass]="{'is-invalid': editForm.get('description').invalid && editForm.get('description').touched}" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="typeDocument">Type</label>
        </div>
        <div class="form-group col-md-9">
            <select class="form-control" placeholder="Type Document" formControlName="typeDocument" id="typeDocument"  [ngClass]="{'is-invalid': editForm.get('typeDocument').invalid && editForm.get('typeDocument').touched}">
                <optgroup *ngFor="let group of groupedTypeDocuments | keyvalue" label="{{ group.key }}">
                    <option *ngFor="let item of group.value" [ngValue]="item.id">
                        {{ item.description }}
                    </option>
                </optgroup>
            </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-10">
          &nbsp;
        </div>
        <div class="form-group col-md-2">
          <button class="btn btn-success form-control" (click)="upload()" [disabled]="editForm.invalid">Confirmer</button>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Fermer</button>
  </nb-card-footer>
</nb-card>
