import {Router} from '@angular/router';
import {Directive, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
@Directive()
export class DataTableOptionsDirective {
  dtOptions: any = {};
  constructor(private router: Router) {
  }
  setDataTableOptions(entity: string,
                      columns: any[],
                      buttons: string[],
                      editLink: string,
                      customList?: string): void {
    let url = environment.serverBaseUrl + entity + '/listing';
    if (customList !== undefined) {
      url = environment.serverBaseUrl + entity + '/' + customList;
    }
    this.dtOptions = {
      ajax: {
        url: url,
      },
      buttons: buttons,
      columns: columns,
      fixedHeader: true,
      colReorder: true,
      serverSide: true,
      processing: true,
      lengthChange: false,
      dom: 'Blfrtip',
      pageLength: 25,
      lengthMenu: [0, 10, 25, 50, 250, 500, 1000, 3000],
      language: {
        url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json',
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          // @ts-ignore
          this.router.navigate([editLink, data.id]);
        });
        return row;
      },
    };
  }
}
