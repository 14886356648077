import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {CustomDatepickerI18n, I18n} from '../../../../lyautey/shared/CustomDatepickerI18n';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {DatePipe} from '@angular/common';
import {NgbDateMomentParserFormatter} from '../../../../lyautey/shared/NgbDateMomentParserFormatter';
import {AvancementService} from '../../../../lyautey/services/avancement.service';
import {ContratService} from '../../../../lyautey/services/contrat.service';
import {EtablissementService} from '../../../../lyautey/services/etablissement.service';
import {FonctionService} from '../../../../lyautey/services/fonction.service';
import {forkJoin, of} from 'rxjs';
import {AvancementModel} from '../../../../lyautey/model/Avancement.model';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {DbDate} from '../../../../lyautey/shared/dbDate';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';

@Component({
    selector: 'ngx-avancement',
    templateUrl: './avancement.component.html',
    styleUrls: ['./avancement.component.scss'],
    providers: [
        I18n,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        DatePipe,
        {
            provide: NgbDateMomentParserFormatter,
            useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
        },
    ],
})
export class AvancementComponent implements OnInit, CanComponentDeactivate {
    buttonText: string = 'Ajouter';
    editForm: FormGroup;
    globalObject: GlobalObject = new GlobalObject();
    avancements: any = [];
    contrats: any = [];
    numericValue: string = '';

    constructor(private service: AvancementService,
                private etablissementService: EtablissementService,
                private contratService: ContratService,
                private fonctionService: FonctionService,
                private dbDate: DbDate,
                private route: ActivatedRoute,
                private fb: FormBuilder) {
    }
    ngOnInit() {
        this.editForm = this.fb.group({
            id: [''],
            contrat: ['', Validators.required],
            grilleType: ['', Validators.required],
            grilleDescription: ['', Validators.required],
            echelon: ['', Validators.required],
            duree: ['', Validators.required],
            indiceBrut: ['', Validators.required],
            reliquat: ['', Validators.required],
            typeAvancement: ['', Validators.required],
            dateAvancement: ['', Validators.required],
            datePromotion: ['', Validators.required],
            promotionMoisGagnes: ['', Validators.required],
        });
        this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id) {
                this.contratService.getByPersonId(id).subscribe((data: any) => {
                    this.contrats = [];
                    const observables = [];
                    if (data._embedded.contrats.length > 0) {
                        data._embedded.contrats.forEach((contrat) => {
                            const etablissementObservable = this.etablissementService
                                .getByUrl(contrat._links.etablissement.href);
                            const fonctionObservable = this.fonctionService
                                .getByUrl(contrat._links.typeFonction.href)
                                .pipe(catchError((error) => {
                                    console.error('Error fetching fonction:', error);
                                    return of({});
                                }));
                            const avancementObservable = this.contratService
                                .getByUrl(contrat._links.avancements.href)
                                .pipe(map(avancementResponse => {
                                    const avancements = avancementResponse['_embedded']?.avancements || [];
                                    // Sort avancements in descending order by indiceBrut
                                    avancements.sort((a, b) => b.indiceBrut - a.indiceBrut);
                                    return avancements;
                                }));
                            const combinedObservable =
                                forkJoin([etablissementObservable, fonctionObservable, avancementObservable])
                                    .pipe(map(([etablissement, typeFonction, avancements]) => ({
                                        'contrat': contrat.id,
                                        'status': contrat.status,
                                        'fonction': typeFonction['description'] || 'N/A',
                                        'etablissement': etablissement.description || 'N/A',
                                        'avancements': avancements,
                                        'viewType': 'table',
                                    })));
                            observables.push(combinedObservable);
                        });
                        forkJoin(observables).subscribe((contratResults) => {
                            this.contrats = contratResults;
                            const statusOrder = ['actif', 'suspendu', 'archive', 'radie'];
                            this.contrats.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
                        });
                    }
                });
            }
        });
    }

    editAvancement(avancementModel: AvancementModel, contrat: any) {
        this.buttonText = 'Modifier';
        console.log('avancementModel', avancementModel);
        console.log('contrat', contrat);

        this.editForm.patchValue(avancementModel);
        this.editForm.patchValue({contrat : contrat.contrat});

    }

    resetEditForm() {
        this.buttonText = 'Ajouter';
        this.editForm.reset({
            id: '',
            contrat: '',
            grilleType: '',
            grilleDescription: '',
            echelon: '',
            duree: '',
            indiceBrut: '',
            reliquat: '',
            typeAvancement: '',
            dateAvancement: '',
            datePromotion: '',
            promotionMoisGagnes: '',
        });
    }

    deleteAvancement(id: number) {
        if (Number(id) > 0) {
            this.service.delete(id).subscribe((data: any) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Votre modification a été bien enregistrée.`,
                }).then(() => this.ngOnInit());
            }, (err: any) => {
            });
        }
    }

    beforeDelete(id: number, title, text) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                this.deleteAvancement(id);
            }
        });
    }

    onSubmit() {
        const avancement = {
            'id': Number(this.editForm.get('id').value) ? this.editForm.get('id').value : 0,
            'contrat': `${environment.serverUrl}/contrats/${this.editForm.get('contrat').value}`,
            'grilleType': this.editForm.get('grilleType').value,
            'grilleDescription': this.editForm.get('grilleDescription').value,
            'echelon': this.editForm.get('echelon').value,
            'duree': this.editForm.get('duree').value,
            'indiceBrut': Number(this.editForm.get('indiceBrut').value) ? this.editForm.get('indiceBrut').value : 0,
            'reliquat': this.editForm.get('reliquat').value,
            'typeAvancement': this.editForm.get('typeAvancement').value,
            'promotionMoisGagnes': Number(this.editForm.get('promotionMoisGagnes').value) ? this.editForm.get('promotionMoisGagnes').value : 0,
            'dateAvancement': '',
            'datePromotion': '',
            'action': Number(this.editForm.get('id').value) && Number(this.editForm.get('id').value) > 0 ? 'PATCH' : 'POST',
        };
        if (this.editForm.get('dateAvancement').value !== undefined
            && this.editForm.get('dateAvancement').value !== null
            && this.editForm.get('dateAvancement').value !== '') {
            avancement.dateAvancement = this.dbDate.format(this.editForm.get('dateAvancement').value);
        }
        if (this.editForm.get('datePromotion').value !== undefined
            && this.editForm.get('datePromotion').value !== null
            && this.editForm.get('datePromotion').value !== '') {
            avancement.datePromotion = this.dbDate.format(this.editForm.get('datePromotion').value);
        }
        this.service.addToContract(avancement)
            .subscribe((model: any) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Votre modification a été bien enregistrée.`,
                    }).then(() => this.ngOnInit());
                },
                (err: any) => {
                });
    }

    getCardStatus(contratStatus: string): string {
        switch (contratStatus) {
            case 'actif':
                return 'success';
            case 'suspendu':
                return 'warning';
            case 'archive':
                return 'info';
            case 'radie':
                return 'danger';
            default:
                return 'secondary';
        }
    }

    setViewType(type: string, contrat: any) {
        contrat.viewType = type;
    }

    printContent() {
        const printContents = document.querySelector('.print-section')?.innerHTML;
        const originalContents = document.body.innerHTML;

        if (printContents) {
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        }
    }

    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }

}
