import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {KeyValueSupport} from '../../../lyautey/shared/commons';

@Component({
  selector: 'ngx-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {

  dataTable: any;
  dataTableSettings: any = { fixedColumns: true, columnDefs: [
    { type: 'date-eu', targets: 1 }, { type: 'date-eu', targets: 2 }]};
  statutContrats: KeyValueSupport[] = [
    {value: 'actif', viewValue: 'Actif', disabled: false},
    {value: 'suspendu', viewValue: 'Suspendu', disabled: false},
    {value: 'archive', viewValue: 'Archivé', disabled: false},
    {value: 'radie', viewValue: 'Radié', disabled: false},
    {value: 'sans_contrat', viewValue: 'Sans Contrat', disabled: false},
  ];
  result = [];
  settings = {};
  userForm: FormGroup;
  private blob: Blob;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private chRef: ChangeDetectorRef,
              private personnelService: PersonnelService) {
    this.createForm();
  }

  createForm() {
    this.userForm = this.fb.group({
      contratStatus: [''],
    });
  }

  submitForm() {
    $('table').DataTable().clear().destroy();
    this.personnelService.listingPersonnel(this.userForm, false, true)
      .subscribe((result: any) => {
        this.result = result.data;
        this.chRef.detectChanges();
        // Now you can use jQuery DataTables :
        // @ts-ignore
        const table: any = $('table');
        // @ts-ignore
        this.dataTable = table.DataTable(this.dataTableSettings);
        }, (err: any) => {
          Swal.fire({
            icon: 'error',
            title: `Veuillez reessayer plus tard!`,
          }) ;
        },
      );
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      contratStatus: [localStorage.getItem('contratStatus') || 'actif', Validators.required],
    });
    // Listen for changes
    this.userForm.get('contratStatus').valueChanges.subscribe(val => {
      localStorage.setItem('contratStatus', val);
    });
    this.personnelService.listingPersonnel(this.userForm, false, true)
      .subscribe((result: any) => {
          if (result !== undefined && result.data !== undefined) {
            this.result = result.data;
            this.chRef.detectChanges();
            // Now you can use jQuery DataTables:
            // @ts-ignore
            const table: any = $('table');
            this.dataTable = table.DataTable(this.dataTableSettings);
          }
        }, (err: any) => {
          console.error(err);
          Swal.fire({
            icon: 'error',
            title: `Veuillez reessayer plus tard!`,
          });
        },
      );
  }

  onItemSelect(item: any) {
  }

  OnItemDeSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  onDeSelectAll(items: any) {
  }

  redirect(id: any, sub: string) {
    this.router.navigate(['/pages/personnel/personne/', id, sub]);
  }

  certificat(id: number) {
    this.personnelService.downloadCertificat(id).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      const now = moment().locale('fr').format();
      link.download = 'certificat-' + now + '.pdf';
      link.click();
    });
  }
}
