import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonnelService} from '../../../../lyautey/services/personnel.service';

@Component({
  selector: 'ngx-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.scss'],
})
export class FormationComponent implements OnInit {
  dataTable: any;
  dataTableSettings: any = { fixedColumns: true};
  result = [];
  settings = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: PersonnelService,
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(params => {
      const id: number = Number(params.get('id'));
      if (id) {
        this.service.getFormations(id)
          .subscribe((data: any) => {
           this.result = data._embedded.formations;
          });
      }
    });
  }
}
