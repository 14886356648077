<nb-card status="primary" *nbIsGranted="['listing', 'personnel']">
    <nb-card-header>
        Prochaines Promotions
    </nb-card-header>

    <nb-card-body>

        <form [formGroup]="userForm">
            <div class="form-row">
                <div class="form-group col-md-1">
                </div>
                <div class="form-group col-md-5"></div>
                <div class="form-group col-md-1">
                    <label class="control-label" for="month" style="text-align: justify">Mois</label>
                </div>
                <div class="form-group col-md-3">
                    <select class="form-control" formControlName="month" id="month">
                        <option *ngFor="let item of months" [ngValue]="item.value ">
                            {{ item.viewValue }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-1">
                    <button (click)="submitForm()" [disabled]="!userForm.valid"
                            class="btn btn-success btn-block form-control"><i class="fas fa-search"></i> &nbsp; Filtrer
                    </button>
                </div>
                <div class="form-group col-md-1"><label *ngIf="result.length>0" class="btn btn-primary"
                                                        style="cursor: pointer;" role="button" btnRadio="Left"
                                                        (click)="download()" title="Télécharger cette liste">
                    Exporter</label></div>
            </div>
        </form>
        <div class="form-row">
            <div class="form-group col-md-12">
                <table class="table table-striped table-bordered display responsive" width="100%">
                    <thead>
                    <tr>
                        <th>Nom complet</th>
                        <th>Etablissement</th>
                        <th>Fonction</th>
                        <th>Grille actuelle</th>
                        <th>Grille echelon</th>
                        <th>Grille indice</th>
                        <th>Date Prochaine Promotion</th>
                        <th>Prochaine Promotion</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let employee of result">
                        <td>{{ employee['Genre'] }} {{ employee['Nom usuel'] }} {{ employee['Prénom'] }}</td>
                        <td>{{ employee['Etablissement'] }}</td>
                        <td>{{ employee['Fonction'] }}</td>
                        <td>{{ employee['Grille'] }}</td>
                        <td>{{ employee['Echelon'] }}</td>
                        <td>{{ employee['Indice'] }}</td>
                        <td>{{ employee['Date Prochaine Promotion'] }}</td>
                        <td>{{ employee['Prochaine Promotion'] }}</td>
                        <td>
                            <div class="form-inline">
                                <div class="btn-group" btnRadioGroup>
                                    <label *nbIsGranted="['view', 'personnel']" class="btn btn-primary"
                                           style="cursor: pointer" role="button" btnRadio="Left"
                                           (click)="redirect(employee['Identifiant personnel'], 'fiche')"
                                           title="Fiche personnel"><i class="fas fa-address-card"></i></label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </nb-card-body>
</nb-card>

