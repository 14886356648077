import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {CountryModel} from '../model/country.model';
import {environment} from '../../../environments/environment';
import {ApplicationService} from './application.service';
import {FormGroup} from '@angular/forms';


@Injectable({
  providedIn: 'root',
})
export class CountryService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'countries', 'country');
  }

  getAll(): Observable<CountryModel[]> {
    return this.httpClient.get(environment.serverUrl + '/countries' ,
      {params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'nomFrFr,asc')})
      .pipe(
        map(response => {
         // @ts-ignore
          return response._embedded.countries;
        }),
      catchError(this.handleError('getAll', [])),
    );
  }

  search( filter: string = ''): Observable<CountryModel[]> {
    return this.httpClient.get(environment.serverUrl + '/countries/search/byName', {
      params : new HttpParams()
        .set('filter', filter),
    }).pipe(
      map(response => {
        // @ts-ignore
        return  response._embedded.countries;
      }),
      catchError(this.handleError('search', [])),
    );
  }
}
