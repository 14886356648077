import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ConjointModel} from '../model/conjoint.model';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {FormArray, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ConjointService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'conjoints', '');
  }
  getByPersonId(id: number): Observable<ConjointModel> {
    const url = environment.serverUrl + `/personnes/${id}/conjoints`;
    return this.httpClient.get<ConjointModel>(url).pipe(
      tap(_ => console.log(`fetched personne conjoint id=${id}`)),
      catchError(this.handleError<ConjointModel>(`get ConjointModel id=${id}`)),
    );
  }

  addToPerson(personneid: number, group: FormGroup): void {
    const formArray = group.get('conjoints') as FormArray;
    const conjoints = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const conjoint = {
          id: 0,
          nomDuConjoint: '',
          nom: '',
          prenom: '',
          action: '',
          personne: '',
          profession: '',
          professionMatricule: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'nomDuConjoint':
              conjoint.nomDuConjoint = control.controls['nomDuConjoint'].value;
              break;
            case 'nom':
              conjoint.nom = control.controls['nom'].value;
              break;
            case 'prenom':
              conjoint.prenom = control.controls['prenom'].value;
              break;
            case 'id':
              conjoint.personne = environment.serverUrl + `/personnes/${personneid}`;
              if ( Number(control.controls['id'].value) > 0) {
                conjoint.id = Number(control.controls['id'].value);
                conjoint.action = 'PATCH';
              } else {
                conjoint.action = 'POST';
              }
              break;
            case 'profession':
              if (control.controls['profession'].value !== undefined
                && Number(control.controls['profession'].value) > 0 ) {
                conjoint.profession =
                  environment.serverUrl + `/professionConjoints/${control.controls['profession'].value}`;
              }
              break;
            case 'professionMatricule':
              if (control.controls['professionMatricule'].value !== undefined
                && control.controls['professionMatricule'].value !== null
                && control.controls['professionMatricule'].value.id !== undefined
                && Number(control.controls['professionMatricule'].value.id) > 0 ) {
                conjoint.professionMatricule =
                  environment.serverUrl + `/personnes/${control.controls['professionMatricule'].value.id}`;
              }
              break;
          }
        });
        if (conjoint.action === 'POST') {
          delete conjoint.id;
          this.add(conjoint).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        } else if (conjoint.action === 'PATCH') {
          this.update(conjoint.id, conjoint).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        }
        conjoints.push(conjoint);
      }
    }
  }
}
