import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {CorpsModel} from '../model/corps.model';

@Injectable({
  providedIn: 'root',
})
export class CorpsService extends ApplicationService {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeCorps', 'typeCorps');
  }

    getAll(): Observable<CorpsModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeCorps' , {
      params: new HttpParams()
        .set('size' , this.size.toString())
        .set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.typeCorps;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<CorpsModel> {
    return this.httpClient.get<CorpsModel>(environment.serverUrl + `/contrats/${id}/typeCorp`)
      .pipe(
        catchError(this.handleError<CorpsModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<CorpsModel> {
    return this.httpClient.get<CorpsModel>(url).pipe(
      catchError(this.handleError<CorpsModel>(`getOne`)),
    );
  }
}
