import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import {SearchQueryService} from '../../../lyautey/services/search-query.service';
import * as moment from 'moment';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {SearchQueryModel} from '../../../lyautey/model/searchQuery.model';
import {KeycloakService} from 'keycloak-angular';
import {AppAuthGuard} from '../../../lyautey/services/app-auth-guard.service';
import {NbDialogService} from '@nebular/theme';
import {PreviewResultComponent} from '../preview-result/preview-result.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {interval, Subscription} from 'rxjs';
import {switchMap, takeWhile} from 'rxjs/operators';

@Component({
    selector: 'ngx-list-recherche',
    templateUrl: './list-recherche.component.html',
    styleUrls: ['./list-recherche.component.scss'],
})
export class ListRechercheComponent implements OnInit {
    dataTable: any;
    dataTableSample: any;
    dataTableSettings: any = {fixedColumns: true};
    columns = [];
    mesListes = [];
    resultCount: number = 0;
    settings = {};
    blob: Blob;
    currentUser: string;
    private currentEtablissementId: any = -1;
    private showShared: boolean = true;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private router: Router,
                private chRef: ChangeDetectorRef,
                private personnelService: PersonnelService,
                public keycloakService: KeycloakService,
                public appAuthGuard: AppAuthGuard,
                private dialogService: NbDialogService,
                private service: SearchQueryService) {
    }

    ngOnInit() {
        this.keycloakService
            .isLoggedIn()
            .then(loggedIn => {
                if (loggedIn) {
                    this.keycloakService.loadUserProfile()
                        .then(profile => {
                            this.currentUser = profile.email;
                            if (this.appAuthGuard.isDirector()) {
                                // @ts-ignore
                                this.currentEtablissementId = profile.attributes.etablissement;
                                if (this.currentEtablissementId !== undefined && Number(this.currentEtablissementId) > 0) {
                                    this.showShared = true;
                                } else {
                                    this.showShared = false;
                                }
                            }
                            this.service.listingAdvanced({
                                'email': this.currentUser,
                                'etablissementId': this.currentEtablissementId,
                                'showShared': this.showShared,
                            }).subscribe((result: any) => {
                                    this.mesListes = result.data;
                                    this.chRef.detectChanges();
                                    // Now you can use jQuery DataTables :
                                    // @ts-ignore
                                    const table: any = $('table');
                                    this.dataTable = table.DataTable(this.dataTableSettings);
                                }, (err: any) => {
                                    Swal.fire({
                                        icon: 'error',
                                        title: `Veuillez réessayer plus tard!`,
                                    });
                                },
                            );

                        })
                        .catch(reason => {
                        });
                }
            }).catch(reason => console.log(reason));
    }

    redirect(id: any) {
        this.router.navigate(['/pages/recherche/edit', id]);
    }

    download(searchQueryId: any) {
        this.spinner.show();
        this.service.getOne(searchQueryId).subscribe(
            (model: SearchQueryModel) => {
                const queryObject = JSON.parse(model.query);
                this.personnelService.startReportGenerationFromSavedSearch(queryObject).subscribe(response => {
                    const reportId = response.body.reportId;
                    const checkInterval = 5000; // Check every 5000 milliseconds (5 seconds)

                    // Step 2: Poll for report status
                    const polling: Subscription = interval(checkInterval).pipe(
                        switchMap(() => this.personnelService.reportStatus(reportId)),
                        takeWhile(statusResponse => !statusResponse.body.isReady, true),
                    ).subscribe(statusResponse => {
                        if (statusResponse.body.isReady) {
                            polling.unsubscribe(); // Stop polling

                            // Step 3: Download the report
                            this.personnelService.downloadReport(reportId).subscribe(data => {
                                this.spinner.hide(); // Hide spinner when the download is ready

                                const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                                const downloadURL = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = downloadURL;
                                const now = moment().locale('fr').format('YYYY-MM-DD_HH-mm-ss');
                                link.download = `${model.description.toUpperCase()}-${reportId}.xlsx`;
                                link.click();
                                window.URL.revokeObjectURL(downloadURL); // Clean up
                            }, error => {
                                this.spinner.hide(); // Ensure spinner is hidden on error
                                console.error('Download failed', error);
                            });
                        }
                    }, error => {
                        this.spinner.hide(); // Ensure spinner is hidden on error
                        console.error('Error checking report status', error);
                    });
                }, error => {
                    this.spinner.hide(); // Ensure spinner is hidden on error
                    console.error('Error starting report generation', error);
                });
            });
    }

    beforeDelete(id, title, text) {
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: 'Annuler',
        }).then((result) => {
            if (result.value) {
                this.service.delete(id).subscribe((model: any) => {
                        Swal.fire(
                            'Suppression de votre liste',
                            'Votre liste vient d\'être supprimée',
                            'success',
                        ).then(() => {
                            $('table').DataTable().clear().destroy();
                            this.ngOnInit();
                        });
                    }, (err: any) => {
                    },
                );
            }
        });
    }

    preview(searchQueryId: any) {
        this.service.getOne(searchQueryId).subscribe(
            (model: SearchQueryModel) => {
                const queryObject = JSON.parse(model.query);
                this.personnelService.advancedSearch(null, true, false, queryObject)
                    .subscribe((result: any) => {
                            this.dialogService.open(PreviewResultComponent, {
                                closeOnEsc: true,
                                autoFocus: true,
                                hasScroll: true,
                                hasBackdrop: true,
                                context: {
                                    searchQuery: model,
                                    data: result.data,
                                    columns: result.columns,
                                    count: result.count,
                                },
                            });
                        }, (err: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: `Veuillez reessayer plus tard!`,
                            });
                        },
                    );
            });
    }
}
