import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {PersonneModel} from '../model/personnel.model';
import {environment} from '../../../environments/environment';
import {SituationFamilialeModel} from '../model/situation-familiale.model';
import {CompteBancaireModel} from '../model/compte-bancaire.model';
import {AdresseModel} from '../model/adresse.model';
import {GenreModel} from '../model/genre.model';
import {CountryModel} from '../model/country.model';
import {DiplomeModel} from '../model/diplome.model';
import {FormGroup} from '@angular/forms';
import {TypeNiveauModel} from '../model/typeNiveau.model';
import {ContratModel} from '../model/contrat.model';
import {ProfessionConjointModel} from '../model/professionConjoint.model';
import {ApplicationService} from './application.service';
import {DbDate} from '../shared/dbDate';
import {FormationModel} from '../model/formation.model';
import {DocumentModel} from '../model/document.model';
import {KeycloakService} from 'keycloak-angular';


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

const httpBlobOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

class PersonnelCriterion {
    constructor(public criterion: string,
                public value: string,
                public minValue: string,
                public maxValue: string,
                public values: number[]) {
    }
}

@Injectable({
    providedIn: 'root',
})

export class PersonnelService extends ApplicationService {
    result: any = {
        'data': null,
        'page': null,
    };
    personData: any = {};
    searchData: any = {};
    size: number = 1000;

    constructor(httpClient: HttpClient,
                protected keycloakService: KeycloakService,
                private dbDate: DbDate) {
        super(httpClient, 'personnes', 'personne');
    }

    getAll(): Observable<PersonneModel> {
        const url = environment.serverUrl + `/personnes`;
        return this.httpClient.get<PersonneModel>(url).pipe(
            tap(_ => console.log(`fetched all personnes`)),
            catchError(this.handleError<PersonneModel>(`getAll`)),
        );
    }

    getPersonnels(matricule: string,
                  filter = '',
                  sortOrder = 'asc',
                  pageNumber = 0,
                  pageSize = 3): Observable<PersonneModel[]> {
        return this.httpClient.get(environment.serverUrl + '/personnes/search/advancedFilters', {
            params: new HttpParams()
                .set('matricule', matricule.toString())
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('page', pageNumber.toString())
                .set('size', pageSize.toString())
        })
            .pipe(
                map(response => {
                    // @ts-ignore
                    this.result = {'data': response._embedded.personnes, 'page': response.page};
                    return this.result;
                }),
                catchError(this.handleError('getPersonnels', [])),
            );
    }

    search(filter: string = ''): Observable<PersonneModel[]> {
        return this.httpClient.get(environment.serverUrl + '/personnes/search/searchList', {
            params: new HttpParams()
                .set('filter', filter),
        }).pipe(
            map(response => {
                // @ts-ignore
                return response._embedded.personnes;
            }),
            catchError(this.handleError('search', [])),
        );
    }

    getPersonnes(): Observable<PersonneModel[]> {
        return this.httpClient.get(environment.serverUrl + '/personnes?projection=viewPersonne').pipe(
            map(response => {
                // @ts-ignore
                return response._embedded.personnes;
            }),
            catchError(this.handleError('getCountries', [])),
        );
    }

    getPersonnel(id: number): Observable<PersonneModel> {
        return this.httpClient.get<PersonneModel>(`${environment.serverUrl}/personnes/${id}`).pipe(
            tap(_ => console.log(`getPersonnel id=${id}`)),
            catchError(this.handleError<PersonneModel>(`getPersonnel PersonneModel id=${id}`)),
        );
    }

    getPhoto(personneId: number, typeDocumentId: number): Observable<DocumentModel> {
        return this.httpClient.get<DocumentModel>(environment.serverUrl + '/documents/search/photo', {
            params: new HttpParams().set('personneId', String(personneId)).set('typeDocumentId', String(typeDocumentId)),
        }).pipe(
            tap(_ => console.log(`fetched personne id`)),
            catchError(this.handleError<DocumentModel>(`getPhoto id=${personneId}`)),
        );
    }

    getSituationFamiliale(id: number): Observable<SituationFamilialeModel> {
        const url = environment.serverUrl + `/personnes/${id}/situationFamiliale`;
        return this.httpClient.get<SituationFamilialeModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<SituationFamilialeModel>(`get SituationFamilialetModel id=${id}`)),
        );
    }

    getGenre(id: number): Observable<GenreModel> {
        const url = environment.serverUrl + `/personnes/${id}/genre`;
        return this.httpClient.get<GenreModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<GenreModel>(`get GenreModel id=${id}`)),
        );
    }

    getTypeNiveau(id: number): Observable<TypeNiveauModel> {
        const url = environment.serverUrl + `/personnes/${id}/typeNiveau`;
        return this.httpClient.get<TypeNiveauModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<TypeNiveauModel>(`get TypeNiveauModel id=${id}`)),
        );
    }

    getNationalites(id: number): Observable<CountryModel> {
        const url = environment.serverUrl + `/personnes/${id}/nationalites`;
        return this.httpClient.get<CountryModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<CountryModel>(`get EnfantModel id=${id}`)),
        );
    }

    getFormations(id: number): Observable<FormationModel> {
        const url = environment.serverUrl + `/personnes/${id}/formations`;
        return this.httpClient.get<FormationModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<FormationModel>(`get EnfantModel id=${id}`)),
        );
    }

    getDiplomes(id: number): Observable<DiplomeModel> {
        const url = environment.serverUrl + `/personnes/${id}/diplomes`;
        return this.httpClient.get<DiplomeModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<DiplomeModel>(`get ConjointModel id=${id}`)),
        );
    }

    getCompteBancaires(id: number): Observable<CompteBancaireModel> {
        const url = environment.serverUrl + `/personnes/${id}/compteBancaires`;
        return this.httpClient.get<CompteBancaireModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<CompteBancaireModel>(`get CompteBancaireModel id=${id}`)),
        );
    }

    getAdresses(id: number): Observable<AdresseModel> {
        const url = environment.serverUrl + `/personnes/${id}/adresses`;
        return this.httpClient.get<AdresseModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<AdresseModel>(`get AdresseModel id=${id}`)),
        );
    }

    getContrats(id: number): Observable<ContratModel> {
        const url = environment.serverUrl + `/personnes/${id}/contrats`;
        return this.httpClient.get<ContratModel>(url).pipe(
            tap(_ => console.log(`fetched personne id=${id}`)),
            catchError(this.handleError<ContratModel>(`get AdresseModel id=${id}`)),
        );
    }

    setPersonData(group: FormGroup): void {
        this.personData = {
            'situationFamiliale': environment.serverUrl + `/situationFamiliales/${group.get('situationFamiliale').value}`,
            'genre': environment.serverUrl + `/genres/${group.get('genre').value}`,
            'matricule': group.get('matricule').value,
            'nom': group.get('nom').value,
            'nomUsuel': group.get('nomUsuel').value,
            'nomMage': group.get('nomMage').value,
            'prenom': group.get('prenom').value,
            'prenomDeux': group.get('prenomDeux').value,
            'prenomTrois': group.get('prenomTrois').value,
            'email': group.get('email').value,
            'emailDeux': group.get('emailDeux').value,
            'dateNaissance': this.dbDate.format(group.get('dateNaissance').value),
            'lieuNaissance': group.get('lieuNaissance').value,
            'numeroCarteNationale': group.get('numeroCarteNationale').value,
            'numeroCnss': group.get('numeroCnss').value,
            'numeroCfe': group.get('numeroCfe').value,
            'numeroCimr': group.get('numeroCimr').value,
            'cnssActivated': group.get('cnssActivated').value,
            'cfeActivated': group.get('cfeActivated').value,
            'cimrActivated': group.get('cimrActivated').value,
            'nomContactUrgence': group.get('nomContactUrgence').value,
            'prenomContactUrgence': group.get('prenomContactUrgence').value,
            'emailContactUrgence': group.get('emailContactUrgence').value,
            'mobileContactUrgence': group.get('mobileContactUrgence').value,
            'adresseContactUrgence': group.get('adresseContactUrgence').value,
            'codePostalContactUrgence': group.get('codePostalContactUrgence').value,
            'villeContactUrgence': group.get('villeContactUrgence').value,
            'mgenActivated': group.get('mgenActivated').value,
            'mgpapActivated': group.get('mgpapActivated').value,
            'cnopsActivated': group.get('cnopsActivated').value,
            'emailAefe': group.get('emailAefe').value,
            'numeroCnops': group.get('numeroCnops').value,
            'numeroMgen': group.get('numeroMgen').value,
            'numeroMgpap': group.get('numeroMgpap').value,
        };

        if (group.get('ancienLieuNaissance') != null
            && group.get('ancienLieuNaissance') !== undefined
            && group.get('ancienLieuNaissance').value !== null
            && group.get('ancienLieuNaissance').value !== undefined) {
            this.personData.ancienLieuNaissance = group.get('ancienLieuNaissance').value;
        }

        if (group.get('dateValiditeCarteNationale') != null
            && group.get('dateValiditeCarteNationale') !== undefined
            && group.get('dateValiditeCarteNationale').value !== null
            && group.get('dateValiditeCarteNationale').value !== undefined) {
            this.personData.dateValiditeCarteNationale = this.dbDate.format(group.get('dateValiditeCarteNationale').value);
        }

        // citizenship
        const citizenship = [];
        Object.keys(group.get('nationalite').value).forEach(key => {
            citizenship.push(environment.serverUrl + `/countries/${group.get('nationalite').value[key].id}`);
        });
        if (citizenship.length > 0) {
            this.personData.nationalites = citizenship;
        }

        if (group.get('paysContactUrgence').value !== undefined && Number(group.get('paysContactUrgence').value.id) > 0) {
            this.personData.paysContactUrgence =
                environment.serverUrl + `/countries/${group.get('paysContactUrgence').value.id}`;

        }

        if (group.get('paysContactUrgence').value !== undefined && Number(group.get('paysNaissance').value.id) > 0) {
            this.personData.paysNaissance = environment.serverUrl + `/countries/${group.get('paysNaissance').value.id}`;
        }

        if (group.get('typeNiveau').value !== undefined && Number(group.get('typeNiveau').value) > 0) {
            this.personData.typeNiveau = environment.serverUrl + `/typeNiveaus/${group.get('typeNiveau').value}`;
        }
    }

    getCountryByUrl(url: string): Observable<CountryModel> {
        return this.httpClient.get<CountryModel>(url).pipe(
            tap(_ => console.log(`fetched personne url= ${url}`)),
            catchError(this.handleError<CountryModel>(`get AdresseModel url=${url}`)),
        );
    }

    getPersoneByUrl(url: string): Observable<PersonneModel> {
        return this.httpClient.get<PersonneModel>(url).pipe(
            tap(_ => console.log(`fetched personne url= ${url}`)),
            catchError(this.handleError<PersonneModel>(`get getPersoneByUrl url=${url}`)),
        );
    }

    getProfessionByUrl(url: string): Observable<ProfessionConjointModel> {
        return this.httpClient.get<ProfessionConjointModel>(url).pipe(
            tap(_ => console.log(`fetched personne url= ${url}`)),
            catchError(this.handleError<ProfessionConjointModel>(`get getProfessionByUrl url=${url}`)),
        );
    }

    addPersonne(group: FormGroup): Observable<PersonneModel> {
        this.setPersonData(group);
        return this.httpClient.post<PersonneModel>(`${environment.serverUrl}/personnes`, this.personData, httpOptions).pipe(
            tap((personne: PersonneModel) => {
                console.log(`added personnel id=${personne.id}`);
            }),
            catchError(this.handleError<PersonneModel>(`addPersonne`)),
        );
    }

    updatePersonne(id: number, group: FormGroup): Observable<PersonneModel> {
        this.setPersonData(group);
        return this.httpClient.patch<PersonneModel>(`${environment.serverUrl}/personnes/${id}`,
            this.personData, httpOptions).pipe(
            tap((personne: PersonneModel) => {
                console.log(`updated personnel id=${id}`);
            }),
            catchError(this.handleError<any>('updatePersonnel')),
        );
    }

    deletePersonnel(id: number): Observable<PersonneModel> {
        return this.httpClient.delete<PersonneModel>(`${environment.serverUrl}/personnes/${id}`, httpOptions).pipe(
            tap(_ => console.log(`deleted personnel id=${id}`)),
            catchError(this.handleError<PersonneModel>('deletePersonnel')),
        );
    }

    getPersonnelView(emailDeux: string): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/single`,
            {'emailDeux': emailDeux}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getPersonnelAnciennete(personneId, etablissementId): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/anciennete`,
            {'personneId': personneId, 'etablissementId': etablissementId}, httpOptions).pipe(
            tap(_ => console.log(`getPersonnelAnciennete`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    getPersonnelDetails(agentId: number, etablissementId: number): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/get`,
            {'personneId': agentId, 'etablissementId': etablissementId}, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }


    advancedSearch(group: FormGroup, sample?: boolean, entity?: boolean, predifinedQuery?: any): any {
        if (predifinedQuery) {
            this.searchData = predifinedQuery;
            this.searchData.sample = sample;
        } else {
            this.setSearchData(group, sample, entity);
        }
        return this.httpClient.post(`${environment.serverBaseUrl}personne/advancedSearch`,
            this.searchData, httpOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    listingPersonnel(group: FormGroup, sample?: boolean, entity?: boolean): any {
        this.setSearchData(group, sample, entity);
        return this.httpClient.post(`${environment.serverBaseUrl}personne/listing`,
            this.searchData, httpOptions).pipe(
            tap(_ => console.log(`listing`)),
            catchError(this.handleError<any>('listing')),
        );
    }

    alertePromotion(request: {}): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/alerte-promotion`,
            request, httpOptions).pipe(
            tap(_ => console.log(`alertePromotion`)),
            catchError(this.handleError<any>('alertePromotion')),
        );
    }

    downloadAlertePromotion(request: {}): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/alerte-promotion-export`,
            request, httpBlobOptions).pipe(
            tap(_ => console.log(`donwloadAlertePromotion`)),
            catchError(this.handleError<any>('donwloadAlertePromotion')),
        );
    }

    emailingPersonnel(contratStatus, criteria, entity?: boolean): any {
        this.searchData.sample = false;
        this.searchData.criteria = [];
        this.searchData.personneFields = {
            'personneId': true,
            'nom': true,
            'nomUsuel': true,
            'prenom': true,
            'email': true,
            'emailDeux': true,
            'emailAefe': true,
            'genre': true
        };
        this.searchData.contratFields = {'etablissement': true};
        this.searchData.contactUrgenceFields = {};
        this.searchData.grilleFields = {};
        this.searchData.adresseMarocFields = {};
        this.searchData.adresseHorsMarocFields = {};
        if (entity) {
            this.searchData.contratStatus = contratStatus;
        } else {
            this.searchData.criteria = criteria;
        }
        return this.httpClient.post(`${environment.serverBaseUrl}personne/emailing`,
            this.searchData, httpOptions).pipe(
            tap(_ => console.log(`emailing`)),
            catchError(this.handleError<any>('emailing')),
        );
    }

    agentsEvaluation(group: FormGroup, sample?: boolean, entity?: boolean): any {
        this.setSearchData(group, sample, entity);
        return this.httpClient.post(`${environment.serverBaseUrl}personne/listing`,
            this.searchData, httpOptions).pipe(
            tap(_ => console.log(`listing`)),
            catchError(this.handleError<any>('listing')),
        );
    }

    downloadSearch(group: FormGroup, sample?: boolean): any {
        this.setSearchData(group, sample);
        return this.httpClient.post(`${environment.serverBaseUrl}personne/downloadExcelSearch`,
            this.searchData, httpBlobOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    downloadCarte(etablissements: number[]): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/downloadCarteProfessionnelle`,
            etablissements, httpBlobOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('downloadCarteProfessionnelle')),
        );
    }

    downloadEnfantResponsablesReport(): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/enfantResponsables`, {}, httpBlobOptions).pipe(
            tap(_ => console.log(`downloadEnfantResponsablesReport`)),
            catchError(this.handleError<any>('downloadEnfantResponsablesReport')),
        );
    }

    downloadSearchById(data: any): any {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/downloadExcelSearch`,
            data, httpBlobOptions).pipe(
            tap(_ => console.log(`advanced Search`)),
            catchError(this.handleError<any>('advancedSearch')),
        );
    }

    generateDocuments(group: FormGroup, sample?: boolean): any {
        this.setSearchData(group, sample);
        return this.httpClient.post(`${environment.serverBaseUrl}personne/downloadCertificat`,
            this.searchData, httpBlobOptions).pipe(
            tap(_ => console.log(`generateDocuments`)),
            catchError(this.handleError<any>('generateDocuments')),
        );
    }

    downloadCertificat(id: number): any {
        return this.httpClient.get(`${environment.serverBaseUrl}personne/certificat/${id}`, httpBlobOptions).pipe(
            tap(_ => console.log(`downloadCertificat`)),
            catchError(this.handleError<any>('downloadCertificat')),
        );
    }

    downloadCertificatVacataire(id: number): any {
        return this.httpClient.get(`${environment.serverBaseUrl}personne/certificatVacataire/${id}`, httpBlobOptions).pipe(
            tap(_ => console.log(`downloadCertificatVacataire`)),
            catchError(this.handleError<any>('downloadCertificatVacataire')),
        );
    }

    setSearchData(group: FormGroup, sample: boolean, entity?: boolean): void {
        this.searchData.sample = sample;
        this.searchData.description = '';
        this.searchData.format = '';
        this.searchData.visibilite = '';
        this.searchData.criteria = [];
        this.searchData.personneFields = {};
        this.searchData.contactUrgenceFields = {};
        this.searchData.contratFields = {};
        this.searchData.grilleFields = {};
        this.searchData.adresseMarocFields = {};
        this.searchData.adresseHorsMarocFields = {};
        this.searchData.autreFields = {};
        if (entity) {
            this.searchData.contratStatus = group.get('contratStatus').value;
        } else {
            if (group.get('criteria').value !== undefined) {
                Object.keys(group.get('criteria').value).forEach(key => {
                    if (group.get('criteria').value[key].listValues === ''
                        || group.get('criteria').value[key].listValues === undefined) {
                        group.get('criteria').value[key].listValues = [];
                    }
                    if (group.get('criteria').value[key].criterion.toLowerCase().indexOf('date') !== -1) {
                        if (this.isNotEmpty(group.get('criteria').value[key].minValue)) {
                            group.get('criteria').value[key].minValue = this.dbDate.format(group.get('criteria').value[key].minValue);
                        }
                        if (this.isNotEmpty(group.get('criteria').value[key].maxValue)) {
                            group.get('criteria').value[key].maxValue = this.dbDate.format(group.get('criteria').value[key].maxValue);
                        }
                    }
                    this.searchData.criteria.push(new PersonnelCriterion(group.get('criteria').value[key].criterion,
                        group.get('criteria').value[key].value,
                        group.get('criteria').value[key].minValue,
                        group.get('criteria').value[key].maxValue,
                        group.get('criteria').value[key].listValues,
                    ));
                });
            }
            if (group.get('personneFields').value !== undefined) {
                this.searchData.personneFields = group.get('personneFields').value;
            }
            if (group.get('contactUrgenceFields').value !== undefined) {
                this.searchData.contactUrgenceFields = group.get('contactUrgenceFields').value;
            }
            if (group.get('contratFields').value !== undefined) {
                this.searchData.contratFields = group.get('contratFields').value;
            }
            if (group.get('grilleFields').value !== undefined) {
                this.searchData.grilleFields = group.get('grilleFields').value;
            }
            if (group.get('adresseMarocFields').value !== undefined) {
                this.searchData.adresseMarocFields = group.get('adresseMarocFields').value;
            }
            if (group.get('adresseHorsMarocFields').value !== undefined) {
                this.searchData.adresseHorsMarocFields = group.get('adresseHorsMarocFields').value;
            }
            if (group.get('autreFields').value !== undefined) {
                this.searchData.autreFields = group.get('autreFields').value;
            }
        }
    }
    startReportGenerationFromSavedSearch(data: any): Observable<any> {
        return this.httpClient.post(`${environment.serverBaseUrl}personne/startReportGeneration`,
            data,
            {observe: 'response'});
    }
    startReportGeneration(group: FormGroup, sample?: boolean): Observable<any> {
        this.setSearchData(group, sample);
        return this.httpClient.post(`${environment.serverBaseUrl}personne/startReportGeneration`,
            this.searchData,
            {observe: 'response'});
    }
    reportStatus(reportId: string): Observable<any> {
        return this.httpClient.get(`${environment.serverBaseUrl}personne/reportStatus/${reportId}`,
            {observe: 'response'});
    }
    downloadReport(reportId: string): Observable<Blob> {
        return this.httpClient.get(`${environment.serverBaseUrl}personne/downloadReport/${reportId}`,
            { responseType: 'blob' }); // Ensure responseType is set to 'blob' for file downloads
    }


}
