import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {BaseModel} from '../model/base.model';


export class ApplicationService {

  size: number = 1000;
  baseUrl: string;
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };

  constructor(public httpClient: HttpClient, public entity: string, public controller: string) {
    this.baseUrl = environment.serverUrl + '/' + entity + '/';
  }


  findAll( ): Observable<BaseModel> {
    return this.httpClient.get<BaseModel>(this.baseUrl).pipe(
      tap(_ => console.log(`fetched all model`)),
      catchError(this.handleError<BaseModel>(`getAll`)),
    );
  }

  getOne(id: number): Observable<BaseModel> {
    return this.httpClient.get<BaseModel>(this.baseUrl + id).pipe(
      tap(_ => console.log(`getOne id=${id}`)),
      catchError(this.handleError<BaseModel>(`getOne id=${id}`)),
    );
  }

  add(data: any): Observable<BaseModel> {
    return this.httpClient.post<BaseModel>(this.baseUrl, data, this.httpOptions).pipe(
      tap((model: BaseModel) => {
      }),
      catchError(this.handleError<BaseModel>(`add`)),
    );
  }

  update(id: number, data: any): Observable<BaseModel> {
    return this.httpClient.patch<BaseModel>(this.baseUrl + id, data, this.httpOptions).pipe(
      tap((model: BaseModel) => {
      }),
      catchError(this.handleError<BaseModel>(`update`)),
    );
  }



  delete(id: number): Observable<BaseModel> {
    return this.httpClient.delete<BaseModel>(this.baseUrl + id, this.httpOptions).pipe(
      tap(_ => console.log(`deleted id=${id}`)),
      catchError(this.handleError<BaseModel>('delete')),
    );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      if (error.status === 404) {
        console.error('Resource not found:', error.message);
      } else if (error.status === 500) {
        console.error('Internal server error:', error.message);
      }
      // ... handle other status codes
      else {
        console.error(`${operation} failed:`, error.message);
      }

      // Rethrow the error for the subscriber to handle
      return throwError(error);
    };
  }

  getByUrl(url: string ): Observable<BaseModel> {
    return this.httpClient.get<BaseModel>(url).pipe(
      tap((value) => console.log(value)),
      catchError(this.handleError<BaseModel>(`getOne`)),
    );
  }


  listing(): any {
    return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listing`,
      {}, this.httpOptions).pipe(
      tap(_ => console.log(`listing`)),
      catchError(this.handleError<any>('listing')),
    );
  }
  isNotEmpty(str: string): boolean {
    if (str !== undefined && str !== null && str && str !== '' && str.length > 0 ) {
      return true;
    }
    return false;
  }
}
