import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {DbDate} from '../shared/dbDate';
import {VisiteMedicaleModel} from '../model/VisiteMedicale.model';


@Injectable({
  providedIn: 'root'
})
export class VisiteMedicaleService extends ApplicationService {

  constructor(httpClient: HttpClient, private dbDate: DbDate) {
    super(httpClient, 'visiteMedicales', '');
  }

  getByPersonId(id: number): Observable<VisiteMedicaleModel> {
    const url = environment.serverUrl + `/personnes/${id}/visiteMedicales`;
    return this.httpClient.get<VisiteMedicaleModel>(url).pipe(
        tap(_ => console.log(`fetched personne visiteMedicales id=${id}`)),
        catchError(this.handleError<VisiteMedicaleModel>(`get VisiteMedicaleModel id=${id}`)),
    );
  }

  addToPerson(personneId: number, group: FormGroup): Observable<VisiteMedicaleModel> {
    return new Observable((observer) => {
      const visiteMedicale = {
        id: 0,
        personne: '',
        conclusion: '',
        dateVisite: '',
        action: '',
      };
      visiteMedicale.action = 'POST';
      Object.keys(group.controls).forEach(key => {
        const control = group.controls[key];
        visiteMedicale.personne = environment.serverUrl + `/personnes/${personneId}`;
        switch (key) {
          case 'id':
            if (Number(control.value) > 0) {
              visiteMedicale.id = Number(control.value);
              visiteMedicale.action = 'PATCH';
            } else {
              visiteMedicale.action = 'POST';
            }
            break;
          case 'conclusion':
            visiteMedicale.conclusion = control.value;
            break;
          case 'dateVisite':
            if (control.value !== undefined &&
                control.value !== null
            ) {
              visiteMedicale.dateVisite = this.dbDate.format(control.value);
            }
            break;
        }
      });
      if (visiteMedicale.action === 'POST') {
        delete visiteMedicale['id'];
        this.add(visiteMedicale).subscribe((model: any) => {
              observer.next(model);
              observer.complete();
            }, (err: any) => {
              observer.error(err);
            },
        );
      } else if (visiteMedicale.action === 'PATCH') {
        this.update(visiteMedicale['id'], visiteMedicale)
            .subscribe((model: any) => {
                  observer.next(model);
                  observer.complete();
                }, (err: any) => {
                  observer.error(err);
                },
            );
      }
    });
  }


  updateDocument(id: number, documentId: number): Observable<VisiteMedicaleModel> {
    return new Observable((observer) => {
      const visiteMedicale = {
        id: id,
        document: environment.serverUrl + `/documents/${documentId}`,
      };

        this.update(id, visiteMedicale)
            .subscribe((model: any) => {
                  observer.next(model);
                  observer.complete();
                }, (err: any) => {
                  observer.error(err);
                },
            );
    });
  }





}
