import {Component, Input, OnInit} from '@angular/core';
import {DataTableOptionsDirective} from '../../../lyautey/utils/dataTable.options';

export class ListPageOptions {
  title: string;
  entity: string;
  columns: any[];
  buttons: string[];
  editLink: string;
  customList: string;
}
@Component({
  selector: 'ngx-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent extends DataTableOptionsDirective implements OnInit {
  dtOptions: any = {};

  @Input() listPageOptions: ListPageOptions;


  ngOnInit(): void {
    this.setDataTableOptions(
      this.listPageOptions.entity,
      this.listPageOptions.columns,
      this.listPageOptions.buttons,
      this.listPageOptions.editLink,
      this.listPageOptions.customList);
  }


}
