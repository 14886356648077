import {AbstractControl} from '@angular/forms';

export class EmailDomainValidators {
  static emailDomain(domainName: string[]) {
    return (control: AbstractControl): { [key: string]: any} | null => {
      const email: string = control.value;
      if (email === null || email === undefined) return null;
      const domain = email.substring(email.lastIndexOf('@') + 1);
      if (!domainName.includes(domain.toLocaleLowerCase())) {
        return {'emailDomain' : true};
      } else {
        return null;
      }
    };
  }
}

/*
export class EmailDomainValidators {
  static emailDomain(domainName: string[]) {
    return (control: AbstractControl): { [key: string]: any} | null => {
      const email: string = control.value;
      if (email === null) return null;
      const domain = email.substring(email.lastIndexOf('@') + 1);
      if (email === '' || domainName.includes(domain.toLocaleLowerCase())) {
        return null;
      } else {
        return {'emailDomain' : true};
      }
    };
  }
}
 */