import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {SearchQueryModel} from '../model/searchQuery.model';

@Injectable({
  providedIn: 'root',
})
export class SearchQueryService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'searchQueries', 'searchQuery');
  }

  getAll(): Observable<SearchQueryModel[]> {
    return this.httpClient.get(environment.serverUrl + '/searchQueries' , {
      params: new HttpParams().set('size' , this.size.toString())})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.typeRits;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<SearchQueryModel> {
    return this.httpClient.get<SearchQueryModel>(environment.serverUrl + `/searchQueries/${id}`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<SearchQueryModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<SearchQueryModel> {
    return this.httpClient.get<SearchQueryModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<SearchQueryModel>(`getOne`)),
    );
  }

  downloadFile(filename: string = null): void {
    const token = 'my JWT';
    const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
    this.httpClient.get(environment.serverBaseUrl + 'searchQuery/get-file',
      {headers, responseType: 'blob' as 'json'}).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
    );
  }
  listingAdvanced(data: any): any {
    return this.httpClient.post(`${environment.serverBaseUrl}${this.controller}/listing`,
      data, this.httpOptions).pipe(
      tap(_ => console.log(`listing`)),
      catchError(this.handleError<any>('listing')),
    );
  }
}

