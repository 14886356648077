<nb-card *nbIsGranted="['download', 'cards']">
  <nb-card-header>
    Cartes Professionnelles
  </nb-card-header>

  <nb-card-body>
    <form [formGroup]='editForm' (ngSubmit)="onFormSubmit()" autocomplete="off">
      <div class="form-row">
        <div class="form-group col-md-2" >
          <label class="control-label" for="etablissement">Etablissement</label>
        </div>
        <div class="form-group col-md-4" >

          <ng-select [items]="etablissements | async"
                     bindLabel="description"
                     [multiple]="true"
                     [closeOnSelect]="false"
                     [searchable]="true"
                     bindValue="id"
                     appendTo="body"
                     formControlName="etablissement" id="etablissement">
          </ng-select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <button nbButton status="primary" type="submit" [disabled]="editForm.invalid">Télécharger le dossier</button>
        </div>
      </div>

    </form>
  </nb-card-body>
</nb-card>

