import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RechercheComponent} from './recherche.component';
import {ListRechercheComponent} from './list-recherche/list-recherche.component';
import {EditRechercheComponent} from './edit-recherche/edit-recherche.component';
import {CartePersonnelleComponent} from './carte-personnelle/carte-personnelle.component';


const routes: Routes = [{
  path: '',
  component: RechercheComponent,
  children: [
    {
      path: 'mes-listes',
      component: ListRechercheComponent,
    },
    {
      path: 'cartes',
      component: CartePersonnelleComponent,
    },
    {
      path: 'edit',
      component: EditRechercheComponent,
    },
    {
      path: 'edit/:id',
      component: EditRechercheComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RechercheRoutingModule { }
