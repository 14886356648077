import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {FormArray, FormGroup} from '@angular/forms';
import {CompteBancaireModel} from '../model/compte-bancaire.model';
import {DbDate} from '../shared/dbDate';

@Injectable({
  providedIn: 'root',
})
export class CompteBancaireService extends ApplicationService {

  constructor(httpClient: HttpClient, private dbDate: DbDate) {
    super(httpClient, 'compteBancaires', '');
  }



  getByPersonId(id: number): Observable<CompteBancaireModel> {
    return this.httpClient.get<CompteBancaireModel>( environment.serverUrl + `/personnes/${id}/compteBancaires`).pipe(
      tap(_ => console.log(`fetched personne adresse id=${id}`)),
      catchError(this.handleError<CompteBancaireModel>(`get CompteBancaireModel id=${id}`)),
    );
  }

  addToPerson(personneId: number, group: FormGroup): void {
    const formArray = group.get('compteBancaires') as FormArray;
    const compteBancaires = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const compteBancaire = {
          id: 0,
          numeroCompte: '',
          libelleBanque: '',
          adresseBanque: '',
          dateRib: '',
          country: '',
          personne: '',
          action: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'id':
              compteBancaire.personne = environment.serverUrl + `/personnes/${personneId}`;
              if (Number(control.controls['id'].value) > 0) {
                compteBancaire.id = Number(control.controls['id'].value);
                compteBancaire.action = 'PATCH';
              } else {
                compteBancaire.action = 'POST';
              }
              break;
            case 'numeroCompte':
              compteBancaire.numeroCompte = control.controls['numeroCompte'].value;
              break;
            case 'adresseBanque':
              compteBancaire.adresseBanque = control.controls['adresseBanque'].value;
              break;
            case 'libelleBanque':
              compteBancaire.libelleBanque = control.controls['libelleBanque'].value;
              break;
            case 'dateRib':
              if (this.isNotEmpty(control.controls['dateRib'].value)) {
                compteBancaire.dateRib = this.dbDate.format(control.controls['dateRib'].value);
              }
              break;
            case 'country':
              if (control.controls['country'].value !== undefined
                && control.controls['country'].value !== null
                && control.controls['country'].value.id !== undefined
                && Number(control.controls['country'].value.id) > 0) {
                compteBancaire.country = environment.serverUrl + `/countries/${control.controls['country'].value.id}`;
              }
              break;
          }
        });
        if (compteBancaire.action === 'POST') {
          delete compteBancaire.id;
          this.add(compteBancaire).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        } else if (compteBancaire.action === 'PATCH') {
          this.update(compteBancaire.id, compteBancaire).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        }
        compteBancaires.push(compteBancaire);
      }
    }
  }
}
