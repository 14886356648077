import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {DocumentModel} from '../../../../../lyautey/model/document.model';

@Component({
  selector: 'ngx-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
})
export class ViewImageComponent {

  @Input() document: DocumentModel;
  @Input() fileSrc: any;

  constructor(protected ref: NbDialogRef<ViewImageComponent>) {}

  dismiss() {
    this.ref.close();
  }

}
