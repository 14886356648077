import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {TypeContratModel} from '../model/typeContrat.model';

@Injectable({
  providedIn: 'root',
})
export class TypeContratService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeContrats', 'typeContrat');
  }

  getAll(): Observable<TypeContratModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeContrats' , {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.typeContrats;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<TypeContratModel> {
    return this.httpClient.get<TypeContratModel>(environment.serverUrl + `/contrats/${id}/typeContrat`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<TypeContratModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<TypeContratModel> {
    return this.httpClient.get<TypeContratModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<TypeContratModel>(`getOne`)),
    );
  }
}
