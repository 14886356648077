import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {LayoutService} from '../../../@core/utils';
import {Subject} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentTheme = 'corporate';
  userMenu = [ { title: 'Change Password' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              public keycloakService: KeycloakService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.keycloakService
      .isLoggedIn()
      .then( loggedIn => {
        if ( loggedIn ) {
          this.keycloakService.loadUserProfile()
            .then(profile => {
              this.user = {name : profile.lastName + ' ' + profile.firstName};
            })
            .catch( reason => {
            });
        }
      })
      .catch( reason => console.log ( reason ));
    this.menuService.onItemClick().subscribe(( event ) => {
      this.onItemSelection(event.item.title);
    });
  }
  onItemSelection( title ) {
    if ( title === 'Log out' ) {
      this.keycloakService.logout();
    } else if ( title === 'Change Password' ) {
      this.keycloakService.getKeycloakInstance().accountManagement();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onLogin() {
    this.keycloakService.login();
  }

  onLogout() {
    this.keycloakService.logout();
  }
}
