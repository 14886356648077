import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AvancementModel} from '../model/Avancement.model';
import {BaseModel} from '../model/base.model';
import {ContratModel} from "../model/contrat.model";

@Injectable({
  providedIn: 'root',
})
export class AvancementService extends ApplicationService {
  searchData: any = {};

  constructor(httpClient: HttpClient) {
    super(httpClient, 'avancements', 'avancement');
  }

  getAll(): Observable<AvancementModel[]> {
    return this.httpClient.get(environment.serverUrl + '/avancements' , {
      params: new HttpParams().set('size' , this.size.toString())})
        .pipe(
            map(response => {
              // @ts-ignore
              return response._embedded.postes;
            }),
            catchError(this.handleError('getAll', [])),
        );
  }
  addToContract(avancement: any): Observable<BaseModel> {
    console.log('Avancement', avancement);
    // Decide the action and return the observable accordingly
    return (avancement.action === 'POST' ?
            this.add(avancement) :
            this.update(avancement['id'], avancement)
    ).pipe(
        catchError((err) => {
          // Handle errors here, possibly logging them or transforming them
          return throwError(err);
        }),
    );
  }
  findById(id: number ): Observable<AvancementModel> {
    return this.httpClient.get<AvancementModel>(environment.serverUrl + `/contrats/${id}/avancement`)
        .pipe(
            // tap((value) => console.log(value)),
            catchError(this.handleError<AvancementModel>(`findById`)),
        );
  }

  getContrat(id: number ): Observable<ContratModel> {
    return this.httpClient.get<ContratModel>(environment.serverUrl + `/avancements/${id}/contrat`)
        .pipe(
            // tap((value) => console.log(value)),
            catchError(this.handleError<ContratModel>(`getContrat`)),
        );
  }

  getByUrl(url: string ): Observable<AvancementModel> {
    return this.httpClient.get<AvancementModel>(url).pipe(
        // tap((value) => console.log(value)),
        catchError(this.handleError<AvancementModel>(`getOne`)),
    );
  }

}
