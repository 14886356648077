import {Component, OnInit} from '@angular/core';
import {UploadFileService} from '../../../../lyautey/services/upload-file.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TypeDocumentsService} from '../../../../lyautey/services/type-documents.service';
import {TypeDocumentModel} from '../../../../lyautey/model/typeDocument.model';
import {DocumentService} from '../../../../lyautey/services/document.service';
import Swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {NbDialogService} from '@nebular/theme';
import {DocumentModel} from '../../../../lyautey/model/document.model';
import {ViewImageComponent} from './view-image/view-image.component';
import {ViewPdfComponent} from './view-pdf/view-pdf.component';
import {DomSanitizer} from '@angular/platform-browser';
import {EditDocumentComponent} from './edit-document/edit-document.component';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {PersonneModel} from '../../../../lyautey/model/personnel.model';
import {PersonnelService} from '../../../../lyautey/services/personnel.service';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {map} from "rxjs/operators";


@Component({
  selector: 'ngx-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {

  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  fileInfo = {};
  editForm: FormGroup;
  globalObject: GlobalObject = new GlobalObject();
  years = [];
  documents: any = [];
  typesDocuments: any = [];
  fullName = '';
  private downloadUrl: string;
  viewType: string = 'list';
  private blob: Blob;
  documentGroups = {
    'Documents recrutement': ['Diplôme/Certification', 'Attestation de travail', 'Curriculum vitae', 'Lettre de motivation', 'Notice de candidature'],
    'Documents identité': ['Carte d’identité/Carte de résident', 'Casier judiciaire/Fiche anthropométrique', 'Photo personnel', 'Relevé d\'Identité Bancaire', 'Livret de famille', 'Bulletin de renseignements', 'Passeport'],
    'Contrats': ['Contrat', 'Décision/Avenant', 'CTE', 'Arrêté'],
    'Avancements /promotions': ['Evaluation / Notation', 'Avancement/Promotion'],
    'Rapports d’incidents': ['Blâme', 'Rapport incident'],
    'Absences': ['Absence'],
    'Divers': ['Divers'],
};
  groupedDocuments: { [groupName: string]: DocumentModel[] } = {};
  advancedDocuments: any = [];
  groupedTypeDocuments: any;


  constructor(private uploadService: UploadFileService,
              private documentsService: DocumentService,
              private dialogService: NbDialogService,
              private personnelService: PersonnelService,
              private sanitizer: DomSanitizer,
              private typeDocumentsService: TypeDocumentsService,
              private route: ActivatedRoute,
              private fb: FormBuilder) { }

  ngOnInit() {
    // Initialize groupedDocuments with empty arrays for each group
    // tslint:disable-next-line:forin
    for (const group in this.documentGroups) {
      this.groupedDocuments[group] = [];
    }
    this.generateArrayOfYears();
    this.downloadUrl = environment.serverBaseUrl + `document/download/`;
    this.editForm = this.fb.group({
      id : [''],
      annee : ['', Validators.required],
      description : ['', Validators.required],
      typeDocument : ['', Validators.required],
      file : [''],
    });
    this.route.parent.paramMap.subscribe(params => {
      const id: number = Number(params.get('id'));
      if (id) {
        this.personnelService.getPersonnel(id)
          .subscribe(
            (personneModel: PersonneModel) => {
              this.fullName =  personneModel.nomUsuel.toUpperCase() + ' ' +
                personneModel.prenom.charAt(0).toUpperCase() + personneModel.prenom.toLowerCase().slice(1);
            },
            (err: any) => {},
          );
        this.documentsService.getByPersonId(id).subscribe((data: any) => {
          this.documents = data;
          this.documents.forEach((document, index) => {
            this.documentsService.getByUrl(document._links.typeDocument.href).subscribe(value => {
              if (value !== undefined) {
                this.documents[index]['typeDocument'] = value['description'];
                // Determine the group for each document
                for (const groupName in this.documentGroups) {
                  if (this.documentGroups[groupName].includes(this.documents[index]['typeDocument'])) {
                    document.group = groupName;
                    this.groupedDocuments[groupName].push(document);
                    this.advancedDocuments.push(document);
                    break;
                  }
                }
              }
            });
          });
          this.advancedDocuments.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime(); // This will sort in descending order
          });
          console.log('groupedDocuments', this.groupedDocuments);
        });
      }
    });
    this.typeDocumentsService.getAll().pipe(
        map(data => {
          // Filter data based on a visible field (for example, 'isVisible')
          return data.filter(item => item.visible === true); // Adjust the condition as per your field
        }),
    ).subscribe(filteredData => {
      console.log('Filtered data:', filteredData);
      this.groupedTypeDocuments = this.groupByCategory(filteredData);
    });
  }
  private groupByCategory(documents: TypeDocumentModel[]) {
    const groups = {};
    documents.forEach(doc => {
      if (!groups[doc.categorie]) {
        groups[doc.categorie] = [];
      }
      groups[doc.categorie].push(doc);
    });
    return groups;
  }
  upload() {
    this.progress = 0;
    this.currentFile = this.selectedFiles.item(0);
    this.uploadService.upload(this.currentFile).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileInfo = event.body;
          this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id) {
              this.documentsService.addToPerson(id, this.fileInfo, this.editForm);
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Votre modification a été bien enregistrée.`,
              }).then(() => this.ngOnInit());
            }
          });
        }
      },
      err => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.currentFile = undefined;
      });

    this.selectedFiles = undefined;
  }

  editDocument(document: DocumentModel) {
    const dialogRef = this.dialogService.open(EditDocumentComponent, {
      closeOnEsc: true,
      autoFocus: true,
      hasScroll: true,
      hasBackdrop: true,
      context: {
        document: document,
      },
    });
    dialogRef.onClose.subscribe(result => {
      // Perform the refresh logic here
      // For example, you can call a method to update the data
      this.ngOnInit();
    });
  }

  downloadFile(documentModel: DocumentModel) {
    this.documentsService.downloadFile(documentModel.id).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = documentModel.file;
      link.click();
    });
  }

  deleteDocument(documentModel: DocumentModel) {
    if (Number(documentModel.id) > 0) {
      this.documentsService.deleteDocument(documentModel.id).subscribe((data: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: `Votre modification a été bien enregistrée.`,
        }).then(() => this.ngOnInit());
        }, (err: any) => {
        },
      );
    }
  }

  beforeDelete(document: DocumentModel, title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        this.deleteDocument(document);
      }
    });
  }

  quickView(document: DocumentModel) {
    this.documentsService.downloadFile(document.id).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      const downloadURL = window.URL.createObjectURL(data);
      const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
      const officeExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx'];
      const extension: string = document.file.split('.').pop();
      if (imageExtensions.includes(extension.toLowerCase())) {
        this.dialogService.open(ViewImageComponent, {
          closeOnEsc: true,
          autoFocus: true,
          hasScroll: true,
          hasBackdrop: true,
          context: {
            document: document,
            fileSrc : this.sanitizer.bypassSecurityTrustUrl(downloadURL),
          },
        });
      } else {
        const viewer = extension === 'pdf' ? 'pdf' : 'office';
        this.dialogService.open(ViewPdfComponent, {
          closeOnEsc: true,
          autoFocus: true,
          hasScroll: true,
          hasBackdrop: true,
          context: {
            document: document,
            fileSrc : downloadURL,
            viewer: viewer,
          },
        });
      }
    });
  }

  onSubmit() {
    this.route.parent.paramMap.subscribe(params => {
      const personneId: string = params.get('id');
      if (personneId) {
        const documentDescription = this.fullName +
          '_' + this.editForm.get('annee').value +
          '_' + this.editForm.get('description').value;
        this.progress = 0;
        this.currentFile = this.selectedFiles.item(0);
        this.documentsService.uploadDocument(
          this.currentFile,
          personneId,
          documentDescription,
          this.editForm.get('typeDocument').value,
        ).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.selectedFiles = undefined;
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Votre modification a été bien enregistrée.`,
              }).then(() => this.ngOnInit());
            }
          },
          err => {
            this.selectedFiles = undefined;
            this.progress = 0;
            this.message = 'Could not upload the file!';
            this.currentFile = undefined;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: err.error.message,
            }).then(() => this.ngOnInit());
          });
      }
    });
    this.selectedFiles = undefined;
  }

  onFileSelect(event) {
    this.selectedFiles = event.target.files;
    // const file = event.target.files[0];
    // this.editForm.get('file').setValue(file);
    // this.selectedFiles = event.target.files;
  }

  generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 50;
    for (let i = max; i >= min; i--) {
      this.years.push(i);
    }
  }

  protected readonly objectKeys = objectKeys;

  setViewType(type: string) {
    this.viewType = type;
  }
}
