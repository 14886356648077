import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {SearchQueryModel} from '../../../lyautey/model/searchQuery.model';

@Component({
  selector: 'ngx-preview-result',
  templateUrl: './preview-result.component.html',
  styleUrls: ['./preview-result.component.scss'],
})
export class PreviewResultComponent {

  @Input() searchQuery: SearchQueryModel;
  @Input() data: any;
  @Input() columns: any;
  @Input() count: number;
  constructor(protected ref: NbDialogRef<PreviewResultComponent>) {}

  dismiss() {
    this.ref.close();
  }
}
