import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {DisciplineModel} from '../model/discipline.model';

@Injectable({
  providedIn: 'root',
})
export class DisciplineService extends ApplicationService {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeDisciplines', 'typeDiscipline');
  }

  getAll(): Observable<DisciplineModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeDisciplines' , {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.typeDisciplines;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<DisciplineModel> {
    return this.httpClient.get<DisciplineModel>(environment.serverUrl + `/contrats/${id}/typeDiscipline`)
      .pipe(
        catchError(this.handleError<DisciplineModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<DisciplineModel> {
    return this.httpClient.get<DisciplineModel>(url).pipe(
      catchError(this.handleError<DisciplineModel>(`getOne`)),
    );
  }
}
