import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {ProfessionConjointModel} from '../model/professionConjoint.model';
import {ApplicationService} from './application.service';

@Injectable({
  providedIn: 'root',
})
export class ProfessionConjointService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'professionConjoints', '');
  }

  getAll(): Observable<ProfessionConjointModel[]> {
    return this.httpClient.get(environment.serverUrl + '/professionConjoints' , {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        return response._embedded.professionConjoints;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }
}
