import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {RegimeModel} from '../model/regime.model';


@Injectable({
  providedIn: 'root',
})
export class RegimeService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeRegimes', 'typeRegime');
  }

  getAll(): Observable<RegimeModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeRegimes' , {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.typeRegimes.filter(value => value['status']);
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  getAllByStatutId(statutId: number): Observable<RegimeModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeRegimes/search/byStatut' , {
      params : new HttpParams()
        .set('size' , this.size.toString())
        .set('typeStatutId' , String(statutId)),
    } ).pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.typeRegimes.filter(value => value['status']);
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  findById(id: number ): Observable<RegimeModel> {
    return this.httpClient.get<RegimeModel>(environment.serverUrl + `/contrats/${id}/regime`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<RegimeModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<RegimeModel> {
    return this.httpClient.get<RegimeModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<RegimeModel>(`getOne`)),
    );
  }
}
