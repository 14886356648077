import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {DocumentModel} from '../model/document.model';
import {BaseModel} from '../model/base.model';

const httpBlobOptions = {
  responseType: 'blob' as 'json',
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root',
})
export class DocumentService  extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'documents', '');
  }

  uploadDocument(file: File, personneId: string,
                 description: string, typeDocument: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('personneId', personneId);
    formData.append('description', description);
    formData.append('typeDocument', typeDocument);
    const req = new HttpRequest('POST', `${environment.serverBaseUrl}document/new`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.httpClient.request(req);
  }


  uploadAttachedDocument(file: File,
                 personneId: string,
                 description: string,
                 typeDocument: string,
                 attachedObjectId: string,
                 attachedObject: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('personneId', personneId);
    formData.append('attachedObjectId', attachedObjectId);
    formData.append('attachedObject', attachedObject);
    formData.append('description', description);
    formData.append('typeDocument', typeDocument);
    const req = new HttpRequest('POST', `${environment.serverBaseUrl}document/new-attached`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.httpClient.request(req);
  }
  getByPersonId(id: number): Observable<DocumentModel[]> {
    return this.httpClient.get(environment.serverUrl + `/personnes/${id}/documents`, {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'date,desc')}).pipe(
      map(response => {
        const excludedValues = ['cte', 'evaluation', 'accident-travail', 'visite-medicale'];

        // @ts-ignore
        return response._embedded.documents.filter(value => !excludedValues.includes(value['attachedObject']));
      }),
      catchError(this.handleError<DocumentModel>(`get DocumentModel id=${id}`)),
    );
  }

  addToPerson(personneid: number, fileInfo: {}, group: FormGroup): void {
    const document = {
      id: 0,
      personne: '',
      description: '',
      file: fileInfo['encryptedFileName'],
      uploadDir: fileInfo['fileLocation'],
      typeDocument: '',
      action: '',
    };
    Object.keys(group.controls).forEach(key => {
      const control = group.controls[key];
      document.personne = environment.serverUrl + `/personnes/${personneid}`;
      document.action = 'POST';
      switch (key) {
        case 'typeDocument':
          if (control.value !== undefined
            && Number(control.value) > 0 ) {
            document.typeDocument =
              environment.serverUrl + `/typeDocuments/${control.value}`;
          }
          break;
        case 'description':
          document.description = control.value;
          break;
      }
    });
    if (document.action === 'POST') {
      delete document['id'];
      this.add(document).subscribe((model: any) => {
        }, (err: any) => {
        },
      );
    } else if (document.action === 'PATCH') {
      this.update(document['id'], document).subscribe((model: any) => {
        }, (err: any) => {
        },
      );
    }
  }

  updateDocument(group: FormGroup): void {
    const document = {
      id: 0,
      description: '',
      typeDocument: '',
    };
    Object.keys(group.controls).forEach(key => {
      const control = group.controls[key];
      switch (key) {
        case 'id':
          document.id = control.value;
          break;
        case 'typeDocument':
          if (control.value !== undefined
            && Number(control.value) > 0 ) {
            document.typeDocument =
              environment.serverUrl + `/typeDocuments/${control.value}`;
          }
          break;
        case 'description':
          document.description = control.value;
          break;
      }
    });
    this.update(document['id'], document).subscribe((model: any) => {
      }, (err: any) => {
      },
    );
  }

  getFile(): Observable<Blob> {
    return this.httpClient.get(`${environment.serverBaseUrl}document/downloadFile`, { responseType: 'blob' });
  }

  downloadFile(id: number): any {
    return this.httpClient.post(`${environment.serverBaseUrl}document/download/${id}`, {}, httpBlobOptions).pipe(
      tap(_ => console.log(`downloadFile`)),
      catchError(this.handleError<any>('downloadFile')),
    );
  }

  deleteDecodedFile(id: number): any {
    return this.httpClient.get(`${environment.serverBaseUrl}document/sanitize`, httpBlobOptions).pipe(
      tap(_ => console.log(`deleteDecodedFile`)),
      catchError(this.handleError<any>('deleteDecodedFile')),
    );
  }

  deleteDocument(id: number): Observable<BaseModel> {
    return this.httpClient.delete<BaseModel>(`${environment.serverBaseUrl}document/delete/${id}`,
      this.httpOptions).pipe(
      tap(_ => console.log(`deleted id=${id}`)),
      catchError(this.handleError<BaseModel>('delete')),
    );
  }

  upload(file: File, typeDocument: string, personne: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('typeDocument', typeDocument);
    formData.append('personne', personne);

    const req = new HttpRequest('POST', `${environment.serverBaseUrl}document/add`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  getEvaluation(personneId: number, evaluationId: number): Observable<DocumentModel> {
    return this.httpClient.get<DocumentModel>(environment.serverUrl + '/documents/search/evaluation' , {
      params : new HttpParams()
        .set('personneId' , String(personneId))
        .set('attachedObjectId' , String(evaluationId))
        .set('typeDocumentId' , String(14))
        .set('attachedObject' , 'evaluation'),
    } ).pipe(
      tap(_ => console.log(`fetched personne id`)),
      catchError(this.handleError<DocumentModel>(`getPhoto id=${personneId}`)),
    );
  }
  addEvaluationDocument(file: File, data: {
    evaluationId: any;
    typeDocumentId: any;
    personneId: any;
    description: string;
  }): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('attachedObbject', 'evaluation');
    formData.append('attachedObbjectId', data.evaluationId);
    formData.append('personneId', data.personneId);
    formData.append('description', data.description);
    formData.append('typeDocument', data.typeDocumentId);
    const req = new HttpRequest('POST', `${environment.serverBaseUrl}document/evaluation`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.httpClient.request(req);
  }

  get(id: number): Observable<DocumentModel> {
    return this.httpClient.get<DocumentModel>(this.baseUrl + id).pipe(
      tap(_ => console.log(`get id=${id}`)),
      catchError(this.handleError<DocumentModel>(`get id=${id}`)),
    );
  }
}

