import { Injectable } from '@angular/core';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NbAclService} from "@nebular/security";

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  protected roles: any;
  protected authenticated: any;
  protected groups: any;
  public activeGroup: any;

  constructor(protected router: Router, protected keycloakService: KeycloakService, public nbAclService: NbAclService) {
    super(router, keycloakService);
    this.groups =
      this.keycloakService.getKeycloakInstance().tokenParsed != undefined && this.keycloakService.getKeycloakInstance().tokenParsed['groups'] !== undefined
      ? this.keycloakService.getKeycloakInstance().tokenParsed['groups'] : [];
    this.groups = this.groups.map(function (val, index) {
      return val.replace('/', '');
    });
    this.activeGroup = this.groups[0] !== undefined ? this.groups[0]: 'guest'
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.authenticated) {
        await this.keycloakService.login({
          redirectUri: window.location.origin + state.url
        });
      }
      const requiredRoles = route.data.roles;
      let granted: boolean = false;
      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.groups.includes(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }
      if (granted) {
        let permissions = {};
        this.keycloakService.getKeycloakInstance().realmAccess.roles.forEach((item, index) => {
          if (item.indexOf('rh:') > -1) {
            const permission = item.split(':');
            if (permissions.hasOwnProperty(permission[2])) {
              const existing = permissions[permission[2]];
              existing.push(permission[1]);
              permissions[permission[2]] = existing;
            } else {
              permissions[permission[2]] = [permission[1]];
            }
          }
        });
        this.nbAclService.setAccessControl( { [this.activeGroup]: permissions});
      } else {
        this.nbAclService.setAccessControl({});
        await this.router.navigate(['pages/miscellaneous/403']);
      }
      resolve(granted);
    });
  }


  public getGroup(): string {
    if (this.groups !== undefined && this.groups.indexOf('manager') > -1) {
      if (this.groups.indexOf('administrator') > -1) return 'administrator';
      else if (this.groups.indexOf('manager') > -1) return 'manager';
      else if (this.groups.indexOf('rh') > -1) return 'rh';
      else if (this.groups.indexOf('director') > -1) return 'director';
      else if (this.groups.indexOf('evaluator') > -1) return 'evaluator';
      else if (this.groups.indexOf('formation') > -1) return 'formation';
      else if (this.groups.indexOf('supervisor') > -1) return 'supervisor';
      else if (this.groups.indexOf('assistant') > -1) return 'assistant';
      else if (this.groups.indexOf('employee') > -1) return 'employee';
      else return 'guest';
    }
    return 'guest';
  }

  public isAdministrator(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('administrator') > -1) {
      return true;
    }
    return false;
  }

  public isManager(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('manager') > -1) {
      return true;
    }
    return false;
  }

  public isRH(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('rh') > -1) {
      return true;
    }
    return false;
  }

  public isDirector(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('director') > -1) {
      return true;
    }
    return false;
  }

  public isAssistante(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('ASSISTANTE') > -1) {
      return true;
    }
    return false;
  }

  public isEvaluateur(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('evaluator') > -1) {
      return true;
    }
    return false;
  }

  public isFormation(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('formation') > -1) {
      return true;
    }
    return false;
  }

  public isSupervisor(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('supervisor') > -1) {
      return true;
    }
    return false;
  }

  public isEmployee(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('employee') > -1) {
      return true;
    }
    return false;
  }
}
