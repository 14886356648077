import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {FormArray, FormGroup} from '@angular/forms';
import {AdresseModel} from '../model/adresse.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdresseService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'adresses', '');
  }
  getByPersonId(id: number ): Observable<AdresseModel> {
    const url = environment.serverUrl + `/personnes/${id}/adresses`;
    return this.httpClient.get<AdresseModel>(url).pipe(
      tap(_ => console.log(`fetched personne adresse id=${id}`)),
      catchError(this.handleError<AdresseModel>(`get AdresseModel id=${id}`)),
    );
  }

  addToPerson(personneId: number, group: FormGroup): void {
    const formArray = group.get('adresses') as FormArray;
    const adresses = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const adresse = {
          id: 0,
          personne: '',
          rue: '',
          ville: '',
          country: '',
          codePostal: '',
          telephone: '',
          gsm: '',
          action: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'id':
              adresse.personne = environment.serverUrl + `/personnes/${personneId}`;
              if (Number(control.controls['id'].value) > 0) {
                adresse.id = Number(control.controls['id'].value);
                adresse.action = 'PATCH';
              } else {
                adresse.action = 'POST';
              }
              break;
            case 'rue':
              adresse.rue = control.controls['rue'].value;
              break;
            case 'ville':
              adresse.ville = control.controls['ville'].value;
              break;
            case 'country':
              if (control.controls['country'].value !== undefined
                && control.controls['country'].value !== null
                && control.controls['country'].value.id !== undefined
                && Number(control.controls['country'].value.id) > 0 ) {
                adresse.country = environment.serverUrl + `/countries/${control.controls['country'].value.id}`;
              }
              break;
            case 'codePostal':
              adresse.codePostal = control.controls['codePostal'].value;
              break;
            case 'telephone':
              adresse.telephone = control.controls['telephone'].value;
              break;
            case 'gsm':
              adresse.gsm = control.controls['gsm'].value;
              break;
          }
        });
        if (adresse.action === 'POST') {
          delete adresse.id;
          this.add(adresse).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        } else if (adresse.action === 'PATCH') {
          this.update(adresse.id, adresse).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        }
        adresses.push(adresse);
      }
    }
  }
}
