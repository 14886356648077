import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RegimeModel} from '../model/regime.model';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {TypeModel} from '../model/type.model';

@Injectable({
  providedIn: 'root',
})
export class TypeService  extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'types', 'type');
  }

  getAll(): Observable<TypeModel[]> {
    return this.httpClient.get(environment.serverUrl + '/types' , {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.types.filter(value => value['status']);
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  getAllByRegimeId(regimeId: number): Observable<TypeModel[]> {
    return this.httpClient.get(environment.serverUrl + '/types/search/byRegime' , {
      params : new HttpParams()
        .set('typeRegimeId' , String(regimeId)),
    } ).pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.types.filter(value => value['status']);
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  findById(id: number ): Observable<TypeModel> {
    return this.httpClient.get<TypeModel>(environment.serverUrl + `/contrats/${id}/type`)
      .pipe(
        catchError(this.handleError<TypeModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<TypeModel> {
    return this.httpClient.get<TypeModel>(url).pipe(
      // tap((value) => console.log(value)),
      catchError(this.handleError<TypeModel>(`getOne`)),
    );
  }
}
