import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {TypeDocumentModel} from '../model/typeDocument.model';

@Injectable({
  providedIn: 'root',
})
export class TypeDocumentsService extends ApplicationService {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeDocuments', 'typeDocuments');
  }
  getAll(): Observable<TypeDocumentModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeDocuments' , {
      params : new HttpParams().set('size' , this.size.toString()).set('sort' , 'categorie,asc')})
      .pipe(
      map(response => {
        // @ts-ignore
        // @ts-ignore
        return response._embedded.typeDocuments;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }

  findById(id: number ): Observable<TypeDocumentModel> {
    return this.httpClient.get<TypeDocumentModel>(environment.serverUrl + `/typeDocuments/${id}`)
      .pipe(
        catchError(this.handleError<TypeDocumentModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<TypeDocumentModel> {
    return this.httpClient.get<TypeDocumentModel>(url).pipe(
      catchError(this.handleError<TypeDocumentModel>(`getOne`)),
    );
  }

}
