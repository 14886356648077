<nb-card status="primary"  *nbIsGranted="['listing', 'personnel']">
  <nb-card-header>
    Personnels
  </nb-card-header>

  <nb-card-body>


    <form [formGroup]="userForm">
      <div class="form-row">
        <div class="form-group col-md-1">
        </div>
        <div class="form-group col-md-6"></div>
        <div class="form-group col-md-1">
          <label class="control-label" for="contratStatus" style="text-align: justify">Etat du contrat</label>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control" formControlName="contratStatus" id="contratStatus">
            <option *ngFor="let item of statutContrats" [ngValue]="item.value ">
              {{ item.viewValue }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-1">
          <button (click)="submitForm()" [disabled]="!userForm.valid" class="btn btn-success btn-block form-control"><i class="fas fa-search"></i> &nbsp; Filtrer</button>
        </div>
      </div>
    </form>

    <div class="form-row">
      <div class="form-group col-md-12">
        <table class="table table-striped table-bordered display responsive" width="100%" >
        <thead>
        <tr>
          <th class="all">Nom</th>
          <th class="all">Date de naissance</th>
          <th class="all">Date entrée ERC</th>
          <th class="all">Établissement</th>
          <th class="all">Fonction</th>
          <th class="all">Statut</th>
          <th class="all">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let employee of result">
          <td>{{employee.civilite}} {{employee.nomUsuel}} {{employee.prenom}}</td>
          <td>
            <span style="display: none">{{employee.dateNaissance | amDateFormat:'YYYY-MM-DD' }}</span>
            {{employee.dateNaissance | amDateFormat:'DD/MM/YYYY' }}</td>
          <td>
            <span style="display: none">{{employee.dateEntreeErc  | amDateFormat:'YYYY-MM-DD' }}</span>
            {{employee.dateEntreeErc  | amDateFormat:'DD/MM/YYYY' }}
          </td>
          <td>{{employee.etablissement}}</td>
          <td>{{employee.fonction}}</td>
          <td>{{employee.typeStatut}}</td>
          <td>
            <div class="form-inline">
              <div class="btn-group" btnRadioGroup>
                <label *nbIsGranted="['view', 'personnel']" class="btn btn-primary" style="cursor: pointer" role="button" btnRadio="Left" (click)="redirect(employee.id, 'fiche')" title="Fiche personnel"><i class="fas fa-address-card"></i></label>
                <label *nbIsGranted="['view', 'contrat']" class="btn btn-secondary" style="cursor: pointer" role="button" btnRadio="Left" (click)="redirect(employee.id, 'contrat')" title="Contrat"><i class="fas fa-suitcase"></i></label>
                <label *nbIsGranted="['view', 'document']" class="btn btn-secondary" style="cursor: pointer" role="button" btnRadio="Left" (click)="redirect(employee.id, 'documents')" title="Documents"><i class="fas fa-folder"></i></label>
                <label *nbIsGranted="['view', 'formation']" class="btn btn-secondary" style="cursor: pointer" role="button" btnRadio="Left" (click)="redirect(employee.id, 'formation')" title="Fromation"><i class="fas fa-graduation-cap"></i></label>
                <label *nbIsGranted="['download', 'certificate']" class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" (click)="certificat(employee.id)" title="Certificat d'exercice"> <i class="fas fa-file-pdf"></i></label>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>



  </nb-card-body>
</nb-card>

