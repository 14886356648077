import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[ngxOnlyNumber]',
})
export class OnlyNumberDirective {

  constructor(private el: ElementRef) { }
  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const newValue = inputElement.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters
    inputElement.value = newValue;
    event.preventDefault();
  }

}
