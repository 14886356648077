import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {StatutModel} from '../model/statut.model';

@Injectable({
  providedIn: 'root',
})
export class StatutService  extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'typeStatuts', 'statut');
  }

  getAll(): Observable<StatutModel[]> {
    return this.httpClient.get(environment.serverUrl + '/typeStatuts' , {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.typeStatuts.filter(value => value['status']);
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<StatutModel> {
    return this.httpClient.get<StatutModel>(environment.serverUrl + `/contrats/${id}/typeStatut`)
      .pipe(
       // tap((value) => console.log(value)),
        catchError(this.handleError<StatutModel>(`findById`)),
      );
  }

  getByUrl(url: string ): Observable<StatutModel> {
    return this.httpClient.get<StatutModel>(url).pipe(
     // tap((value) => console.log(value)),
      catchError(this.handleError<StatutModel>(`getOne`)),
    );
  }
}
