import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-avancement-timeline',
  templateUrl: './avancement-timeline.component.html',
  styleUrls: ['./avancement-timeline.component.scss'],
})
export class AvancementTimelineComponent implements OnInit {
  @Input() avancements: any[];  // Define the type according to your data structure

  constructor() { }

  ngOnInit(): void {
  }

}
