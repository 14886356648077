<nb-card status="primary">
  <nb-card-header>
    {{ searchQueryDescription }}


  </nb-card-header>

  <nb-card-body>
    <ngx-spinner  size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false"><p style="color: white" > Veuillez patienter s'il vous plait... </p></ngx-spinner>

    <div class="form-row" *nbIsGranted="['download', 'tutors']" >
      <div class="form-group col-md-2">
        <button status="info" class="form-control" nbButton (click)="enfantsAcharge()">Enfants à charge</button>
      </div>
    </div>

    <div class="container-fluid">
      <form [formGroup]="searchCriteriaForm" novalidate>
        <nb-stepper orientation="horizontal">
        <nb-step [label]="labelOne">
          <ng-template #labelOne>Critères</ng-template>
          <div class="form-row">
            <div class="form-group col-md-3 text-left">
              <h4>Rapport</h4>
            </div>
            <div class="form-group col-md-9 text-right">
              <button class="prev-button" nbButton disabled nbStepperNext style="margin: 0 10px">Précédent</button>
              <button class="next-button" nbButton nbStepperNext>Suivant</button>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-2" >
              <label [attr.for]="'description'">Nom du rapport</label>
            </div>
            <div class="form-group col-md-5" >
              <input type="text" class="form-control" formControlName="description" [id]="'description'" />
            </div>
            <div class="form-group col-md-5" ></div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-2" >
              <label [attr.for]="'visibilite'">Type</label>
            </div>
            <div class="form-group col-md-5" >
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="visibilitePersonnelle" value="1" formControlName="visibilite" />
                <label class="form-check-label" for="visibilitePersonnelle">Liste personnelle</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="visibiliteCommune" value="2" formControlName="visibilite" />
                <label class="form-check-label" for="visibiliteCommune">Liste commune</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="visibiliteCommuneVerrouillee" value="3" formControlName="visibilite" />
                <label class="form-check-label" for="visibiliteCommuneVerrouillee">Liste commune verrouillée</label>
              </div>
            </div>
          </div>
          <h4>Critères de votre recherche</h4>
          <div formArrayName="criteria" *ngIf="searchCriteriaForm['controls'].criteria['controls'].length>0">
            <div *ngFor="let item of searchCriteriaForm['controls'].criteria['controls']; let i=index" >
              <div [formGroupName]="i" >
                <br *ngIf="i==0" />
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label [attr.for]="'criterion' +i">Paramètre</label>
                    <ng-select formControlName="criterion"
                               [id]="'criterion' +i"
                               [items]="globalObject.criteriaList"
                               (change)="criterionChangedHandler($event, i)"
                               bindLabel="viewValue"
                               appendTo="body"
                               bindValue="value" >
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'posteType'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="globalObject.typeMage"
                               bindLabel="viewValue"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="value"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'situationFamilialeId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="situationFamiliales | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'genreId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="genres | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'niveauEtudeId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="typeNiveaux | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'etablissementId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="etablissements | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'nationaliteId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="countries | async"
                               bindLabel="nomFrFr"
                               [virtualScroll]="true"
                               multiple="multiple"
                               bindValue="id"
                               appendTo="body"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'typeFonctionId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="fonctions | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               (change)="onChange($event)"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'typeStatutId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="statuts | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'typeCorpsId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="corps | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'typeDisciplineId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="corps | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'typeRegimeId'">
                    <label [attr.for]="'listValues' +i">Valeur</label>
                    <ng-select [items]="regimes | async"
                               bindLabel="description"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               bindValue="id"
                               appendTo="body"
                               formControlName="listValues" [id]="'listValues' +i">
                    </ng-select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratStatus'">
                    <label [attr.for]="'value' +i">Valeur</label>
                    <select class="form-control" formControlName="value" [id]="'value' +i">
                      <option *ngFor="let item of globalObject.statutContrats" [ngValue]="item.value ">
                        {{ item.viewValue }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDateEntreeErc'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="minValue" placeholder="Date d'entrée" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d1="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date d'entrée" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d2="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'dateNaissance'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="minValue" placeholder="Date de naissance" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d3="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date de naissance" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d4="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDateCdd'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="minValue" placeholder="Date CDD" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d5="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date CDD" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d6="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDatePromotion'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="minValue" placeholder="Date Promotion" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d7="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date Promotion" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d8="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDateCdi'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="minValue" placeholder="Date CDI" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d9="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date Promotion" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d10="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDateArchivage'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="minValue" placeholder="Date archivage" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d11="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date archivage" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d12="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDateSortieErc'">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label [attr.for]="'minValue' +i">Du</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="minValue" placeholder="Date de sortie" [id]="'minValue' +i"
                                 container="body"  ngbDatepicker #d13="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label [attr.for]="'maxValue' +i">Au</label>
                        <div class="input-group">
                          <input type="text" class="form-control" formControlName="maxValue" placeholder="Date Promotion" [id]="'maxValue' +i"
                                 container="body"  ngbDatepicker #d14="ngbDatepicker"
                                 placement="top"
                                 [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'cfeActivated'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'cnssActivated'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'cimrActivated'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'cimrctivated'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'totalEnfantsAcharge'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratEncadrement'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5" *ngIf="item.get('criterion').value === 'contratDisponibilite'">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <div class="form-check form-check-inline">
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1"></div>
                      <div class="form-group col-md-11">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueOui' +i" value="Oui" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueOui' +i">Oui</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" [id]="'valueNon' +i" value="Non" formControlName="value" />
                          <label class="form-check-label" [attr.for]="'valueNon' +i">Non</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-1">
                    <label>&nbsp;</label>
                    <button nbButton status="danger" class="form-control" type="button" (click)="beforeDelete(i, 'Suppression de criterion', 'Cette action n\'est pas revocable')"><i class="fas fa-minus-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-1">
              <button nbButton status="success" class="float-right form-control" type="button" (click)="addCriteriaButtonClick()"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-group col-md-11">
              <label>&nbsp;</label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 text-right">
              <button class="prev-button" nbButton disabled nbStepperNext style="margin: 0 10px">Précédent</button>
              <button class="next-button" nbButton nbStepperNext>Suivant</button>
            </div>
          </div>
        </nb-step>
        <nb-step [label]="labelTwo">
          <ng-template #labelTwo>Champs</ng-template>
          <div class="form-row">
            <div class="form-group col-md-3 text-left">
              <h4>Champs de votre recherche</h4>
            </div>
            <div class="form-group col-md-9 text-right">
              <button class="prev-button" nbButton nbStepperNext  style="margin: 0 10px">Précédent</button>
              <button (click)="submitForm()" class="btn btn-success" nbButton nbStepperNext>Lancer la recherche</button>
            </div>
          </div>
          <div class="container">
            <div class="table-responsive-md">
              <table class="table">
                <tr>
                  <th>
                    Personne <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="personneFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.personneFields">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Adresse Maroc <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="adresseMarocFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.adresseMarocFields">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Adresse Hors Maroc <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="adresseHorsMarocFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.adresseHorsMarocFields">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Contact d'urgence <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="contactUrgenceFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.contactUrgenceFields ">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Contrat <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="contratFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.contratFields">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" />  {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    Grille <br />
                  </th>
                  <td>
                    <div class="row" formGroupName="grilleFields">
                      <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.grilleFields">
                        <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
                  <tr>
                      <th>
                          Autre <br />
                      </th>
                      <td>
                          <div class="row" formGroupName="autreFields">
                              <div class="col-xs-6 col-sm-4 checkbox" *ngFor="let item of orderedFields.autreFields">
                                  <label for="{{item}}" ><input type="checkbox" formControlName="{{item}}" id="{{item}}" /> {{fieldsLabels[item]}}</label>
                              </div>
                          </div>
                      </td>
                  </tr>
              </table>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 text-right">
              <button class="prev-button" nbButton nbStepperNext  style="margin: 0 10px">Précédent</button>
              <button (click)="submitForm()" class="btn btn-success" nbButton nbStepperNext >Lancer la recherche</button>
            </div>
          </div>
        </nb-step>
        <nb-step [label]="labelThree">
          <ng-template #labelThree>Résultat</ng-template>
          <div class="form-row">
            <div class="form-group col-md-3">
              <h4> {{ resultCount }} éléments trouvés</h4>
            </div>
            <div class="form-group col-md-3">
            </div>
            <div class="form-group col-md-2" *ngIf="isPersist">
              <button status="primary" class="form-control" nbButton (click)="saveSearch()">Sauvegarder</button>
            </div>
            <div class="form-group col-md-2" *ngIf="!isPersist">
              &nbsp;
            </div>
            <div class="form-group col-md-2">
              <button status="success" class="form-control" nbButton (click)="download()">Télécharger</button>
            </div>
            <div class="form-group col-md-2" *ngIf="!appAuthGuard.isDirector()">
              <button status="info" class="form-control" nbButton (click)="generateDocuments()">Certificats</button>
            </div>
          </div>

          <div class="form-row" *ngIf="resultCount > 0">
            <h4>Aperçu général des résultats</h4>
            <div class="form-group col-md-12">
              <table class="table table-striped table-bordered display responsive" width="100%" >
                <thead>
                <tr>
                  <th *ngFor="let column of columns; let index = index;">{{column}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let employee of result">
                  <td *ngFor="let column of columns; let index = index;">{{employee[column]}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
      </form>
    </div>
  </nb-card-body>
</nb-card>

