import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectPage {
  private basePersonnel = '/pages/personnel/personne/';
  private baseEvaluation = '/pages/evaluation/';

  constructor(private router: Router) {
  }

  refreshPersonnel (params) {
    this.router.navigate([this.basePersonnel, params.key, params.action]).then(r => console.log('redirecting...') );
  }

  refreshEvaluation (params) {
    this.router.navigate([this.baseEvaluation, params.action, params.key]);
  }

}
