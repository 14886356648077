<nb-card status="primary" style="margin-top: 10px" *nbIsGranted="['create', 'document']">
  <nb-card-header>
  Nouveau document
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]='editForm' autocomplete="off" (ngSubmit)="onSubmit()">

      <div class="form-row">

        <div class="form-group col-md-1">
          <label for="annee">Année</label>
          <input type="hidden" class="form-control" formControlName="id" />
          <select class="form-control"  fullWidth formControlName="annee" id="annee">
            <option *ngFor="let item of years" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="description">Libelle</label>
          <input type="hidden" class="form-control" formControlName="id" />
          <input type="description" class="form-control" id="description"  formControlName="description"/>
        </div>

        <div class="form-group col-md-3">
          <label for="typeDocument">Type</label>
            <select class="form-control" placeholder="Type Document" formControlName="typeDocument" id="typeDocument">
                <optgroup *ngFor="let group of groupedTypeDocuments | keyvalue" label="{{ group.key }}">
                    <option *ngFor="let item of group.value" [ngValue]="item.id">
                        {{ item.description }}
                    </option>
                </optgroup>
            </select>
        </div>

        <div class="form-group col-md-3">
          <label for="file">Document</label>
          <input class="form-control" type="file" id="file"  formControlName="file" (change)="onFileSelect($event)" />
        </div>

      </div>
      <button class="btn btn-success" type="submit">
        <i class="fas fa-file-upload"></i>&nbsp;Ajouter
      </button>
    </form>
  </nb-card-body>
</nb-card>

<nb-card status="primary"  *nbIsGranted="['listing', 'document']">
  <nb-card-header *ngIf="documents.length > 0">

      <div class="d-flex justify-content-between align-items-center">
          <div>Liste des documents</div>
          <div>
              <button (click)="setViewType('list')" class="btn btn-sm">
                  <nb-icon icon="list-outline"></nb-icon>
              </button>
              <button (click)="setViewType('table')" class="btn btn-sm">
                  <nb-icon icon="grid-outline"></nb-icon>
              </button>
          </div>
      </div>
  </nb-card-header>
  <nb-card-body>
      <div class="table-responsive" *ngIf="viewType === 'list'">
          <div class="table-responsive" *ngFor="let group of objectKeys(groupedDocuments)">
              <h3>{{ group }}</h3>
              <table class="table table-bordered table-hover">
                  <thead>
                  <tr>
                      <th scope="col" style="width: 10%">id</th>
                      <th scope="col" style="width: 40%">Libelle</th>
                      <th scope="col" style="width: 35%">Type</th>
                      <th scope="col" style="width: 15%">Date</th>
                      <th scope="col" style="width: 15%">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let document of groupedDocuments[group]">
                      <td>{{ document.id }}</td>
                      <td>{{ document.description }}</td>
                      <td>{{ document.typeDocument }}</td>
                      <td>{{ document.date | amDateFormat:'DD/MM/YYYY hh:mm:ss' }}</td>
                      <td>
                          <div class="form-inline">
                              <div class="btn-group" btnRadioGroup>
                                  <label class="btn btn-primary" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="downloadFile(document)" title="Télécharger ce document"> <i class="fas fa-file-download"></i></label>
                                  <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="quickView(document)" title="Aperçu"> <i class="fas fa-eye"></i></label>
                                  <label class="btn btn-warning" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['create', 'document']" (click)="editDocument(document)" title="Modifier"> <i class="fas fa-edit"></i></label>
                                  <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left" *nbIsGranted="['delete', 'document']" (click)="beforeDelete(document, 'Suppression de document', 'Cette action n\'est pas revocable')" title="Supprimer"><i class="fas fa-trash"></i></label>
                              </div>
                          </div>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </div>
        <div class="table-responsive" *ngIf="viewType === 'table'">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th scope="col" style="width: 10%">id</th>
                    <th scope="col" style="width: 40%">Libelle</th>
                    <th scope="col" style="width: 35%">Type</th>
                    <th scope="col" style="width: 15%">Date</th>
                    <th scope="col" style="width: 15%">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let document of advancedDocuments">
                    <td>{{ document.id }}</td>
                    <td>{{ document.description }}</td>
                    <td><b>{{ document.group }}</b> - {{ document.typeDocument }}</td>
                    <td>{{ document.date | amDateFormat:'DD/MM/YYYY hh:mm:ss' }}</td>
                    <td>
                        <div class="form-inline">
                            <div class="btn-group" btnRadioGroup>
                                <label class="btn btn-primary" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="downloadFile(document)" title="Télécharger ce document"> <i class="fas fa-file-download"></i></label>
                                <label class="btn btn-info" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['download', 'document']" (click)="quickView(document)" title="Aperçu"> <i class="fas fa-eye"></i></label>
                                <label class="btn btn-warning" style="cursor: pointer" role="button" btnRadio="Right" *nbIsGranted="['create', 'document']" (click)="editDocument(document)" title="Modifier"> <i class="fas fa-edit"></i></label>
                                <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left" *nbIsGranted="['delete', 'document']" (click)="beforeDelete(document, 'Suppression de document', 'Cette action n\'est pas revocable')" title="Supprimer"><i class="fas fa-trash"></i></label>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

  </nb-card-body>

</nb-card>

