import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DbDate {
  constructor() {
  }

  format (stringDate: string) {
    const date = stringDate.split('-');
    const dateParts =  {
      day : parseInt(date[2], 10) < 10 ? '0' + parseInt(date[2], 10) : parseInt(date[2], 10),
      month : parseInt(date[1], 10) < 10 ? '0' + parseInt(date[1], 10) : parseInt(date[1], 10),
      year : parseInt(date[0], 10),
    };

    const formattedStringDate: string =
      moment(new Date(dateParts.year, <number>dateParts.month - 1, <number>dateParts.day))
      .format('YYYY-MM-DD');
    return formattedStringDate;
  }

}
