import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {VacationCategorieHoraireModel} from '../model/vacationCategorieHoraire.model';

@Injectable({
  providedIn: 'root',
})
export class VacationCategorieHoraireService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'vacationCategorieHoraires', 'vacationCategorieHoraire');
  }

  getAll(): Observable<VacationCategorieHoraireModel[]> {
    return this.httpClient.get(environment.serverUrl + '/vacationCategorieHoraires' , {
      params: new HttpParams()
        .set('size' , this.size.toString())
        .set('sort' , 'description,asc')})
      .pipe(
        map(response => {
          // @ts-ignore
          return response._embedded.vacationCategorieHoraires;
        }),
        catchError(this.handleError('getAll', [])),
      );
  }

  findById(id: number ): Observable<VacationCategorieHoraireModel> {
    return this.httpClient.get<VacationCategorieHoraireModel>(
      environment.serverUrl + `/contrats/${id}/vacationCategorieHoraire`)
      .pipe(
        // tap((value) => console.log(value)),
        catchError(this.handleError<VacationCategorieHoraireModel>(`findById`)),
      );
  }

  getOne(id: number): Observable<VacationCategorieHoraireModel> {
    return this.httpClient.get<VacationCategorieHoraireModel>(this.baseUrl + id).pipe(
      tap(_ => console.log(`getOne id=${id}`)),
      catchError(this.handleError<VacationCategorieHoraireModel>(`getOne id=${id}`)),
    );
  }

  getByUrl(url: string ): Observable<VacationCategorieHoraireModel> {
    return this.httpClient.get<VacationCategorieHoraireModel>(url).pipe(
      // tap((value) => console.log(value)),
      catchError(this.handleError<VacationCategorieHoraireModel>(`getOne`)),
    );
  }
}
