import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { NbRoleProvider } from '@nebular/security';
import {KeycloakService} from "keycloak-angular";
import {from, of as observableOf} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RoleProvider implements NbRoleProvider {

  constructor(protected keycloakService: KeycloakService) {
  }

  getRole(): Observable<string> {
    let groups =
      this.keycloakService.getKeycloakInstance().tokenParsed != undefined && this.keycloakService.getKeycloakInstance().tokenParsed['groups'] !== undefined
        ? this.keycloakService.getKeycloakInstance().tokenParsed['groups'] : [];
    groups = groups.map(function (val, index) {
      return val.replace('/', '');
    });
    return observableOf(groups[0] !== undefined ? groups[0]: 'guest');
  }
}



