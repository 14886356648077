<nb-card status="primary" style="margin-top: 10px" *nbIsGranted="['create', 'avancement']">
    <nb-card-header>
        Nouvel avacement
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]='editForm' autocomplete="off" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="form-row">
                        <div class="form-group col-md-5">
                            <label for="contrat">Contrat</label>
                            <select class="form-control" formControlName="contrat" id="contrat">
                                <option *ngFor="let item of (contrats )" [ngValue]="item.contrat">
                                    {{ item.etablissement | uppercase }} - {{ item.fonction }} -
                                    [{{ item.status | uppercase }} - {{ item.contrat }}]
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="grilleType">Type de la grille</label>
                            <input type="hidden" class="form-control" formControlName="id"/>
                            <select class="form-control" formControlName="grilleType" id="grilleType">
                                <option *ngFor="let item of this.globalObject.grilleType" [ngValue]="item.value ">
                                    {{ item.viewValue }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-1">
                            <label for="grilleDescription">Grille</label>
                            <input type="text" class="form-control" formControlName="grilleDescription"
                                   id="grilleDescription"/>
                        </div>

                        <div class="form-group col-md-1">
                            <label for="echelon">Echelon</label>
                            <input type="text" class="form-control" formControlName="echelon" id="echelon"
                                     />
                        </div>

                        <div class="form-group col-md-1">
                            <label for="duree">Durée</label>
                            <input type="text" class="form-control"  formControlName="duree"  id="duree"

                            />
                        </div>

                        <div class="form-group col-md-1">
                            <label for="indiceBrut">Indice Brut</label>
                            <input type="text" class="form-control"  formControlName="indiceBrut" id="indiceBrut"

                            />
                        </div>

                        <div class="form-group col-md-1">
                            <label for="reliquat">Reliquat</label>
                            <input type="text" class="form-control"  formControlName="reliquat" id="reliquat"

                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="datePromotion"> Date de la promotion en vigueur</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Date de la promotion en vigueur"
                                       formControlName="datePromotion" id="datePromotion"
                                       container="body" ngbDatepicker #d83="ngbDatepicker"
                                       placement="top"
                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d83.toggle()"
                                            type="button"></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="typeAvancement">Type d'avancement </label>
                            <select class="form-control" formControlName="typeAvancement" id="typeAvancement">
                                <option *ngFor="let item of this.globalObject.typeAvancement" [ngValue]="item.value">
                                    {{ item.viewValue }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="promotionMoisGagnes">Nombre de mois gagnés</label>
                            <input type="text" class="form-control" id="promotionMoisGagnes"
                                   formControlName="promotionMoisGagnes"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-9">
                </div>
                <div class="form-group col-md-1" >
                    <button *ngIf="editForm.get('id').value > 0" class="btn btn-secondary" type="button" (click)="resetEditForm()">
                        Annuler
                    </button>
                </div>
                <div class="form-group col-md-1">
                </div>
                <div class="form-group col-md-1">
                    <button class="btn btn-success" type="submit">
                        &nbsp;Sauvegarder
                    </button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>
<div *ngFor="let contrat of contrats">
    <nb-card [status]="getCardStatus(contrat.status)" *ngIf="contrat.avancements.length > 0">
        <nb-card-header>
            <div class="d-flex justify-content-between align-items-center">
                <div>Contrat: {{ contrat.etablissement }} - {{ contrat.fonction }} - {{ contrat.contrat }}</div>
                <div>
                    <button (click)="setViewType('table', contrat)" class="btn btn-sm">
                        <nb-icon icon="grid-outline"></nb-icon>
                    </button>
                    <button (click)="setViewType('timeline', contrat)" class="btn btn-sm">
                        <nb-icon icon="calendar-outline"></nb-icon>
                    </button>
                    <button (click)="printContent()" class="btn btn-sm">
                        <nb-icon icon="printer-outline"></nb-icon>
                    </button>
                </div>
            </div>
        </nb-card-header>

        <nb-card-body>
            <div class="print-section">
                <div class="table-responsive" *ngIf="contrat.viewType === 'table'">
                    <table class="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Grille</th>
                            <th scope="col">Echelon</th>
                            <th scope="col">Duree</th>
                            <th scope="col">Indice brut</th>
                            <th scope="col">Reliquat</th>
                            <th scope="col">Date de la promotion en vigueur</th>
                            <th scope="col">Type d'avancement</th>
                            <th scope="col">Nombre de mois gagnés</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of contrat.avancements; let i=index">
                            <td>{{ item.id }}</td>
                            <td>{{ item.grilleType }} - {{ item.grilleDescription }}</td>
                            <td>{{ item.echelon }}</td>
                            <td>{{ item.duree }}</td>
                            <td>{{ item.indiceBrut }}</td>
                            <td>{{ item.reliquat }}</td>
                            <td>
                                <span style="display: none">{{ item.datePromotion | amDateFormat:'YYYY-MM-DD' }}</span>
                                {{ item.datePromotion | amDateFormat:'DD/MM/YYYY' }}
                            </td>
                            <td>{{ item.typeAvancement }}</td>
                            <td>{{ item.promotionMoisGagnes }}</td>
                            <td>
                                <div class="form-inline" style="float: right">
                                    <div class="btn-group" btnRadioGroup>
                                        <label class="btn btn-warning" style="cursor: pointer" role="button"
                                               btnRadio="Right" *nbIsGranted="['create', 'avancement']"
                                               (click)="editAvancement(item, contrat)" title="Modifier"> <i class="fas fa-edit"></i></label>
                                        <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left"
                                               *nbIsGranted="['delete', 'avancement']"
                                               (click)="beforeDelete(item.id,'Suppression d\'avancement', 'Cette action n\'est pas revocable')"
                                               title="Supprimer"><i class="fas fa-trash"></i></label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Timeline View -->
                <ngx-avancement-timeline *ngIf="contrat.viewType === 'timeline'"
                                         [avancements]="contrat.avancements"></ngx-avancement-timeline>
            </div>

        </nb-card-body>

    </nb-card>
</div>
