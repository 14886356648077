import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SituationFamilialeModel} from '../model/situation-familiale.model';
import {ApplicationService} from './application.service';

@Injectable({
  providedIn: 'root',
})
export class SituationFamilialeService extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'situationFamiliales', '');
  }
  getAll(): Observable<SituationFamilialeModel[]> {
    return this.httpClient.get(this.baseUrl,
      {params: new HttpParams().set('size' , this.size.toString())})
      .pipe(
        map(response => {
        // @ts-ignore
        return response._embedded.situationFamiliales;
      }),
      catchError(this.handleError('getAll', [])),
    );
  }
}
