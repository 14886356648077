import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {AppAuthGuard} from '../../../lyautey/services/app-auth-guard.service';
import {NbAclService} from "@nebular/security";
import {PersonneModel} from "../../../lyautey/model/personnel.model";

@Component({
    selector: 'ngx-personne',
    templateUrl: './personne.component.html',
    styleUrls: ['./personne.component.scss'],
})
export class PersonneComponent implements OnInit {

    tabs: any[] = [];
    matricule = '';

    constructor(private route: ActivatedRoute,
                private personnelService: PersonnelService,
                public appAuthGuard: AppAuthGuard,
                public nbAclService: NbAclService) {

    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const id: number = Number(params['id']);
            if (id) {
                this.personnelService.getPersonnel(id)
                    .subscribe(
                        (personneModel: PersonneModel) => {
                            this.personnelService.getGenre(id)
                                .subscribe((data: any) => {
                                    this.matricule = `${data.description} ${personneModel.nomUsuel} ${personneModel.prenom} [${personneModel.id}]`;


                                });
                        },
                        (err: any) => {
                        },
                    );
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'personnel')) {
                    this.tabs.push({
                        title: 'Fiche Personnel',
                        route: `/pages/personnel/personne/${id}/fiche`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'contrat')) {
                    this.tabs.push({
                        title: 'Contrat',
                        route: `/pages/personnel/personne/${id}/contrat`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'document')) {
                    this.tabs.push({
                        title: 'Documents',
                        route: `/pages/personnel/personne/${id}/documents`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'formation')) {
                    this.tabs.push({
                        title: 'Formation & Stage',
                        route: `/pages/personnel/personne/${id}/formation`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'evaluation')) {
                    this.tabs.push({
                        title: 'Evaluation',
                        route: `/pages/personnel/personne/${id}/inspection`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'cte')) {
                    this.personnelService.getNationalites(id)
                        .subscribe((data: any) => {
                            const countryMorocco = data._embedded.countries.filter(function (country) {
                                return country.alpha2 === 'MA';
                            });
                            if (countryMorocco.length === 0) {
                                this.tabs.push({title: 'CTE', route: `/pages/personnel/personne/${id}/cte`});
                            }
                        });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'personnel')) {
                    this.tabs.push({
                            title: 'Visite Médicale',
                            route: `/pages/personnel/personne/${id}/visite-medicale`,
                        },
                        {
                            title: 'Accident de Travail',
                            route: `/pages/personnel/personne/${id}/accident-travail`,
                        },
                        {
                            title: 'Avancement',
                            route: `/pages/personnel/personne/${id}/avancement`,
                        },
                    );
                }

            } else {
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'personnel')) {
                    this.tabs.push({
                        title: 'Fiche Personnel',
                        route: `/pages/personnel/personne/fiche`,
                    });
                }
            }
        });
    }

}
