import {Component, Injectable, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'ngx-fonction-annexe',
  templateUrl: './fonction-annexe.component.html',
  styleUrls: ['./fonction-annexe.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
  ],
})
export class FonctionAnnexeComponent implements OnInit {

  date: NgbDateStruct;
  model1: string;

  profileForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    dateNaissance: ['', Validators.required],
  });

  constructor(private route: ActivatedRoute,
              private calendar: NgbCalendar,
              private fb: FormBuilder) {
  }

  ngOnInit() {
  }
}
