<nb-card status="primary" style="margin-top: 10px" *nbIsGranted="['view', 'formation']">
<nb-card-header>
    Liste des formations
  </nb-card-header>
  <nb-card-body>
    <div class="form-row">
      <div class="form-group col-md-12">
        <table class="table table-striped table-bordered display responsive" width="100%" >
          <thead>
          <tr>
            <th class="all">Intitule session</th>
            <th class="all">Année scolaire</th>
            <th class="all">Date début</th>
            <th class="all">Date fin</th>
            <th class="all">Lieu formation</th>
            <th class="all">Ville</th>
            <th class="all">Pays</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of result">
            <td>{{row.intituleSession}}</td>
            <td>{{row.anneeScolaire}}</td>
            <td>
              <span style="display: none">{{row.dateDebut | amDateFormat:'YYYY-MM-DD' }}</span>
              {{row.dateDebut | amDateFormat:'DD/MM/YYYY' }}
            </td>
            <td>
              <span style="display: none">{{row.dateFin | amDateFormat:'YYYY-MM-DD' }}</span>
              {{row.dateFin | amDateFormat:'DD/MM/YYYY' }}
            </td>
            <td>{{row.lieuFormation}}</td>
            <td>{{row.ville}}</td>
            <td>{{row.pays}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>

