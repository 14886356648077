import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {EvaluationService} from '../../../../lyautey/services/evaluation.service';
import {DocumentModel} from '../../../../lyautey/model/document.model';
import {ViewPdfComponent} from '../documents/view-pdf/view-pdf.component';
import {DocumentService} from '../../../../lyautey/services/document.service';
import {NbDialogService} from '@nebular/theme';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {GlobalObject} from '../../../../lyautey/classes/global-object';
import {ContratService} from '../../../../lyautey/services/contrat.service';
import {catchError, map} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {EtablissementService} from '../../../../lyautey/services/etablissement.service';
import {FonctionService} from '../../../../lyautey/services/fonction.service';
import {StatutService} from '../../../../lyautey/services/statut.service';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';

@Component({
    selector: 'ngx-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.scss'],
})
export class InspectionComponent implements OnInit , CanComponentDeactivate {
    selectedFiles: FileList;
    currentFile: File;
    progress = 0;
    message = '';
    dataTable: any;
    dataTableSettings: any = {fixedColumns: true};
    result = [];
    settings = {};
    editForm: FormGroup;
    globalObject: GlobalObject = new GlobalObject();
    private blob: Blob;
    contrats: any[];
    hasTitulaireDetache: boolean = false;
    personneId: number = 0;
    typeDocumentId: number = 14;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private chRef: ChangeDetectorRef,
                private dialogService: NbDialogService,
                private etablissementService: EtablissementService,
                private contratService: ContratService,
                private fonctionService: FonctionService,
                private statutService: StatutService,
                private evaluationService: EvaluationService,
                private documentsService: DocumentService,
                private service: EvaluationService) {
    }

    ngOnInit() {
        this.editForm = this.fb.group({
            file: [''],
            id: [''],
            personne: [''],
            description: [''],
            dateCloture: [''],
            etablissement: [null],
            anneeScolaire: ['', Validators.required],
        });
        this.route.parent.paramMap.subscribe(params => {
            this.personneId = Number(params.get('id'));
            if (this.personneId) {
                this.editForm.get('personne').setValue(this.personneId);
                this.service.listingByAgent(this.personneId)
                    .subscribe((result: any) => {
                            this.result = result;
                        }, (err: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: `Veuillez réessayer plus tard!`,
                            });
                        },
                    );
                this.contratService.getByPersonId(this.personneId).subscribe((data: any) => {
                    this.contrats = [];
                    const observables = [];
                    if (data._embedded.contrats.length > 0) {
                        data._embedded.contrats.forEach((contrat) => {
                            const typeStatutObservable = this.statutService
                                .getByUrl(contrat._links.typeStatut.href);
                            const etablissementObservable = this.etablissementService
                                .getByUrl(contrat._links.etablissement.href);
                            const fonctionObservable = this.fonctionService
                                .getByUrl(contrat._links.typeFonction.href)
                                .pipe(catchError((error) => {
                                    console.error('Error fetching fonction:', error);
                                    return of({});
                                }));
                            const avancementObservable = this.contratService
                                .getByUrl(contrat._links.avancements.href)
                                .pipe(map(avancementResponse => {
                                    const avancements = avancementResponse['_embedded']?.avancements || [];
                                    // Sort avancements in descending order by indiceBrut
                                    avancements.sort((a, b) => b.indiceBrut - a.indiceBrut);
                                    return avancements;
                                }));
                            const combinedObservable =
                                forkJoin([typeStatutObservable, etablissementObservable,
                                    fonctionObservable, avancementObservable])
                                    .pipe(map(([typeStatut, etablissement,
                                                   typeFonction, avancements]) => ({
                                        'contrat': contrat.id,
                                        'status': contrat.status,
                                        'typeStatut': typeStatut['description'] || 'N/A',
                                        'fonction': typeFonction['description'] || 'N/A',
                                        'etablissement': etablissement.description || 'N/A',
                                        'etablissementId': etablissement.id || 0,
                                        'avancements': avancements,
                                        'viewType': 'table',
                                    })));
                            observables.push(combinedObservable);
                        });
                        forkJoin(observables).subscribe((contratResults) => {
                            this.contrats = contratResults;
                            const statusOrder = ['actif', 'suspendu', 'archive', 'radie'];
                            this.contrats.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
                            // Assuming this.contrats is an array of objects and each object has a 'typeStatut' property
                            this.hasTitulaireDetache = this.contrats.some(contrat => contrat.typeStatut === 'Titulaire détaché');
                        });
                    }
                });
            }
        });
    }

    quickView(evaluationId: number, personneId: number) {
        this.documentsService.getEvaluation(personneId, evaluationId).subscribe((document: DocumentModel) => {
            this.documentsService.getByUrl(document._links.typeDocument.href).subscribe(value => {
                if (value !== undefined) {
                    document['typeDocument'] = value['description'];
                    this.documentsService.downloadFile(document.id).subscribe(data => {
                        this.blob = new Blob([data], {type: 'application/octet-stream'});
                        const downloadURL = window.URL.createObjectURL(data);
                        const extension: string = document.file.split('.').pop();
                        const viewer = extension === 'pdf' ? 'pdf' : 'office';
                        this.dialogService.open(ViewPdfComponent, {
                            closeOnEsc: true,
                            autoFocus: true,
                            hasScroll: true,
                            hasBackdrop: true,
                            context: {
                                document: document,
                                fileSrc: downloadURL,
                                viewer: viewer,
                            },
                        });
                    });
                }
            });
        });
    }

    onSubmit() {
        this.evaluationService.addEvaluationDetache(this.editForm).subscribe(value => {
            const evaluationId: number = value.id;
            if (evaluationId > 0 && this.personneId > 0) {
                this.progress = 0;
                this.currentFile = this.selectedFiles.item(0);
                this.documentsService.addEvaluationDocument(
                    this.currentFile, {
                        evaluationId: evaluationId,
                        personneId: this.personneId,
                        typeDocumentId: this.typeDocumentId,
                        description: this.editForm.get('description').value
                    }
                    ,
                ).subscribe(
                    event => {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progress = Math.round(100 * event.loaded / event.total);
                        } else if (event instanceof HttpResponse) {
                            this.selectedFiles = undefined;
                            this.service.listingByAgent(this.personneId)
                                .subscribe((result: any) => {
                                        this.result = result;
                                        Swal.fire({
                                            position: 'top-end',
                                            icon: 'success',
                                            title: `Votre modification a été bien enregistrée.`,
                                        });
                                    }, (err: any) => {
                                        Swal.fire({
                                            icon: 'error',
                                            title: `Veuillez réessayer plus tard!`,
                                        });
                                    },
                                );
                        }
                    },
                    err => {
                        this.selectedFiles = undefined;
                        this.progress = 0;
                        this.message = 'Could not upload the file!';
                        this.currentFile = undefined;
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: `Could not upload the file!`,
                        }).then(() => {
                            this.ngOnInit();
                        });
                    });
            }
            this.selectedFiles = undefined;
        });
    }

    onFileSelect(event) {
        this.selectedFiles = event.target.files;
        // const file = event.target.files[0];
        // this.editForm.get('file').setValue(file);
        // this.selectedFiles = event.target.files;
    }

    canDeactivate() {
        if (this.editForm.dirty) {
            return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
        }
        return true;
    }
}

