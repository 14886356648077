import { Injectable } from '@angular/core';
import {ApplicationService} from './application.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {FormArray, FormGroup} from '@angular/forms';
import {DiplomeModel} from '../model/diplome.model';
import {NiveauModel} from '../model/niveau.model';

@Injectable({
  providedIn: 'root',
})
export class DiplomeService  extends ApplicationService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'diplomes', '');
  }

  getByPersonId(id: number ): Observable<DiplomeModel> {
    const url = environment.serverUrl + `/personnes/${id}/diplomes`;
    return this.httpClient.get<DiplomeModel>(url, {
      params: new HttpParams().set('size' , this.size.toString()).set('sort' , 'annee,desc')}).pipe(
      tap(_ => console.log(`fetched personne diplome id=${id}`)),
      catchError(this.handleError<DiplomeModel>(`get DiplomeModel id=${id}`)),
    );
  }

  getNiveauByUrl(url: string): Observable<NiveauModel> {
    return this.httpClient.get<NiveauModel>(url).pipe(
      tap(_ => console.log(`fetched  url= ${url}`)),
      catchError(this.handleError<NiveauModel>(`get  url=${url}`)),
    );
  }

  addToPerson(personneId: number, group: FormGroup): void {
    const formArray = group.get('diplomes') as FormArray;
    const diplomes = [];
    for (const control of formArray.controls) {
      if (control instanceof FormGroup) {
        const diplome = {
          id: 0,
          personne: '',
          annee: '',
          niveau: '',
          libelle: '',
          action: '',
        };
        Object.keys(control.controls).forEach(key => {
          switch (key) {
            case 'id':
              diplome.personne = environment.serverUrl + `/personnes/${personneId}`;
              if (Number(control.controls['id'].value) > 0) {
                diplome.id = Number(control.controls['id'].value);
                diplome.action = 'PATCH';
              } else {
                diplome.action = 'POST';
              }
              break;
            case 'annee':
              diplome.annee = control.controls['annee'].value;
              break;
            case 'niveau':
              if (control.controls['niveau'].value !== undefined
                && control.controls['niveau'].value !== null
                && Number(control.controls['niveau'].value) > 0 ) {
                diplome.niveau = environment.serverUrl + `/niveaus/${control.controls['niveau'].value}`;
              }
              break;
            case 'libelle':
              diplome.libelle = control.controls['libelle'].value;
              break;

          }
        });
        if (diplome.action === 'POST') {
          delete diplome.id;
          this.add(diplome).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        } else if (diplome.action === 'PATCH') {
          this.update(diplome.id, diplome).subscribe((model: any) => {
            }, (err: any) => {
            },
          );
        }
        diplomes.push(diplome);
      }
    }
  }
}
