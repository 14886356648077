import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RechercheRoutingModule } from './recherche-routing.module';
import { RechercheComponent } from './recherche.component';
import { ListRechercheComponent } from './list-recherche/list-recherche.component';
import { EditRechercheComponent } from './edit-recherche/edit-recherche.component';
import {
    NbAccordionModule,
    NbButtonModule,
    NbCalendarModule,
    NbCardModule,
    NbLayoutModule,
    NbStepperModule,
} from '@nebular/theme';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {TableModule} from 'ngx-easy-table';
import {AppCommonModule} from '../app-common/app-common.module';
import {DataTablesModule} from 'angular-datatables';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MomentModule} from 'ngx-moment';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {PreviewResultComponent} from './preview-result/preview-result.component';
import { CartePersonnelleComponent } from './carte-personnelle/carte-personnelle.component';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {NbSecurityModule} from "@nebular/security";
import {ButtonsModule} from "ngx-bootstrap/buttons";


@NgModule({
  declarations: [RechercheComponent, ListRechercheComponent, EditRechercheComponent, PreviewResultComponent
    , CartePersonnelleComponent],
    imports: [
        CommonModule,
        RechercheRoutingModule,
        NbCardModule,
        ReactiveFormsModule,
        AngularMultiSelectModule,
        NbCalendarModule,
        TableModule,
        AppCommonModule,
        DataTablesModule,
        NgxSpinnerModule,
        NbAccordionModule,
        MomentModule,
        NbButtonModule,
        NbStepperModule,
        NbLayoutModule,
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgOptionHighlightModule,
        NbSecurityModule,
        ButtonsModule,
    ],
  exports: [
    PreviewResultComponent,
  ],
})
export class RechercheModule { }
