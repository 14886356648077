import {Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {DocumentModel} from '../../../../../lyautey/model/document.model';

@Component({
  selector: 'ngx-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent  {

  @Input() document: DocumentModel;
  @Input() viewer: string;
  @Input() fileSrc: any;

  constructor(protected ref: NbDialogRef<ViewPdfComponent>) {}

  dismiss() {
    this.ref.close();
  }

}
